import { RootState } from "./../../../../../store";

import { useSearchParams } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";

// Services
import { CM0031UseCase } from "./../../usecase/ServiceImpl";
import { useState } from "react";
import helpers from "../../../../../common/helpers/common";
import moment from "moment";
import { setRefresh, setVisibleCM0031 } from "../slice/Slice";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { uuidv4 } from "@firebase/util";
import COMMON from "../../../../../common/constants/COMMON";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";

export const MENU_ITEM = [
  {
    name: "業者別",
    key: "trader",
  },
  {
    name: "案件別",
    key: "project",
  },
];

const PARAMS = {
  menu: "menu",
  sortType: "sortType1",
  sortBy: "sortBy1",
  from: "from1",
  to: "to1",
  clear: "clear1",
  from2: "from2",
  to2: "to2",
  clear2: "clear2",
};

const SORT_TYPE: string = "DESC",
  SORT_BY: string = "updatedAt";

const PARAMS_DEFAULT = {
  menu: MENU_ITEM[0].key,
  from: helpers.getFilterTime(moment()),
  to: helpers.getFilterTime(moment()),
};

export const INPUT_CATEGORY_CODE = {
  manualInput: "manual_input",
  percentage: "percentage",
};

const DEFAULT_SUMMARY = { consumptionTax: 0, excludingTax: 0, taxIncluded: 0 };

export const COLUMN_SORT = [
  "paymentExcludingTax",
  "taxConsumption",
  "paymentExcludedTax",
];

const CM0031Handler = (service: CM0031UseCase) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const menu = searchParams.get(PARAMS.menu) || PARAMS_DEFAULT.menu;
  const sortType = searchParams.get(PARAMS.sortType);
  const sortBy = searchParams.get(PARAMS.sortBy);
  const from = searchParams.get(PARAMS.from) || PARAMS_DEFAULT.from;
  const to = searchParams.get(PARAMS.to) || PARAMS_DEFAULT.to;
  const clear = searchParams.get(PARAMS.clear);
  const from2 = searchParams.get(PARAMS.from2) || PARAMS_DEFAULT.from;
  const to2 = searchParams.get(PARAMS.to2) || PARAMS_DEFAULT.to;
  const clear2 = searchParams.get(PARAMS.clear2);

  const [dataView, setDataView] = useState<any[]>([]);
  const [expandKey, setExpandKey] = useState<any>([]);
  const document = useSelector((state: RootState) => state.cm0031.document);
  const loading = useSelector((state: RootState) => state.common.loading);
  const [pagination, setPagination] = useState({ numPages: 0, page: 1 });
  const [summary, setSummary] = useState(DEFAULT_SUMMARY);
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);
  const getWorkflowSummaryIndirectApprovalProcess = async (
    documentId: number
  ) => {
    try {
      dispatch(setLoading(true));
      const data = await service.getWorkflowSummaryIndirectApprovalProcess({
        documentId,
      });
      if (data.results.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          note: data.results.currentUserApproval.note,
          fullName: data.results.currentUserApproval.fullName,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            note: note,
            fullName: element.fullName,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getProjectAll = async (params: {
    page: number;
    size: number;
    workspaceId: string;
    statusId?: number;
    sortBy: string;
    sortType: string;
  }) => {
    try {
      dispatch(setLoading(true));
      const response =
        await service.getReSummarySubmittedIndirectCostProjectListByDocument({
          documentId: document.id,
          page: params.page,
          size: params.size,
        });

      const projectResults = response?.results ?? [];

      if (response?.pagination) setPagination(response.pagination);
      if (projectResults.length > 0) {
        const results: any[] = [];
        for await (const [index, item] of projectResults.entries()) {
          const obj: any = {
            ...item,
            key: uuidv4(),
            consumptionTax: 0,
            excludingTax: 0,
            taxIncluded: 0,
            children: [],
          };
          const resChild = await service.getSubmittedIndirectCostProjectList({
            documentId: `${document?.id}`,
            projectId: `${item.id}`,
          });
          if (resChild?.results) {
            obj.children = resChild.results.map((ele) => {
              const objChild = {
                ...ele,
                key: uuidv4(),
                consumptionTax: 0,
                excludingTax: 0,
                taxIncluded: 0,
              };

              if (
                ele.inputMethodCategory.code === INPUT_CATEGORY_CODE.manualInput
              ) {
                objChild.excludingTax = Math.floor(ele.actualCost) ?? 0;
              } else {
                objChild.excludingTax =
                  Math.floor(ele.directCost / (1 - ele.multiply) - ele.directCost);
              }
              objChild.consumptionTax =
                Math.floor(objChild.excludingTax * (ele.taxSetting ?? 1))

              objChild.taxIncluded =
                Math.floor(objChild.excludingTax) + Math.floor(objChild.consumptionTax);

              if (isFinite(objChild.excludingTax))
                obj.excludingTax += Math.floor(objChild.excludingTax) ?? 0;
              if (isFinite(objChild.consumptionTax))
                obj.consumptionTax += Math.floor(objChild.consumptionTax) ?? 0;
              if (isFinite(objChild.taxIncluded))
                obj.taxIncluded += Math.floor(objChild.taxIncluded) ?? 0;

              return objChild;
            });
          }
          results.push(obj);
        }
        if (params.page === 1) {
          setDataView(results);
          setExpandKey([results[0]?.key]);
        } else setDataView((prevState) => [...prevState, ...results]);
      } else {
        setDataView([]);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getSubmittedIndirectCostProjectTotal = async (params: {
    documentId: number;
  }) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getSubmittedIndirectCostProjectTotal(
        params
      );
      if (response?.results) {
        setSummary({
          excludingTax: response.results.paymentCostWithoutTax,
          consumptionTax:
            (response.results.paymentCostWithoutTax ?? 0) *
            (response.results.taxSetting ?? 1)
          ,
          taxIncluded:
            (response.results.paymentCostWithoutTax || 0) *
            (response.results.taxSetting || 1) +
            response.results.paymentCostWithoutTax || 0
          ,
        });
      } else setSummary(DEFAULT_SUMMARY);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const funcSetExpendKey = (key: any) => {
    const checkKey = funcCheckKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const funcCheckKeyExpand = (key: any) => {
    if (!key || !expandKey) return false;
    return expandKey.some((element: any) => element === key);
  };

  const handleChangeMenuChosen = (menu: string) => {
    searchParams.set(PARAMS.menu, menu);
    setSearchParams(searchParams);
  };

  const handleCancelModal = () => {
    dispatch(setVisibleCM0031(false));
  };

  const funcCheckSortOrder = (
    columnName: string,
    sortType: string | null,
    sortBy: string | null
  ): "ASC" | "DESC" | undefined => {
    if (columnName === sortBy) {
      return !sortType ? undefined : sortType === "ASC" ? "ASC" : "DESC";
    }
  };
  const funcSortTable = (column: string, sortOrder: string) => {
    searchParams.set(PARAMS.sortBy, column);
    searchParams.set(PARAMS.sortType, sortOrder);
    setSearchParams(searchParams);
  };

  const funcFilterTime = (value: any) => {
    if (menu === MENU_ITEM[0].key) {
      searchParams.delete(PARAMS.from);
      searchParams.delete(PARAMS.to);
      searchParams.set(PARAMS.clear, "1");
      if (value) {
        searchParams.delete(PARAMS.clear);
        const fromValue = helpers.getFilterTime(value[0]);
        const toValue = helpers.getFilterTime(value[1]);
        if (fromValue !== PARAMS_DEFAULT.from) {
          searchParams.set(PARAMS.from, fromValue);
        }
        if (toValue !== PARAMS_DEFAULT.to) {
          searchParams.set(PARAMS.to, toValue);
        }
      }
    } else {
      searchParams.delete(PARAMS.from2);
      searchParams.delete(PARAMS.to2);
      searchParams.set(PARAMS.clear2, "1");
      if (value) {
        searchParams.delete(PARAMS.clear2);
        const fromValue = helpers.getFilterTime(value[0]);
        const toValue = helpers.getFilterTime(value[1]);
        if (fromValue !== PARAMS_DEFAULT.from) {
          searchParams.set(PARAMS.from2, fromValue);
        }
        if (toValue !== PARAMS_DEFAULT.to) {
          searchParams.set(PARAMS.to2, toValue);
        }
      }
    }
    setSearchParams(searchParams);
  };

  const handleRetrieve = async () => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          const response =
            await service.postSubmittedIndirectCostProjectRetrieve({
              documentId: document.id,
            });
          if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
            SuccessNotification(
              response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            dispatch(setVisibleCM0031(false));
            dispatch(setRefresh());
          }
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_4,
      description2: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
    });
  };

  const handleScrollTable = (e: any) => {
    const target = e.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight &&
      pagination.page < pagination.numPages
    ) {
      getProjectAll({
        page: pagination.page + 1,
        size: COMMON.DEFAULT_SIZE,
        sortType: SORT_TYPE,
        sortBy: SORT_BY,
        workspaceId: "1",
        statusId: 0,
      });
    }
  };

  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    dataView,
    expandKey,
    menu,
    from,
    to,
    clear,
    from2,
    to2,
    clear2,
    sortType,
    sortBy,
    currentUserApproval,
    workflow,
    summary,
    funcSetExpendKey,
    funcCheckKeyExpand,
    handleChangeMenuChosen,
    handleCancelModal,
    funcCheckSortOrder,
    funcSortTable,
    funcFilterTime,
    getProjectAll,
    handleScrollTable,
    handleRetrieve,
    getSubmittedIndirectCostProjectTotal,
    getWorkflowSummaryIndirectApprovalProcess,
    showConfirmApproveModal,
  };
};

export default CM0031Handler;
