import { uuidv4 } from "@firebase/util";

import { useSearchParams } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";

// Services
import { CM0030UseCase } from "./../../usecase/ServiceImpl";
import { useState } from "react";
import helpers from "../../../../../common/helpers/common";
import moment from "moment";

import { setLoading } from "../../../../../common/slice/CommonSlice";
import COMMON from "../../../../../common/constants/COMMON";
import { Form } from "antd";
import { exportCM0030 } from "../../../../../common/helpers/exports/cm/template_cm0030";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

const PARAMS = {
  menu: "menu",
  sortType: "sortType5",
  sortBy: "sortBy5",
  from: "from5",
  to: "to5",
  clear: "clear5",
  openFilter: "openFilter5",
};

const PARAMS_DEFAULT = {
  from: helpers.getFilterTime(moment()),
  to: helpers.getFilterTime(moment()),
};

const FILE_NAME = `社内収支_間接工事費_${moment().format(
  COMMON.FORMAT_DATE_SUBMIT
)}`;

const INPUT_CATEGORY_CODE = {
  manualInput: "manual_input",
  percentage: "percentage",
};

const CM0030Handler = (service: CM0030UseCase) => {
  const dispatch = useDispatch();
  const [formFilter] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const sortType = searchParams.get(PARAMS.sortType);
  const sortBy = searchParams.get(PARAMS.sortBy);
  const from = searchParams.get(PARAMS.from) || PARAMS_DEFAULT.from;
  const to = searchParams.get(PARAMS.to) || PARAMS_DEFAULT.to;
  const clear = searchParams.get(PARAMS.clear);

  const [dataView, setDataView] = useState<any>([]);
  const [expandKey, setExpandKey] = useState<any>([]);
  const [status, setStatus] = useState<any>({});
  const [totalIndirectCost, setTotalIndirectCost] = useState({});
  const [visibleApprove, setVisibleApprove] = useState<boolean>(false);
  const openFilter = searchParams.get(PARAMS.openFilter);
  const [filterCurrent, setFilterCurrent] = useState({ from: "", to: "" });
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [numPages, setNumPages] = useState<number>(1);
  const [projectPage, setProjectPage] = useState<number>(1);
  const projectSize = Number(COMMON.DEFAULT_SIZE);

  const funcSetExpendKey = (key: any) => {
    const checkKey = funcCheckKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const funcCheckKeyExpand = (key: any) => {
    if (!key || !expandKey) return false;
    return expandKey.some((element: any) => element === key);
  };

  const handleChangeMenuChosen = (menu: string) => {
    searchParams.set(PARAMS.menu, menu);
    setSearchParams(searchParams);
  };

  const funcCheckSortOrder = (
    columnName: string,
    sortType: string | null,
    sortBy: string | null
  ): "ASC" | "DESC" | undefined => {
    if (columnName === sortBy) {
      return !sortType ? undefined : sortType === "ASC" ? "ASC" : "DESC";
    }
  };
  const funcSortTable = (column: string, sortOrder: string) => {
    searchParams.set(PARAMS.sortBy, column);
    searchParams.set(PARAMS.sortType, sortOrder);
    setSearchParams(searchParams);
  };

  const initialFilter = () => {
    formFilter.setFieldValue(
      "filterTime5",
      clear ? null : [moment(from), moment(to)]
    );
  };

  const handleFilterTime = (filter: any) => {
    setProjectPage(COMMON.DEFAULT_PAGE);
    const value = filter.filterTime5;
    searchParams.delete(PARAMS.from);
    searchParams.delete(PARAMS.to);
    searchParams.set(PARAMS.clear, "1");
    if (value) {
      setFilterCurrent({ from: "", to: "" });
      searchParams.delete(PARAMS.clear);
      const fromValue = helpers.getFilterTime(value[0]);
      const toValue = helpers.getFilterTime(value[1]);
      if (fromValue !== PARAMS_DEFAULT.from) {
        searchParams.set(PARAMS.from, fromValue);
      }
      if (toValue !== PARAMS_DEFAULT.to) {
        searchParams.set(PARAMS.to, toValue);
      }
    }
    setSearchParams(searchParams);
  };

  const getTotalIndirectCost = async (params: { from: string; to: string }) => {
    try {
      if (filterCurrent.from === params.from && filterCurrent.to === params.to)
        return;
      setFilterCurrent({ from: params.from, to: params.to });
      const response = await service.getTotalIndirectCost(params);
      const data = response?.results ?? {};

      if (data)
        setTotalIndirectCost({
          paymentAmountExcludeTax: data.paymentAmountExcludeTax,

          consumptionTax: data.paymentAmountExcludeTax * (data?.taxSetting ?? 1),

          taxIncluded: data.paymentAmountExcludeTax * (data?.taxSetting ?? 1) +
            data.paymentAmountExcludeTax,

        });
      else setTotalIndirectCost({});
    } catch (error) {
      setTotalIndirectCost({});
    }
  };

  const getSummaryIndirectCost = async (params: {
    projectId: number;
    from: string;
    to: string;
    sortBy?: string;
    sortType?: string;
  }) => {
    const response = await service.getSummaryIndirectCost({
      ...params,
      page: 1,
      size: COMMON.DEFAULT_SIZE,
    });
    if (response?.results && response.results.length > 0) {
      let dataFirst = [...response?.results];
      if (response.pagination.numPages > 1) {
        for (
          let pageFirst = 2;
          pageFirst <= response.pagination.numPages;
          pageFirst++
        ) {
          const responseRe = await service.getSummaryIndirectCost({
            ...params,
            page: pageFirst,
            size: COMMON.DEFAULT_SIZE,
          });
          if (responseRe?.results && responseRe?.results?.length > 0) {
            dataFirst = [...dataFirst, ...responseRe?.results];
          }
        }
      }
      return dataFirst;
    }
    return [];
  };

  const funcHandleDataTable = async (params: any) => {
    if (numPages < projectPage) return;
    try {
      dispatch(setLoading(true));
      let projectData: any[] = [];
      const responseData = await service.getProjectAlls({
        page: projectPage,
        size: projectSize,
        from: params.from,
        to: params.to,
        sortBy: COMMON.DEFAULT_SORT_BY,
        sortType: COMMON.DEFAULT_SORT_TYPE,
      });
      projectData = responseData.results;

      for (let i = 0; i < projectData.length; i++) {
        const costDetail = await getSummaryIndirectCost({
          ...params,
          projectId: projectData[i].id,
        });
        const resultCostDetail = costDetail?.map((value: any) => {
          const objChild = {
            ...value,
            key: uuidv4(),
            paymentAmount: 0,
            consumptionTax: 0,
            taxInclude: 0,
          };
          let paymentAmountValue =
            value.inputMethodCategory.code === INPUT_CATEGORY_CODE.manualInput
              ? value.actualCost ?? 0
              : value.directCost / (1 - value.multiplicationFactor) -
              value.directCost;

          let consumptionTaxValue = paymentAmountValue * (value.taxSetting ?? 1)

          objChild.paymentAmount = Math.floor(paymentAmountValue)

          objChild.consumptionTax = Math.floor(paymentAmountValue * (value.taxSetting ?? 1))

          objChild.taxIncluded = Math.floor(paymentAmountValue) + Math.floor(consumptionTaxValue)


          return objChild;
        });

        let paymentAmount: number = 0;
        let taxInclude: number = 0;
        let consumptionTax: number = 0;

        resultCostDetail?.forEach((element: any) => {
          let paymentAmountValue =
            element.inputMethodCategory.code === INPUT_CATEGORY_CODE.manualInput
              ? element.actualCost ?? 0
              : element.directCost / (1 - element.multiplicationFactor) -
              element.directCost;

          let consumptionTaxValue =
            paymentAmountValue * (element.taxSetting ?? 1)

          let taxIncludedValue = Math.floor(paymentAmountValue) + Math.floor(consumptionTaxValue);

          if (isFinite(paymentAmountValue))
            paymentAmount += Math.floor(paymentAmountValue) ?? 0;
          if (isFinite(taxIncludedValue)) taxInclude += Math.floor(taxIncludedValue) ?? 0;
          if (isFinite(consumptionTaxValue))
            consumptionTax += Math.floor(consumptionTaxValue) ?? 0;
        });
        projectData[i].children = resultCostDetail.sort((a, b) => {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        });
        projectData[i].paymentAmount = paymentAmount

        projectData[i].taxInclude = taxInclude

        projectData[i].consumptionTax = consumptionTax

      }
      projectPage === COMMON.DEFAULT_PAGE
        ? setDataView(projectData)
        : setDataView([...dataView, ...projectData]);
      if (numPages === COMMON.DEFAULT_PAGE && projectData[0]?.id)
        setExpandKey([projectData[0].id]);
      if (
        responseData?.pagination?.numPages &&
        responseData.pagination.numPages !== 0
      )
        setNumPages(responseData.pagination.numPages);
      setProjectPage(responseData.pagination.page + 1);
      if (filterCurrent.from === params.from && filterCurrent.to === params.to)
        return;
      setFilterCurrent({ from: params.from, to: params.to });
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleScroll = async (e: any, from: string, to: string) => {
    var target = e.target;
    const bottom =
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight;
    if (e.target.scrollTop === 0) return;

    if (bottom) {
      await funcHandleDataTable({ from, to });
    }
  };

  const getIndirectCostProjectDocumentStatus = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      if (filterCurrent.from === params.from && filterCurrent.to === params.to)
        return;
      setFilterCurrent({ from: params.from, to: params.to });
      const response = await service.getIndirectCostProjectDocumentStatus(
        params
      );
      if (response?.results) setStatus(response.results);
      else setStatus({});
    } catch (error) {
      setStatus({});
    }
  };

  const handleConfirmData = () => {
    setVisibleApprove(true);
  };

  const onOkConfirmModal = async () => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          const fileName = FILE_NAME;
          const response = await service.putIndirectCostProjectSubmit({
            fileName,
            startDate: helpers.getFilterTimeMonth(from),
            endDate: helpers.getFilterTimeMonthTo(to),
          });
          if (response?.message) {
            SuccessNotification(
              response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            setVisibleApprove(false);
            setRefreshStatus((curState) => !curState);
            setFilterCurrent({ from: "", to: "" });
          }
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      className: "confirm__modal confirm__modal-purple-oke",
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: "間接工事費" + MESSAGE.MESSAGE_SUBMIT_CM0019,

      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };

  const onCancelConfirmModal = () => {
    setVisibleApprove(false);
  };

  const handleOpenCalendar = (open: boolean) => {
    if (open) searchParams.set("openFilter5", "1");
    else {
      if (
        helpers.getFilterTimeMonth(from) === filterCurrent.from ||
        helpers.getFilterTimeMonthTo(to) === filterCurrent.to
      ) {
        return;
      } else setDataView([]);
      searchParams.delete("openFilter5");
    }
    setSearchParams(searchParams);
  };

  const exportExcelCM0030 = async () => {
    dispatch(setLoading(true));

    try {
      const response = await service.doExportCM0030({
        from: from ? helpers.getFilterTimeMonth(from) : "",
        to: to ? helpers.getFilterTimeMonthTo(to) : "",
        page: 1,
        size: projectSize,
        sortBy: COMMON.DEFAULT_SORT_BY,
        sortType: COMMON.DEFAULT_SORT_TYPE,
      } as any);
      doExportForResponse(response, FILE_NAME + ".xlsx");
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    dataView,
    expandKey,
    from,
    to,
    clear,
    sortType,
    sortBy,
    totalIndirectCost,
    formFilter,
    visibleApprove,
    openFilter,
    filterCurrent,
    status,
    refreshStatus,
    funcSetExpendKey,
    funcCheckKeyExpand,
    handleChangeMenuChosen,
    funcCheckSortOrder,
    funcSortTable,
    handleFilterTime,
    handleConfirmData,
    funcHandleDataTable,
    getTotalIndirectCost,
    initialFilter,
    onCancelConfirmModal,
    handleOpenCalendar,
    exportExcelCM0030,
    getIndirectCostProjectDocumentStatus,
    onOkConfirmModal,
    handleScroll,
  };
};

export default CM0030Handler;
