//https://docs.google.com/spreadsheets/d/1RQHkZmRulnb3qt7FaoJBnbffHUJ672IaIkwQUYdgq2g/edit#gid=0

const MESSAGE = {
  MESSAGE_5: "入力してください。",
  MESSAGE_59: "グループ名に特殊文字を含めることができません。",
  MESSAGE_76: "「取得年月日」と「有効期限」の形式が正しくありません。",
  MESSAGE_60: "メンバーの名前に特殊文字を含めることができません。",
  MESSAGE_81: "プランの範囲で選択してください",

  MESSAGE_002: "ユーザー アカウントまたはパスワードが正しくありません",
  MESSAGE_005: "パスワードが変更されました",
  MESSAGE_006: "パスワードが一致しません。",
  MESSAGE_007: "現在のパスワードが正しくありません。",
  MESSAGE_008: "このアカウントが存在しません。",
  MESSAGE_010: "IDは重複されました。",
  MESSAGE_012: "予約が作成されました。",
  MESSAGE_017:
    "選択した予約者をすべて削除してもよろしいですか？削除すると内容を元に戻すことができません。選択した予約者をすべて削除してもよろしいですか？削除すると内容を元に戻すことができません。",
  MESSAGE_020: "このページから移動",
  MESSAGE_021: "行った変更が保存されない可能性があります。",
  MESSAGE_022_1: "行った変更は保存されません。",
  MESSAGE_022_2: "このページを閉じてもよろしいでしょうか？",
  MESSAGE_UPLOAD_WRONG_FILE:
    "アップロード可能なファイル形式はJPGまたはPNGのみです",
  MESSAGE_UPLOAD_WRONG_FILE_2:
    "PDF/JPEG/JPN/PNGファイルのみをアップロードできます。",
  MESSAGE_IMAGE_TOO_LARGE: "8MB以上のファイルはアップロードできません",
  MESSAGE_UPLOAD: "ファイルの形式が不正です。",
  MESSAGE_PASSWORD_NOT_MATCH: "パスワードが間違っています。",
  MESSAGE_INVALID_PASSWORD:
    "文字と数字を組み合わせる8桁以上16桁以内で入力してください。",
  MESSAGE_INVALID_PASSWORD_REGEX:
    "パスワードには数字、大文字、小文字、特殊文字を入力する必要があります。",
  MESSAGE_PASSWORDS_DO_NOT_MATCH: "初めに入力したパスワードと確認用のパスワードが異なります。もう一度、同じパスワードを入力してください。",
  MESSAGE_CCUSID_DO_NOT_MATCH: "初めに入力したIDと確認用のIDが異なります。もう一度、同じIDを入力してください。",

  MESSAGE_REQUIRED_FIELD: "このフィールドを入力してください。",
  MESSAGE_INVALID_INPUT: "入力内容が不正です。",
  MESSAGE_CONFIRM_DELETE_MEMBER_TIT: "メンバー削除",
  MESSAGE_CONFIRM_DELETE_MEMBER_DES:
    "メンバーを削除してもよろしいですか。削除すると元に戻すことができません。  ",
  MESSAGE_EMAIL: "アカウントまたはメールアドレスの形式が不正です。",
  MESSAGE_DELETE_MANY_TIT: "削除確認",
  MESSAGE_DELETE_MANY_DES_1: "削除すると、復元できません。",
  MESSAGE_DELETE_MANY_DES_2: "メンバーを削除してもよろしいでしょうか？",
  MESSAGE_ID_SPECIAL_CHARACTER: "IDに特殊文字を含めることができません。",
  MESSAGE_INPUT_INVALID: "入力内容が不正です。",
  MESSAGE_8_16_CHARACTERS:
    "文字と数字を組み合わせる8桁以上16桁以内で入力してください。",
  MESSAGE_8_24_CHARACTERS: "大文字、小文字、数字、記号の3種類以上を組み合わせる8桁以上24桁以内で入力してください。",
  MESSAGE_REQUIRED: "このフィールドを入力してください。",
  MESSAGE_REQUIRED_EMAIL: "会社のメールアドレスを入力してください。",
  MESSAGE_EMAIL_INVALID: "メールアドレスの形式が正しくありません。",
  MESSAGE_LESS_THAN_14: "14桁の番号を入力してください。",
  MESSAGE_LESS_THAN_14_CCUS: "建設キャリアアップシステムの事業者ID14桁を半角数字で入力してください。",
  MESSAGE_WRONG_EMAIL_FORMAT: "メールアドレスの形式が不正です。",
  MESSAGE_DUPLICATE_EMAIL:
    "現在のメールアドレスと重複しております。新しいメールアドレスを入力してください。",
  MESSAGE_WRONG_ID_FORMAT:
    "IDの形式が不正です。文字列と数字のみを入力してください。",
  MESSAGE_WRONG_PHONE_FORMAT: "電話番号の形式が正しくありません。",
  MESSAGE_WRONG_FAX_FORMAT: "半角数字で入力してください。",
  MESSAGE_DATA_NOT_FOUND: "データなし",
  MESSAGE_CUSSID_FORMAT: "14桁の番号を入力してください",
  MESSAGE_EXPORT_ERROR: "エラーが発生したため、出力できませんでした。",
  MESSAGE_REQUIRED_PROJECT: "案件名は入力必須項目です。",
  MESSAGE_REQUIRED_CONSTRUCTION: "工事名は入力必須項目です。",
  MESSAGE_TITLE_BEFORE_CREATE: "提出確認",
  MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1: "権限付与確認",
  MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_2: "権限解除確認",
  MESSAGE_REQUIRED_CCUS: "入力必須項目です。",
  MESSAGE_DESCRIPTION_BEFORE_CREATE_ATTENDANCE:
    "打刻申請を提出してもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_BEFORE_CREATE_LATE_EARLY:
    "遅刻・早退申請を提出してもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_BEFORE_CREATE_LEAVE:
    "休暇申請を提出してもよろしいでしょうか？",

  MESSAGE_DELETE_ATTENDANCE: "打刻申請を削除してもよろしいでしょうか？",
  MESSAGE_DELETE_LATE_EARLY: "遅刻・早退申請を削除してもよろしいでしょうか？",
  MESSAGE_DELETE_LEAVE: "休暇申請を削除してもよろしいでしょうか？",

  MESSAGE_QUESTION_SUBMIT_CM: "資料を提出してもよろしいですか？",
  MESSAGE_MODAL_DELETE_TITLE: "削除確認",
  MESSAGE_MODAL_DELETE_DESCRIPTION_PM025:
    "メモを削除してもよろしいでしょうか？",
  MESSAGE_MODAL_DELETE_DESCRIPTION_CM:
    "レコードを削除してもよろしいでしょうか？",
  MESSAGE_TITLE_CM016: "取り下げ確認",
  MESSAGE_DESCRIPTION_CM016: "資料を取り下げてもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_CR: "提出中の実行予算書を",
  MESSAGE_DESCRIPTION_CR_EXTRA: "取り下げてもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_CR_CONFIRM: "実行予算書を提出してもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_CI:
    "提出中の工事引合書を取り下げてもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_CI_CONFIRM: "工事引合書を提出してもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_CI_CONFIRM_APPROVE: "工事引合書を承認してもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_CI_CONFIRM_SUBMIT: "工事引合書を提出してもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_CR_CONFIRM_APPROVE: "実行予算書を承認してもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_CR_CONFIRM_SUBMIT: "実行予算書を提出してもよろしいでしょうか?",
  MESSAGE_DESCRIPTION_CM0024: "実行予算書を提出してもよろしいですか？",
  MESSAGE_INVALID_MORE_THAN_SIX: "6文字以上を入力してください。",
  MESSAGE_DELETE_SW: "ワークフローを削除してもよろしいでしょうか？",
  MESSAGE_SUBMIT_CM: "を提出してもよろしいですか？",
  MESSAGE_SUBMIT_CM2: "の資料を提出してもよろしいでしょうか？",
  MESSAGE_SW_NAME: "数次と文字を入力してください。",
  MESSAGE_START_END_TIME_ERROR: "開始時間と終了時間を確認してください。",
  MESSAGE_DELETE_NOTIFICATION: "通知を削除してもよろしいでしょうか。",
  MESSAGE_DESCRIPTION_DELETE_ORD: "を削除してもよろしいでしょうか？",
  MESSAGE_DELETE_ONE_ORD001:
    "オーダーを削除すると、該当するアカウントのすべての情報を削除します。",
  MESSAGE_DELETE_MULTI_ORD001:
    "オーダーを削除すると、該当するアカウントのすべての情報を削除します。",
  MESSAGE_DESCRIPTION2_DELETE_ORD001: "情報は復元できません。",
  MESSAGE_DESCRIPTION3_DELETE_ORD001: "削除してもよろしいでしょうか？",

  MESSAGE_ID_ORD: "IDに特殊文字を含めることができません。",
  MESSAGE_ID_ORD_MIN: "6文字以上入力してください。",
  MESSAGE_DELETE_MEMBER_AOP:
    "メンバーを削除すると、設定したユーザーの権限も失われます。削除してもよろしいでしょうか？",
  MESSAGE_NO_DATA: "データなし",

  MESSAGE_DESCRIPTION_CR_1: "提出中の材料費の資料を",
  MESSAGE_DESCRIPTION_CR_2: "提出中の外注費の資料を",
  MESSAGE_DESCRIPTION_CR_3: "提出中の労務費の資料を",
  MESSAGE_DESCRIPTION_CR_4: "提出中の間接工事費の資料を",
  MESSAGE_DESCRIPTION_CR_5: "提出中の入金額の資料を",

  MESSAGE_DESCRIPTION_COMMON_QUESTION_1: "取り下げてもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_COMMON_QUESTION_2: "提出してもよろしいでしょうか？",

  MESSAGE_DESCRIPTION_CR_7: "この項目を削除してもよろしいでしょうか？",
  MESSAGE_DESCRIPTION_CR_8_1: "選択した請求出来高の資料を",
  MESSAGE_DESCRIPTION_CR_9_1: "選択した材料費の資料を",
  MESSAGE_DESCRIPTION_CR_10_1: "選択した外注費の資料を",
  MESSAGE_DESCRIPTION_CR_11_1: "選択した労務費の資料を",
  MESSAGE_DESCRIPTION_CR_12_1: "選択した間接工事費の資料を",

  MESSAGE_DESCRIPTION_CR_13_1: "提出中の請求出来高の資料を",
  MESSAGE_DESCRIPTION_CR_14_1: "［協力会社名］の工事代金支払明細書を",
  MESSAGE_DESCRIPTION_CR_14_2: "の工事代金支払明細書を",

  MESSAGE_DESCRIPTION_CR_15_1: "提出中の工事代金支払明細書を",

  MESSAGE_LESS_THAN_7: "7桁の数字を入力してください。",
  MESSAGE_CHANGE_PASSWORD_SUCCESS: "パスワードを変更しました",

  MESSAGE_SUBMIT_CM0019: "の社内収支を提出してもよろしいでしょうか？",

  MESSAGE_ORD001_MSG_002: "マスタを保存できませんでした。",
  MESSAGE_ORD001_MSG_004: "マスタを保存しました。",
  MESSAGE_IC2_MSG_007: "14桁の数字を入力してください。",
  MESSAGE_ORD001_MSG_008:
    "現在のユーザー数は[現在のユーザー数]です。ユーザー数を変更したい場合、組織・メンバー画面からメンバーを削除することが必要です。",
  MESSAGE_SM01_MSG_009: "終了時間と開始時間を再確認してください。",
  MESSAGE_OM002_MSG_011:
    "ユーザー数が上限に達したため、新規メンバーを作成できません。",
  MESSAGE_PM027_MSG_012: "カタカナで入力してください。",
  MESSAGE_ORD_2_1_ERROR_1:
    "「工事代金明細書」機能を登録するため、「案件別収支管理」機能を登録する必要があります。",
  MESSAGE_ORD_2_1_ERROR_2:
    "「社内収支」機能を登録するため、「案件別収支管理」機能を登録する必要があります。",
  MESSAGE_ORD_2_1_ERROR_3:
    "「ダッシュボード」機能を登録するため、「案件別収支管理」、「工事代金明細書」、「社内収支」機能を登録する必要があります。",
  MESSAGE_DESCRIPTION_SM04: "コメントを削除してもよろしいでしょうか？",
  MESSAGE_VALIDATE_FAX: "入力文字数が制限を超えています。",
  MESSAGE_CCUSID_INITIAL: "認証待ち：CCUS現場IDの認証を行っています。しばらくお待ちください。",
  MESSAGE_CCUSID_DONE: "認証済：CCUS現場IDが認証されました。",
  MESSAGE_CCUSID_ERROR: "エラー：CCUS現場IDの認証に失敗しました。正しいCCUS現場IDを再入力してください。",
  MESSAGE_CCUS061_VALID: "①～③の項目のいずれか入力必須です。",
  MESSAGE_CCUS061_VALID_NAME: "漢字、ひらがな、または半角英字大文字を入力してください。",
  MESSAGE_CCUS061_VALID_NAME_KATAKANA: "全角カタカナまたは半角英字、半角数字を入力してください。",
  MESSAGE_CCUS061_VALID_DATE: "yyyyMMdd形式で半角数字を入力してください。",
  MESSAGE_CCUS061_INVALID_INPUT: "入力の形式が不正です。",

  MESSAGE_CCUSID_PM_INITIAL: "確認待ち：建設キャリアアップシステムにてこの案件の元請事業者であるかどうかを確認中です。しばらくお待ちください。",
  MESSAGE_CCUSID_PM_DONE: "確認済：この案件の元請事業者であることが確認できました。この案件の就業履歴情報を建設キャリアアップシステムへ登録できます。",
  MESSAGE_CCUSID_PM_ERROR1: "確認済：この案件の元請事業者であることを確認できませんでした。この案件の就業履歴情報を建設キャリアアップシステムへ登録することはできません。",
  MESSAGE_CCUSID_PM_ERROR2: "エラー：CCUS現場IDの認証に失敗したため、この案件の元請事業者であるかどうかを確認できませんでした。",

  TITLE_APPROVE_CCUS: "就業履歴情報登録",
  DESCRIPTION_APPROVE_CCUS: "選択した就業履歴情報を承認し、CCUSへ登録してもよろしいでしょうか？",
  TITLE_VERIFY_CCUS_COMPANY: "CCUS事業者ID認証済",
  TITLE_SUBMIT_AUTHENTICATION_CCUS: "技能者認証",
  TITLE_ERROR_CCUS: "エラー",
  CONTENT_SUBMIT_AUTHENTICATION_CCUS: "選択した技能者を建設キャリアアップシステムへ",
  CONTENT_SUBMIT_AUTHENTICATION_CCUS_2: "認証を実行してもよろしいでしょうか？",
  MESSAGE_CCUSID_TUTORIAL: "ONE Unitの就業履歴情報をCCUSに登録を行う場合は、登録前にCCUS現場IDを入力してください。",
};

export const TITLE = {
  CCUSID: "CCUS現場ID",
  DEPARTMENT_TITLE: "部門",
  DEPARTMENT: "所属部門",
  NAME: "名前",
  CCUSTECHNICIANID: "CCUS技能者ID",
  EXPIRATIONDATE: "有効期限",
  POSITION: "役職",
  ID: "ID",
  PHONENUMBER: "電話番号",
  EMAILADDRESS: "メールアドレス",
  ADDRESS: "住所",
  QUALIFICATIONGROUP: "資格グループ",
  QUALIFICATIONREGISTRATION: "資格登録",
  DATEOFACQUISITION: "取得年月日",
  ACQUISITIONNUMBER: "取得NO.",
  PLACEOFACQUISITION: "取得先",
  REMARKS: "備考",

  POPUP_CCUS061_NAME: "氏名",
  POPUP_CCUS061_NAME_KATAKANA: "氏名（カタカナ）",
  POPUP_CCUS061_EMAIL_ADDRESS: "メールアドレス",
  POPUP_CCUS061_CURRENT_ADDRESS: "現住所",

  POPUP_CCUS061_DATEOFBIRTH: "生年月日",
  POPUP_CCUS061_GENDER: "性別",
  POPUP_CCUS061_ABO_BLOOD_TYPE: "ABO式血液型",

  POPUP_CCUS061_SKILLED_WORKER_ID: "技能者ID",
  POPUP_CCUS061_NATIONALITY: "国籍",
  POPUP_CCUS061_TELEPHONE_NUMBER_HOME: "電話番号（自宅）",

  POPUP_CCUS061_STATUS_OF_RESIDENCE: "在留資格",
  POPUP_CCUS061_MOBILE_NUMBER: "携帯番号",
  POPUP_CCUS061_PERIOD_RESIDENCE: "在留期間",

  POPUP_CCUS061_EMERGENCY_CONTACT: "緊急連絡先",
  POPUP_CCUS061_ADDRESS: "住所",
  POPUP_CCUS061_PHONE_NUMBER: "電話番号",
  POPUP_CCUS061_RELATIONSHIP: "続柄",

  POPUP_CCUS061_HEALTH_INSURANCE: "健康保険",
  POPUP_CCUS061_ENROLLMENT_STATUS: "加入状況",
  POPUP_CCUS061_REASON_FOR_EXEMPTION: "適用除外の理由",
  POPUP_CCUS061_TYPE: "種類",
  POPUP_CCUS061_NAME_INSURER: "保険者名称",
  POPUP_CCUS061_PENSION_INSURANCE: "年金保険",
  POPUP_CCUS061_EMPLOYMENT_INSURANCE: "雇用保険",
  POPUP_CCUS061_CLASSIFICATION: "被保険者種類・区分",

  POPUP_CCUS061_CONSTRUCTION_INDUSTRY_ENROLLMENT_STATUS: "建設業退職金共済制度_加入状況",
  POPUP_CCUS061_SMALL_AND_MEDIUM_ENROLLMENT_STATUS: "中小企業退職金共済制度_加入状況",
  POPUP_CCUS061_ACCIDENT_COMPENSATION_INSURANCE: "労災保険特別加入",
  POPUP_CCUS061_INSURANCE_TYPE: "保険種類",

  POPUP_CCUS061_GENERAL_HEALTH_CHECK_UP_DATE: "一般健康診断受診日",
  POPUP_CCUS061_SPECIAL_HEALTH_EXAMINATION_INFORMATION_LIST: "特殊健康診断情報リスト",
  POPUP_CCUS061_TYPE_HEALTH: "種別",
  POPUP_CCUS061_DATE_OF_VISIT: "受診日",
  POPUP_CCUS061_PNEUMOCONIOSIS_MEDICAL_EXAMINATION_DATE: "じん肺健康診断受診日",
  POPUP_CCUS061_JOB_INFORMATION_LIST: "職種情報リスト",
  POPUP_CCUS061_JOB_TITLE: "職種名",

  POPUP_CCUS061_SKILLED_WORKER_TO_FILL_IN: "経験記入欄_技能者本人記入用",
  POPUP_CCUS061_COMPANY_AFFILIATION: "経験記入欄_所属事業者記入用",
  POPUP_CCUS061_BACKGROUND_GRADUATE_DESIGNATED_DEPARTMENT: "学歴_指定学科卒",
  POPUP_CCUS061_BACKGROUND_EDUCATIONAL_BACKGROUND: "学歴_学歴",

  POPUP_CCUS061_REGISTERED_KEY_TECHNICIAN_INFORMATION_LIST: "登録基幹技能者情報リスト",
  POPUP_CCUS061_REGISTERED_KEY_TECHNICIAN_NAME: "登録基幹技能者名",
  POPUP_CCUS061_COMPLETION_DATE: "修了日",
  POPUP_CCUS061_DATE_EXPIRY: "有効期限",

  POPUP_CCUS061_LIST_QUALIFICATIONS_HELD: "保有資格情報リスト",
  POPUP_CCUS061_QUALIFICATION_NAME: "資格名",
  POPUP_CCUS061_EXPIRATION_DATE: "有効期限年月日",
  POPUP_CCUS061_ACQUISITION_DATE: "取得年月日",
  POPUP_CCUS061_REGISTRATION_DATE: "登録年月日"
};

export const MESSAGE_STATUS = {
  SUCCESS: "搬入済",
  NORMAL: "取消",
  WARNING: "未搬入",
  DANGER: "受け不可",
  BLUE: "新規作成",
};

export const NOTIFICATION_TITLE = {
  SUCCESS: "成功しました",
  ERROR: "エラーが発生しました",
  MESSAGE_DELETE_NOTIFICATION_SUCCESS: "通知を削除しました",
  ERROR_CCUS: "技能者情報が見つかりませんでした。",
};

export const LABEL_MESSAGE = {
  CANCEL_MODAL: "キャンセル",
  OK_MODAL: "はい",
  OK_MODAL_2: "確認",
  OK_DELETE: "削除",
};

export const TITLE_CCUS01 = {
  CCUISID: "CCUS事業者ID",
  CCUS_BUSINESS_ID: "CCUS事業者ID（確認用）",
  CCUISID_PLACEHOLDER: "IDを入力してください",
  CCUS_PASSWORD: "CCUS事業者パスワード",
  CCUS_BUSINESS_PASSWORD: "CCUS事業者パスワード（確認用）",
  CCUS_PASSWORD_PLACEHOLDER: "パスワードを入力してください",
  LABEL_TITLE_CCUS01: `建設キャリアアップシステム(CCUS)
  事業者ID認証` ,
  LABEL_TITLE_CCUS01_UPLOAD_CCUSID: "建設キャリアアップシステムへログインするための <br/> 管理者のIDとパスワードを入力してください。",

  LABEL_SUBTITLE_CCUS01_REGISTER1: "建設キャリアアップシステムへログインするための",
  LABEL_SUBTITLE_CCUS01_REGISTER2: "管理者のIDとパスワードを入力してください。",
  LABEL_SUB_TITLE_CCUS01: " 下記のIDおよびパスワードを認証するため <br/> 建設キャリアアップシステムへ送信してもよろしいでしょうか？",
  LABEL_CCUS_COMPANY_WAITING: `送信したID及びパスワードを建設キャリアアップシステムに確認中です。
しばらくお待ちください。`,

  LABEL_CCUS_COMPANY_ERROR: `エラー：IDまたはパスワードが正しくありません。編集し、再送信をお願いします。 
尚、認証が完了するまで建設キャリアアップシステムと連携する機能はご利用できません。`,

  LABEL_CCUS_COMPANY_DONE: `送信したID及びパスワードが認証されました。 
建設キャリアアップシステムと連携する機能をご利用できます。`
};

export default MESSAGE;
