import React, { useMemo } from "react";
import { CM0030Hanlder } from "../handler/HandlerImpl";
import IconDown from "../../../../../assets/icons/icon-collapse-cm.svg";
import helpers from "../../../../../common/helpers/common";
import COMMON from "../../../../../common/constants/COMMON";
import EmptyData from "../../../../../assets/icons/empty-data.svg";
import { uuidv4 } from "@firebase/util";
import { Empty } from "antd";

interface props {
  handler: CM0030Hanlder;
}
const NO_DATA = "データなし";

const Table = ({ handler }: props) => {
  console.log(handler.dataView);
  const contentTable = useMemo(() => {
    if (!handler.dataView || handler.dataView.length === 0)
      return (
        <tr>
          <td colSpan={10}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={NO_DATA} />
          </td>
        </tr>
      );
    const elementTable: any = [];
    handler.dataView.forEach((element, index) => {
      elementTable.push(
        <React.Fragment key={element.id}>
          <div style={{ height: 4 }}></div>
          <tr
            className="row-level-0 button-style"
            onClick={() => handler.funcSetExpendKey(element.id)}
          >
            <td colSpan={2} style={{ width: 55 }}>
              {index + 1}
            </td>
            <td
              colSpan={4}
              style={{ textAlign: "left", paddingLeft: 35, width: 632 }}
            >
              {helpers.getShortTextWithToolTip(element.projectName, 20)}
            </td>
            <td style={{ width: 170 }}>
              {Math.floor(element?.paymentAmount).toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
              円
            </td>
            <td style={{ width: 170 }}>
              {Math.floor(element?.consumptionTax).toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
              円
            </td>
            <td style={{ width: 170 }}>
              {Math.floor(element?.taxInclude).toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
              円
            </td>
            <td>
              <img
                alt=""
                src={IconDown}
                className={`cm024-icon ${
                  handler.funcCheckKeyExpand(element.id)
                    ? ""
                    : "cm024-icon-active"
                }`}
                onClick={() => handler.funcSetExpendKey(element.id)}
              />
            </td>
          </tr>
        </React.Fragment>
      );

      if (element.children) {
        elementTable.push(
          <tr
            key={`table${element.id}`}
            className="bg-fa"
            style={{
              display: handler.funcCheckKeyExpand(element.id)
                ? undefined
                : "none",
            }}
          >
            <td colSpan={2}></td>
            <td>工事名</td>
            <td>項目名</td>
            <td>対象期間</td>
            <td>支払日</td>
            <td></td>
            <td></td>
            <td></td>

            <td
              style={{ width: "40px", textAlign: "center", marginTop: 13 }}
            ></td>
          </tr>
        );

        if (element.children && element?.children?.length > 0) {
          element.children.forEach((child2: any, index: number) => {
            elementTable.push(
              <tr
                key={child2.key}
                className="bg-fa"
                style={{
                  display: handler.funcCheckKeyExpand(element.id)
                    ? undefined
                    : "none",
                }}
              >
                <td></td>
                <td>{index + 1}</td>
                <td>
                  {" "}
                  {helpers.getShortTextWithToolTip(
                    child2?.constructionDescription,
                    20
                  )}
                </td>
                <td>{helpers.getShortTextWithToolTip(child2?.item, 10)} </td>
                <td>{`${helpers.formatDatePresenter(
                  child2?.startDate,
                  COMMON.FORMAT_DATE_CM
                )}～${helpers.formatDatePresenter(
                  child2?.endDate,
                  COMMON.FORMAT_DATE_CM
                )}`}</td>
                <td>
                  {helpers.formatDatePresenter(
                    child2?.paymentDate,
                    COMMON.FORMAT_DATE_CM
                  )}
                </td>
                <td>
                  {child2?.paymentAmount.toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })}
                  円{" "}
                </td>
                <td>
                  {child2?.consumptionTax.toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })}
                  円{" "}
                </td>
                <td>
                  {child2?.taxIncluded.toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })}
                  円
                </td>
                <td style={{ width: "40px" }}></td>
              </tr>
            );
          });
        } else {
          elementTable.push(
            <tr
              key={`table${element.id}nodata`}
              style={{
                display: handler.funcCheckKeyExpand(element.id)
                  ? undefined
                  : "none",
              }}
            >
              <td colSpan={10}>
                <Empty
                  style={{ width: "100%" }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={NO_DATA}
                />
              </td>
            </tr>
          );
        }
      }
    });
    return elementTable;
  }, [handler.dataView, handler.expandKey]);

  return (
    <div
      className="cm024-table"
      onScroll={(e: any) => {
        handler.handleScroll(
          e,
          helpers.getFilterTimeMonth(handler.from),
          helpers.getFilterTimeMonthTo(handler.to)
        );
      }}
    >
      <table>
        <thead>
          <tr>
            <th colSpan={2} style={{ width: 55 }}>
              No
            </th>
            <th
              colSpan={4}
              style={{ width: 632, textAlign: "left", paddingLeft: 35 }}
            >
              案件名
            </th>

            <th style={{ width: 170 }}>支払金額(税抜)</th>
            <th style={{ width: 170 }}>消費税</th>
            <th style={{ width: 170 }}>支払金額(税込)</th>
            <th style={{ width: 40 }}></th>
          </tr>
        </thead>
        <tbody>{contentTable}</tbody>
        <tfoot>
          <tr className="table-foot">
            <td
              colSpan={6}
              style={{ width: 55, textAlign: "left", paddingLeft: 30 }}
            >
              合計金額
            </td>
            <td style={{ width: 170 }}>
              {(
                handler?.totalIndirectCost?.paymentAmountExcludeTax ?? 0
              ).toLocaleString("en-US", { maximumFractionDigits: 0 })}
              円
            </td>
            <td style={{ width: 170 }}>
              {(handler?.totalIndirectCost?.consumptionTax ?? 0).toLocaleString(
                "en-US",
                { maximumFractionDigits: 0 }
              )}
              円
            </td>
            <td style={{ width: 170 }}>
              {(handler?.totalIndirectCost?.taxIncluded ?? 0).toLocaleString(
                "en-US",
                { maximumFractionDigits: 0 }
              )}
              円
            </td>
            <td style={{ width: 40 }}></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Table;
