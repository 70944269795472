import { format } from "path";
// Core library
import { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
// Services
import { CM0019ServiceImpl } from "./../../usecase/ServiceImpl";
import { Form } from "antd";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";
import { TableDataEntity, TableSummaryData } from "../../entity/Entity";
import {
  convertDataExport,
  convertDataTable,
  convertSummaryData,
} from "../../helper";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { CM0019TemplateExport } from "../../../../../common/helpers/exports/cm/template_cm0019";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { RootState } from "../../../../../store";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

export const Project_ALL = {
  name: "すべて",
  id: "all",
};

export const DEFAULT_TAB_KEY = "tab";
export const PROJECT_PARAM_ID_KEY = "projectId";
export const FROM_MONTH_KEY = "fromMonth19";
export const TO_MONTH_KEY = "toMonth19";
export const CM0019_HOLD_KEY = "1";
export const CM0020_HOLD_KEY = "2";
export const CM0024_HOLD_KEY = "3";
export const CM0028_HOLD_KEY = "4";
export const CM0030_HOLD_KEY = "5";
export const CM0032_HOLD_KEY = "6";
export const COLUMN_SORT = "sort";
const PARAMS = {
  sortType: "sortType1",
  sortBy: "sortBy1",
  disableBtn: "disableBtn1",
  page: "page",
};

const DEFAULT_FILTER = {
  targetPeriod: [moment().startOf("month"), moment()],
  status: "all",
};

const CM0019Handler = (service: CM0019ServiceImpl) => {
  const [formFilter] = Form.useForm();

  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.common.loading);

  const [searchParams, setSearchParams] = useSearchParams();
  const [listProject, setListProject] = useState<any[]>([]);
  const [loadingOption, setLoadingOption] = useState(false);
  const [keywordOptionConstruction, setKeywordOptionConstruction] =
    useState<any>(undefined);
  const [pageOptionConstruction, setPageOptionConstruction] = useState(
    COMMON.DEFAULT_PAGE
  );
  const [sizeOptionConstruction, setSizeOptionConstruction] = useState(
    COMMON.DEFAULT_SIZE
  );
  const [expandKey, setExpandKey] = useState<any[]>([]);
  const sortType = searchParams.get(PARAMS.sortType);
  const sortBy = searchParams.get(PARAMS.sortBy);
  const projectId = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "";
  const disableBtn = searchParams.get(PARAMS.disableBtn) ?? "btn1";
  const [data, setData] = useState<TableDataEntity[]>([]);
  const [page, setPage] = useState<number>(COMMON.DEFAULT_PAGE);
  const [summary, setSummary] = useState<TableSummaryData>({
    depositAmount: 0,
    materialAmount: 0,
    outsourcingAmount: 0,
    laborAmount: 0,
    indirectAmout: 0,
    grossAmount: 0,
  });
  const [render, setRender] = useState<number>(Math.random());
  const [isChangedTax, setIsChangedTax] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ numPages: 0, page: 1 });

  const initialState = (from: string, to: string) => {
    formFilter.setFieldValue("targetPeriod", [moment(from), moment(to)]);
    const isExistProjectId = listProject?.find(e => e.id.toString() === projectId) !== undefined
    formFilter.setFieldValue(
      "project",
      isExistProjectId && projectId !== "" && projectId !== "all"
        ? Number(projectId)
        : Project_ALL.name
    );
    searchParams.set(FROM_MONTH_KEY, from);
    searchParams.set(TO_MONTH_KEY, to);

    setSearchParams(searchParams);
  };

  const funcCheckSortOrder = (
    columnName: string,
    sortType: string | null,
    sortBy: string | null
  ): "ASC" | "DESC" | undefined => {
    if (columnName === sortBy) {
      return !sortType ? undefined : sortType === "ASC" ? "ASC" : "DESC";
    }
  };
  const funcSortTable = (column: string, sortOrder: string) => {
    searchParams.set(PARAMS.sortBy, column);
    searchParams.set(PARAMS.sortType, sortOrder);
    setSearchParams(searchParams);
  };

  const handleTaxIncluded = () => {
    setIsChangedTax(true);
    setListProject([]);
    setData([]);
    setPage(COMMON.DEFAULT_PAGE);
    searchParams.set(PARAMS.page, COMMON.DEFAULT_PAGE + "");
    searchParams.set(PARAMS.disableBtn, "btn1");
    setSearchParams(searchParams);
  };

  const handleTaxExcluded = () => {
    setIsChangedTax(true);
    setListProject([]);
    setData([]);
    setPage(COMMON.DEFAULT_PAGE);
    searchParams.set(PARAMS.page, COMMON.DEFAULT_PAGE + "");
    searchParams.set(PARAMS.disableBtn, "btn2");
    setSearchParams(searchParams);
  };
  const funcSetExpendKey = (key: any) => {
    const checkKey = funcCheckKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const funcCheckKeyExpand = (key: any) => {
    if (!key || !expandKey) return false;
    return expandKey.some((element: any) => element === key);
  };

  const formatProject = (data: any) => {
    const arr: any = [];
    if (data?.length > 0) {
      data.map((item: any) => {
        arr.push({
          id: item.id,
          name: item.projectName,
        });
      });
    }
    return arr;
  };

  const getListProject = async (params: {
    page: number;
    size: number;
    from: string;
    to: string;
    keyword?: string;
    projectId?: string;
  }): Promise<any> => {
    const res = await service.getReSummaryCostProjectList({
      ...params,
      sortBy: COMMON.DEFAULT_SORT_BY,
      sortType: COMMON.DEFAULT_SORT_TYPE,
    });
    if (res?.results) {
      const data = formatProject(res.results);
      return data;
    }
  };

  const getListProjectFirstOpenModal = async (params: any): Promise<any> => {
    let dataOutput: any[] = [];

    let stop = true;
    let index = 0;

    do {
      const data = await getListProject({
        page: Number(params.page) + index,
        size: params.size,
        from: params.from,
        to: params.to,
        projectId: projectId,
      });

      const objFinded = data.find(
        (item: any) => Number(item.id) === Number(params.projectId)
      );

      dataOutput.push(...data);

      index += 1;

      if (
        objFinded ||
        params.projectId === "" ||
        params.projectId === "all" ||
        data.length === 0
      ) {
        stop = false;
      }
    } while (stop);

    setListProject([
      { id: Project_ALL.id, name: Project_ALL.name },
      ...dataOutput,
    ]);
  };

  let timeOut: any = useRef();
  const handleSearchOptionConstruction = (
    searchValue: string,
    from: string,
    to: string
  ) => {
    if (timeOut.current) clearTimeout(timeOut.current);
    timeOut.current = setTimeout(async () => {
      try {
        setLoadingOption(true);
        setKeywordOptionConstruction(searchValue);
        setPageOptionConstruction(COMMON.DEFAULT_PAGE);
        setSizeOptionConstruction(COMMON.DEFAULT_SIZE);
        const data = await getListProject({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: searchValue,
          from: from,
          to: to,
          projectId: projectId,
        });

        if (data.length != 0) {
          setListProject([
            { id: Project_ALL.id, name: Project_ALL.name },
            ...data,
          ]);
        } else {
          setListProject([
            ...data,
          ]);
        }

      } catch (error: any) {
      } finally {
        setLoadingOption(false);
      }
    }, 1000);
  };

  const handleScrollConstruction = async (
    e: any,
    from: string,
    to: string
  ): Promise<any> => {
    try {
      setLoadingOption(true);
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        setPageOptionConstruction(pageOptionConstruction + 1);
        const data = await getListProject({
          page: pageOptionConstruction + 1,
          size: sizeOptionConstruction,
          keyword: keywordOptionConstruction
            ? keywordOptionConstruction
            : undefined,
          from: from,
          to: to,
          projectId: projectId,
        });
        if (data) setListProject([...listProject, ...data]);
      }
    } catch (error: any) {
    } finally {
      setLoadingOption(false);
    }
  };

  const handleSelected = (e: any) => {
    searchParams.set(PROJECT_PARAM_ID_KEY, e);
    setSearchParams(searchParams);
  };

  const handleFilterMonth = (values: any) => {
    const e = values.targetPeriod;

    if (e) {
      if (!e[0] || !e[1]) {
        searchParams.set(
          FROM_MONTH_KEY,
          `${helpers.getFilterTimeMonth(moment().format(COMMON.FORMAT_DATE4))}`
        );
        searchParams.set(
          TO_MONTH_KEY,
          `${helpers.getFilterTimeMonthTo(
            moment().format(COMMON.FORMAT_DATE4)
          )}`
        );
      } else {
        searchParams.set(
          FROM_MONTH_KEY,
          `${helpers.getFilterTimeMonth(e[0].format(COMMON.FORMAT_DATE4))}`
        );
        searchParams.set(
          TO_MONTH_KEY,
          `${helpers.getFilterTimeMonthTo(e[1].format(COMMON.FORMAT_DATE4))}`
        );
      }
    }

    setPage(COMMON.DEFAULT_PAGE);

    searchParams.set("page", `${COMMON.DEFAULT_PAGE}`);

    setSearchParams(searchParams);
  };

  const handleOpenCalendar = (open: boolean) => {
    const _value = formFilter.getFieldValue("targetPeriod");
    if (open) {
      searchParams.set("openFilter", `${COMMON.DEFAULT_PAGE}`);
    } else {
      searchParams.delete("openFilter");
      searchParams.set(
        FROM_MONTH_KEY,
        `${helpers.getFilterTimeMonth(_value[0].format(COMMON.FORMAT_DATE4))}`
      );
      searchParams.set(
        TO_MONTH_KEY,
        `${helpers.getFilterTimeMonthTo(_value[1].format(COMMON.FORMAT_DATE4))}`
      );
    }

    setSearchParams(searchParams);
  };

  // set global variable check specific case
  const [cProjectId, setCProjectId] = useState<string>("firstTime");
  const [cFrom, setCFrom] = useState<string>("firstTime");
  const [cTo, setCTo] = useState<string>("firstTime");

  const handleGetProjectListData = async (params: any) => {

    try {
      const output: any[] = [];

      // Call all data
      dispatch(setLoading(true));
      const tax = await getTax();
      const response = await service.getReSummaryCostProjectList({ ...params });
      if (response?.pagination) {
        setPagination(response.pagination);
      }

      if (response?.results?.length > 0) {
        const promises = response.results.map(async (item: any) => {
          return service.getReSummaryCostProjectConstructionList({
            projectId: item.id,
            from: params.from,
            to: params.to,
          });
        });
        const summaryList = await Promise.all(promises);
        response.results.forEach((element: any, index: number) => {
          output.push({
            ...element,
            children: summaryList[index]?.results ?? [],
          });
        });
      }

      const convertedData = convertDataTable(
        output,
        disableBtn === "btn1" ? tax : 0
      );
      let outputResult: any[] = [];

      if (
        cProjectId !== String(params.projectId) ||
        cFrom !== String(params.from) ||
        cTo !== String(params.to)
      ) {
        setCProjectId(params.projectId);
        setCFrom(params.from);
        setCTo(params.to);
        setRender(Math.random());

        if (convertedData.length > 0) setExpandKey([convertedData[0].id]);

        outputResult = convertedData;
      } else outputResult = [...data, ...convertedData];
      if (params.projectId && params.projectId != "all") {
        const found = outputResult.find((item) => +item.id == +params.projectId)
        setExpandKey([found.id])
        setData([found]);
      } else setData(outputResult);

      setIsChangedTax(false);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleScrollTable = (e: any) => {
    const target = e.target;
    if (
      (!projectId || projectId == "all") && !loading &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight &&
      pagination.page < pagination.numPages
    ) {
      searchParams.set("page", `${page + 1}`);
      setPage((prev) => prev + 1);
    }
  };

  const handleClearDropdown = () => {
    searchParams.delete(PROJECT_PARAM_ID_KEY);
    setSearchParams(searchParams);
    formFilter.setFieldValue("project", Project_ALL.name);
  };

  const handleDeleteParams = () => {
    searchParams.delete(PROJECT_PARAM_ID_KEY);
    searchParams.delete(FROM_MONTH_KEY);
    searchParams.delete(TO_MONTH_KEY);
    searchParams.delete(PARAMS.disableBtn);

    formFilter.setFieldValue("project", Project_ALL.name);

    setSearchParams(searchParams);
  };

  const handleExport = async () => {
    try {
      dispatch(setLoading(true));
      const from = searchParams.get(FROM_MONTH_KEY) ?? "";
      const to = searchParams.get(TO_MONTH_KEY) ?? "";
      const sheetName = "社内収支【総括】";
      const fileName = `社内収支_集計_${moment().format(
        COMMON.FORMAT_DATE_SUBMIT
      )}`;

      if (from !== "" || to !== "") {
        const response = await service.doExportCM0019({
          // projectId: item.id,
          from: from,
          to: to,
        } as any);
        doExportForResponse(response, fileName + ".xlsx");
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getLineSummary = async (params: any) => {
    try {
      const resp = await service.getLineSummary(params);
      const tax = await getTax();
      setSummary(
        convertSummaryData(resp.results, disableBtn === "btn1" ? tax : 0)
      );
    } catch (error: any) {
      ErrorNotification(error?.message, NOTIFICATION_TITLE.ERROR);
    }
  };

  const getTax = async () => {
    try {
      const response = await service.getTaxSetting({});
      return response?.results?.taxSetting ?? 0;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return 0;
    }
  };

  return {
    listProject,
    formFilter,
    sortBy,
    sortType,
    disableBtn,
    loadingOption,
    data,
    page,
    summary,
    render,
    cFrom,
    cTo,
    cProjectId,
    isChangedTax,

    funcCheckSortOrder,
    funcSortTable,
    handleTaxIncluded,
    handleTaxExcluded,
    initialState,
    funcSetExpendKey,
    funcCheckKeyExpand,
    getListProjectFirstOpenModal,
    handleSearchOptionConstruction,
    handleScrollConstruction,
    handleSelected,
    handleFilterMonth,
    handleGetProjectListData,
    handleScrollTable,
    handleClearDropdown,
    handleDeleteParams,
    handleOpenCalendar,
    handleExport,
    getLineSummary,
    getTax,
  };
};

export default CM0019Handler;
