// Core library
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CM0020ServiceImpl } from "./../../usecase/ServiceImpl";
import helpers from "../../../../../common/helpers/common";
import moment from "moment";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { uuidv4 } from "@firebase/util";
import COMMON from "../../../../../common/constants/COMMON";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import {
  refreshCM0022,
  setIsExport,
  setVisibleApproveCM0022,
} from "../../../CM0022/presenter/slice/Slice";
import { Form } from "antd";
import { exportCM0020 } from "../../../../../common/helpers/exports/cm/CM0020_export";
import { RootState } from "../../../../../store";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { refreshAM0020, setIsHavingData } from "../slice/Slice";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

export const MENU_ITEM = [
  {
    name: "業者別",
    key: "trader",
  },
  {
    name: "案件別",
    key: "project",
  },
];

const PARAMS = {
  menu: "menu2",
  from: "from21",
  to: "to21",
  clear: "clear21",
  from2: "from22",
  to2: "to22",
  clear2: "clear22",
  openFilter: "openFilter",
};

const PARAMS_DEFAULT = {
  menu: MENU_ITEM[0].key,
  from: helpers.getFilterTime(moment()),
  to: helpers.getFilterTime(moment()),
};

const FILE_NAME = `社内収支_材料費_業者別_${moment().format(
  COMMON.FORMAT_DATE_SUBMIT
)}`;

const FILE_NAME_CM0022 = `社内収支_材料費_案件別_${moment().format(
  COMMON.FORMAT_DATE_SUBMIT
)}`;
export const COLUMN_SORT = [
  "paymentExcludingTax",
  "taxConsumption",
  "paymentExcludedTax",
];

const CM0020Handler = (service: CM0020ServiceImpl) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.common.loading);

  const [formFilter] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const menu = searchParams.get(PARAMS.menu) || PARAMS_DEFAULT.menu;
  const from = searchParams.get(PARAMS.from) || PARAMS_DEFAULT.from;
  const to = searchParams.get(PARAMS.to) || PARAMS_DEFAULT.to;
  const clear = searchParams.get(PARAMS.clear);
  const from2 = searchParams.get(PARAMS.from2) || PARAMS_DEFAULT.from;
  const to2 = searchParams.get(PARAMS.to2) || PARAMS_DEFAULT.to;
  const clear2 = searchParams.get(PARAMS.clear2);
  const openFilter = searchParams.get(PARAMS.openFilter);

  const [dataView, setDataView] = useState<any>([]);
  const [expandKey, setExpandKey] = useState<any>([]);
  const [status, setStatus] = useState<any>({});
  const [isVisibleApprove, setVisibleApprove] = useState(false);
  const [keyOpened, setKeyOpened] = useState<number[]>([]);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [filterCurrent, setFilterCurrent] = useState({ from: "", to: "" });
  const [pagination, setPagination] = useState({ numPages: 0, page: 1 });
  const [summary, setSummary] = useState<any[]>([]);

  const getMaterialCostCompanySummary = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getMaterialCostCompanySummary(params);
      setSummary(response?.results ?? []);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneySummaryMaterialCostCompanyProjectList = async (params: {
    companyId: number;
    from: string;
    to: string;
    sortBy?: string;
    sortType?: string;
  }) => {
    const response =
      await service.getMoneySummaryMaterialCostCompanyProjectList({
        ...params,
        page: 1,
        size: COMMON.DEFAULT_SIZE,
      });
    if (response?.results && response.results.length > 0) {
      let dataFirst = [...response?.results];
      if (response.pagination.numPages > 1) {
        for (
          let pageFirst = 2;
          pageFirst <= response.pagination.numPages;
          pageFirst++
        ) {
          const responseRe =
            await service.getMoneySummaryMaterialCostCompanyProjectList({
              ...params,
              page: pageFirst,
              size: COMMON.DEFAULT_SIZE,
            });
          if (responseRe?.results && responseRe?.results?.length > 0) {
            dataFirst = [...dataFirst, ...responseRe?.results];
          }
        }
      }
      return dataFirst;
    }
    return [];
  };
  const getMoneySummaryMaterialCostCompanyProjectListMaterialCost =
    async (params: {
      projectId: number;
      companyId: number;
      from: string;
      to: string;
      sortBy?: string;
      sortType?: string;
    }) => {
      const response =
        await service.getMoneySummaryMaterialCostCompanyProjectListMaterialCost(
          {
            ...params,
            page: 1,
            size: COMMON.DEFAULT_SIZE,
            sortBy: "createdAt",
            sortType: "DESC"
          }
        );
      if (response?.results && response.results.length > 0) {
        let dataFirst = [...response?.results];
        if (response.pagination.numPages > 1) {
          for (
            let pageFirst = 2;
            pageFirst <= response.pagination.numPages;
            pageFirst++
          ) {
            const responseRe =
              await service.getMoneySummaryMaterialCostCompanyProjectListMaterialCost(
                {
                  ...params,
                  page: pageFirst,
                  size: COMMON.DEFAULT_SIZE,
                  sortBy: "createdAt",
                  sortType: "DESC"
                }
              );
            if (responseRe?.results && responseRe?.results?.length > 0) {
              dataFirst = [...dataFirst, ...responseRe?.results];
            }
          }
        }
        return dataFirst;
      }
      return [];
    };

  const getDataTable = async (
    params: {
      page: number;
      size: number;
      from: string;
      to: string;
      sortType?: string;
      sortBy?: string;
    },
    isScroll?: boolean
  ) => {
    dispatch(setLoading(true));
    try {
      if (!isScroll) {
        if (
          filterCurrent.from === params.from &&
          filterCurrent.to === params.to
        )
          return;
        setFilterCurrent({ from: params.from, to: params.to });
      }
      const resFirst = await service.getMoneySummaryMaterialCostCompanyList(
        params
      );

      const results: any[] = [];
      const keyExpand: any[] = [];

      if (resFirst?.pagination) setPagination(resFirst.pagination);

      if (resFirst?.results && resFirst.results?.length > 0) {
        for await (const [index, item] of resFirst.results.entries()) {
          let objFirst = {
            ...item,
            key: uuidv4(),
            no: index + 1 + params.size * (params.page - 1),
            taxInclude: 0,
            children: [] as any[],
          };
          if (index === 0) keyExpand.push(objFirst.key);

          const resSecond = await getMoneySummaryMaterialCostCompanyProjectList(
            {
              ...params,
              companyId: item.id,
            }
          );

          if (resSecond.length > 0) {
            for await (const [indexChild, itemChild] of resSecond.entries()) {
              let objSecond = {
                ...itemChild,
                key: uuidv4(),
                no: indexChild + 1,
                taxInclude: 0,
                children: [] as any[],
              };
              if (indexChild === 0 && index === 0)
                keyExpand.push(objSecond.key);
              const resChildThird =
                await getMoneySummaryMaterialCostCompanyProjectListMaterialCost(
                  {
                    ...params,
                    projectId: itemChild.id,
                    companyId: item.id,
                  }
                );

              let consumptionTax = 0;
              let taxInclude = 0;
              const dataConstruction = resChildThird?.map((element: any, index) => {
                consumptionTax +=
                  element.paymentAmountExcludeTax * (element?.taxSetting ?? 1);
                taxInclude +=
                  element.paymentAmountExcludeTax * (element?.taxSetting ?? 1) +
                  element.paymentAmountExcludeTax;

                return {
                  ...element,
                  key: uuidv4(),
                  no: index + 1,
                  consumptionTax: Math.floor(
                    element.paymentAmountExcludeTax * (element?.taxSetting ?? 1)
                  ),
                  taxInclude: Math.floor(
                    element.paymentAmountExcludeTax *
                    (element?.taxSetting ?? 1) +
                    element.paymentAmountExcludeTax
                  ),
                };
              })
              dataConstruction.sort((a, b: any) => {
                return moment(a.createdAt).diff(b.createdAt)
              })

              objSecond.children = dataConstruction.map((item: any, idx: number) => {
                return {
                  ...item,
                  no: idx + 1
                }
              })

              objSecond.consumptionTax = Math.floor(consumptionTax);
              objSecond.taxInclude = Math.floor(taxInclude);
              objFirst.consumptionTax += consumptionTax;
              objFirst.taxInclude += taxInclude;
              objFirst.children.push(objSecond);
            }
          }

          objFirst.consumptionTax = Math.floor(objFirst.consumptionTax);
          objFirst.taxInclude = Math.floor(objFirst.taxInclude);
          results.push(objFirst);
        }
      }

      if (params.page === 1) setExpandKey(keyExpand);
      setDataView((preState: any[]) => {
        if (params.page === 1) return results;
        else return [...preState, ...results];
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneySummaryMaterialCostCompanyStatus = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      if (filterCurrent.from === params.from && filterCurrent.to === params.to)
        return;
      setFilterCurrent({ from: params.from, to: params.to });
      const response = await service.getMoneySummaryMaterialCostCompanyStatus(
        params
      );
      if (response?.results) setStatus(response.results);
      else setStatus({});
    } catch (error) {
      setStatus({});
    }
  };

  const funcSetExpendKey = async (
    key: any,
    level: string,
    id: number,
    idParent?: number
  ) => {
    const checkKey = funcCheckKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const funcCheckKeyExpand = (key: any) => {
    if (!key || !expandKey) return false;
    return expandKey.some((element: any) => element === key);
  };

  const handleChangeMenuChosen = (menu: string) => {
    searchParams.set(PARAMS.menu, menu);
    setSearchParams(searchParams);
  };

  const initialFilter = () => {
    if (menu === MENU_ITEM[0].key)
      formFilter.setFieldValue(
        "filterTime",
        clear ? null : [moment(from), moment(to)]
      );
    else
      formFilter.setFieldValue(
        "filterTime",
        clear2 ? null : [moment(from2), moment(to2)]
      );
  };
  const funcFilterTime = (filter: any) => {
    const value = filter.filterTime;
    if (menu === MENU_ITEM[0].key) {
      searchParams.delete(PARAMS.from);
      searchParams.delete(PARAMS.to);
      searchParams.set(PARAMS.clear, "1");
      if (value) {
        searchParams.delete(PARAMS.clear);
        const fromValue = helpers.getFilterTime(value[0]);
        const toValue = helpers.getFilterTime(value[1]);
        if (fromValue !== PARAMS_DEFAULT.from) {
          searchParams.set(PARAMS.from, fromValue);
        }
        if (toValue !== PARAMS_DEFAULT.to) {
          searchParams.set(PARAMS.to, toValue);
        }
      }
    } else {
      searchParams.delete(PARAMS.from2);
      searchParams.delete(PARAMS.to2);
      searchParams.set(PARAMS.clear2, "1");
      if (value) {
        searchParams.delete(PARAMS.clear2);
        const fromValue = helpers.getFilterTime(value[0]);
        const toValue = helpers.getFilterTime(value[1]);
        if (fromValue !== PARAMS_DEFAULT.from) {
          searchParams.set(PARAMS.from2, fromValue);
        }
        if (toValue !== PARAMS_DEFAULT.to) {
          searchParams.set(PARAMS.to2, toValue);
        }
      }
    }
    setSearchParams(searchParams);
  };

  const handleSubmitData = () => {
    switch (menu) {
      case MENU_ITEM[0].key:
        setVisibleApprove(true);
        break;
      case MENU_ITEM[1].key:
        dispatch(setVisibleApproveCM0022(true));
        break;
    }
  };

  const onCancelSubmitModal = () => {
    setVisibleApprove(false);
  };

  const onSubmit = async () => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          const fileName = FILE_NAME;
          const response =
            menu === MENU_ITEM[0].key
              ? await service.putMoneySummaryMaterialCostCompanySubmit({
                fileName,
                startDate: helpers.getFilterTimeMonth(from),
                endDate: helpers.getFilterTimeMonthTo(to),
              })
              : await service.postMoneySummaryMaterialCostProjectSubmit({
                fileName: FILE_NAME_CM0022,
                startDate: helpers.getFilterTimeMonth(from2),
                endDate: helpers.getFilterTimeMonthTo(to2),
              });
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
          setVisibleApprove(false);
          setRefreshStatus((curState) => !curState);
          setFilterCurrent({ from: "", to: "" });
          dispatch(refreshCM0022());
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      className: "confirm__modal confirm__modal-purple-oke",
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: "材料費" + MESSAGE.MESSAGE_SUBMIT_CM0019,

      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };
  const exportExcel = async (e: any) => {
    if (e.detail !== 1) return;
    if (menu === MENU_ITEM[0].key) {
      dispatch(setLoading(true));
      try {
        const response = await service.doExportCM0020({
          from: from ? helpers.getFilterTimeMonth(from) : "",
          to: to ? helpers.getFilterTimeMonthTo(to) : "",
          page: 1,
          size: COMMON.DEFAULT_SIZE,
        } as any);
        doExportForResponse(response, FILE_NAME + ".xlsx");
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        dispatch(setLoading(false));
      }
    } else dispatch(setIsExport());
  };

  const handleOpenCalendar = (open: boolean) => {
    if (open) searchParams.set("openFilter", "1");
    else searchParams.delete("openFilter");
    setSearchParams(searchParams);
  };

  const handleScrollTable = (e: any) => {
    const target = e.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight &&
      pagination.page < pagination.numPages
    ) {
      getDataTable(
        {
          from: clear ? "" : helpers.getFilterTimeMonth(from),
          to: clear ? "" : helpers.getFilterTimeMonthTo(to),
          page: pagination.page + 1,
          size: COMMON.DEFAULT_SIZE,
          sortBy: "createdAt",
          sortType: "DESC"
        },
        true
      );
    }
  };

  const checkHavingData = (value: boolean) => {
    dispatch(setIsHavingData(value));
  };

  return {
    formFilter,
    dataView,
    expandKey,
    menu,
    from,
    to,
    clear,
    from2,
    to2,
    clear2,
    isVisibleApprove,
    status,
    refreshStatus,
    openFilter,
    filterCurrent,
    summary,
    funcSetExpendKey,
    funcCheckKeyExpand,
    handleChangeMenuChosen,
    funcFilterTime,
    handleSubmitData,
    getDataTable,
    onSubmit,
    onCancelSubmitModal,
    getMoneySummaryMaterialCostCompanyStatus,
    initialFilter,
    exportExcel,
    handleOpenCalendar,
    handleScrollTable,
    getMaterialCostCompanySummary,
    checkHavingData,
  };
};

export default CM0020Handler;
