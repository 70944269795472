import React from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import EyeViewDetailSvg from "../../../../../assets/icons/eye-view-detail.svg";
import IconView from "../../../../../assets/icons/eye-view-detail.svg";

import { convertMoneyCompanySummaryOutsourceCostResult } from "../../helper";
import helpers from "../../../../../common/helpers/common";
import {
  ReSummaryCompanySummaryOutsourceCostResult,
  ReSummaryCompanySummaryOutsourceCostTotalResult,
} from "../../../CM0017/entity/Entity";
import { ReSummarySubmittedCompanyOutsourceCostResults } from "../../entity/Entity";
import { TAX_CODE } from "../../../../../common/constants/COMMON";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

type props = {
  openCM0017_2: () => void;
  funcViewModalCM017_1: (value: {
    companyId?: number;
    colabRefId?: number;
    code: string;
    from?: string;
    to?: string;
  }) => void;
  summaryCost: ReSummarySubmittedCompanyOutsourceCostResults | undefined;
  summaryTotal: ReSummaryCompanySummaryOutsourceCostTotalResult;
  taxSetting: number;
  paymentMonth?: string;
};
export const TABLE_CELL_WIDTH_CM0015: number[] = [
  100, 260, 180, 150, 120, 174, 174, 174, 174, 120,
];

const TableAboveComponent = ({
  summaryTotal,
  summaryCost,
  paymentMonth,
  taxSetting,
  openCM0017_2,
  funcViewModalCM017_1,
}: props) => {
  const documentInfo = useSelector(
    (state: RootState) => state.cm0017_3.documentInfo
  );
  console.log(summaryTotal);
  const columns: ColumnsType<any> = [
    {
      title: "支払月",
      key: "支払月",
      align: "center",
      // width: TABLE_CELL_WIDTH_CM0015[0],
      render: (record) => (
        <span className="collapsed-table-row-style">{`${paymentMonth}`}</span>
      ),
    },
    {
      title: "今回支払額",
      key: "今回支払額",
      align: "center",
      // width: TABLE_CELL_WIDTH_CM0015[1],
      render: (record) => {
        return (
          <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
            record.totalCost
          )}円`}</span>
        );
      },
    },
    {
      title: "相殺金額合計",
      key: "相殺金額合計",
      align: "center",
      // width: TABLE_CELL_WIDTH_CM0015[4] + TABLE_CELL_WIDTH_CM0015[5] + TABLE_CELL_WIDTH_CM0015[6],
      children: [
        {
          title: "案件別相殺合計金額",
          key: "案件別相殺合計金額",
          // width: TABLE_CELL_WIDTH_CM0015[6],
          children: [
            {
              title: "課税",
              key: "課税",
              align: "center",
              // width: TABLE_CELL_WIDTH_CM0015[6],]
              render: (record) => {
                return (
                  <span className="collapsed-table-row-style">
                    {`${helpers.formatNumberMoney(record.offsetCostWithTax)}円`}
                  </span>
                );
              },
            },
            {
              title: "非課税",
              key: "非課税",
              align: "center",
              // width: TABLE_CELL_WIDTH_CM0015[5],
              render: (record) => {
                return (
                  <span className="collapsed-table-row-style">
                    {`${helpers.formatNumberMoney(
                      record.offsetCostWithoutTax
                    )}円`}
                  </span>
                );
              },
            },
          ],
        },
        {
          title: (
            <main className="center-item">
              <div>業者別相殺合計金額</div>
              {/* <div
                                style={{ marginLeft: 10 }}
                                className="button-style"
                                onClick={() => openCM0017_2()}
                            >
                                <img alt="" src={EyeViewDetailSvg} />
                            </div> */}
            </main>
          ),
          key: "業者別相殺合計金額",
          align: "center",
          // width: TABLE_CELL_WIDTH_CM0015[5],
          children: [
            {
              title: (
                <div className="center-item">
                  <div style={{ marginRight: 8 }}>課税</div>
                  <img
                    className="icon-view"
                    alt=""
                    src={IconView}
                    onClick={(e) => {
                      e.stopPropagation();
                      funcViewModalCM017_1({
                        companyId: summaryCost?.company.id,
                        colabRefId: summaryCost?.company.colabRefId,
                        code: TAX_CODE.TAX,
                        from: documentInfo.from,
                        to: documentInfo.to,
                      });
                    }}
                  />
                </div>
              ),
              key: "課税",
              align: "center",
              // width: TABLE_CELL_WIDTH_CM0015[6],
              render: (record) => {
                return (
                  <span className="collapsed-table-row-style">
                    {`${helpers.formatNumberMoney(
                      record.offsetCostSummaryWithTax
                    )}円`}
                  </span>
                );
              },
            },
            {
              title: (
                <div className="center-item">
                  <div style={{ marginRight: 8 }}>非課税</div>
                  <img
                    className="icon-view"
                    alt=""
                    src={IconView}
                    onClick={(e) => {
                      e.stopPropagation();
                      funcViewModalCM017_1({
                        companyId: summaryCost?.company.id,
                        colabRefId: summaryCost?.company.colabRefId,
                        code: TAX_CODE.TAX_FREE,
                        from: documentInfo.from,
                        to: documentInfo.to,
                      });
                    }}
                  />
                </div>
              ),
              key: "非課税",
              align: "center",
              // width: TABLE_CELL_WIDTH_CM0015[5],
              render: (record) => {
                return (
                  <span className="collapsed-table-row-style">
                    {`${helpers.formatNumberMoney(
                      record.offsetCostSummaryWithoutTax
                    )}円`}
                  </span>
                );
              },
            },
          ],
        },
      ],
    },
    {
      title: "差引支払額",
      key: "差引支払額",
      align: "center",
      // width: TABLE_CELL_WIDTH_CM0015[8],
      render: (record) => {
        const formulaCalculation =
          summaryTotal.cost -
          (record.offsetCostWithTax * (1 + taxSetting) +
            record.offsetCostWithoutTax +
            record.offsetCostSummaryWithTax * (1 + taxSetting) +
            record.offsetCostSummaryWithoutTax);

        return (
          <span className="collapsed-table-row-style">
            {`${helpers.formatNumberMoney(formulaCalculation)}円`}
          </span>
        );
      },
    },
  ];

  return (
    <Table
      bordered={true}
      columns={columns}
      dataSource={convertMoneyCompanySummaryOutsourceCostResult(summaryCost)}
      className="collapsed-table-header table-footer-inside "
      pagination={false}
    />
  );
};

export default TableAboveComponent;
