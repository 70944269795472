import { Form } from "antd";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { CCUS061ServiceImpl } from "../../usecase/ServiceImpl";
import { useEffect, useState } from "react";
import MESSAGE, { LABEL_MESSAGE, NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { FilterCCUSType, TableIssueAuthenType, TableTechnicianInfoType } from "../../entity/Entity";
import moment from "moment";
import { AUTHENTICATION_KEY, DEFAULT_PARAM, MENU_ITEM, PARAMS } from "../../constants";
import COMMON from "../../../../../common/constants/COMMON";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ErrorModalCCUS from "../../../../../common/components/modal/ErrorModalCCUS";
import { setOpenModalCcus061, setShouldFetch, setUserId } from "../slice/Slice";
import REGEX from "../../../../../common/constants/REGEX";
import ModalConfirmCCUS from "../../../../../common/components/modal/ModalConfirmCCUS";
import IconWarning from "../../../../../assets/icons/icon-warning-circle-red.svg";
import IconSuccess from "../../../../../assets/icons/icon-success.svg";


const CCUS002Handler = (service: CCUS061ServiceImpl) => {
  const [formFilter] = Form.useForm();
  const [formTab2] = Form.useForm();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const state = searchParams.get(PARAMS.state) || MENU_ITEM[0].code;

  const [listUser, setListUser] = useState<any[]>([]);
  const [totalRecord, setTotalRecord] = useState<number>(0);

  const sortType = searchParams.get(PARAMS.sortType);
  const sortBy = searchParams.get(PARAMS.sortBy);
  const page = searchParams.get(PARAMS.page) || COMMON.DEFAULT_PAGE + "";
  const size = searchParams.get(PARAMS.size) || COMMON.DEFAULT_SIZE + "";

  const [listTechnician, setListTechnician] = useState<FilterCCUSType[]>([]);
  const [listDepartment, setListDepartment] = useState<FilterCCUSType[]>([]);

  const [pageTechnician, setPageTechnician] = useState<number>(DEFAULT_PARAM.page);
  const [pageDepartment, setPageDepartment] = useState<number>(DEFAULT_PARAM.page);

  const [sizeTechnician, setSizeTechnician] = useState<number>(DEFAULT_PARAM.size);
  const [sizeDepartment, setSizeDepartment] = useState<number>(DEFAULT_PARAM.size);

  const [loadingTechnician, setLoadingTechnician] = useState<boolean>(false);
  const [loadingDepartment, setLoadingDepartment] = useState<boolean>(false);

  const [dataTableEmployee, setDataTableEmployee] = useState<TableTechnicianInfoType[]>([]);
  const [dataTableRelaseKey, setDataTableRelaseKey] = useState<TableIssueAuthenType[]>([]);

  const [selectedTechnician, setSelectedTechnician] = useState<string>("")
  const [selectedDepartment, setSelectedDepartment] = useState<string>("")
  const [selectedRowsKey, setSelectedRowsKey] = useState<React.Key[]>([])

  const [keywordOptionTechnician, setKeywordOptionTechnician] = useState<string>("");
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const [isNotSubmit, setNotSubmit] = useState<boolean>(false);
  const [rowErrors, setRowErrors] = useState<{ [key: string]: { [field: string]: string } }>({});
  const handleChangeMenuChosen = (menu: string) => {
    if (menu != state) {
      setRowErrors({})
      setSelectedRowsKey([])
      setSelectedTechnician("")
      setSelectedDepartment("")
      setDataTableRelaseKey([])
      setDataTableEmployee([])
      searchParams.delete("lastReferred");
      searchParams.delete(PARAMS.sortType);
      searchParams.delete(PARAMS.sortBy);
      searchParams.set(PARAMS.state, menu);
      searchParams.set(PARAMS.page, DEFAULT_PARAM.page + "");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    onResetFilter()
  }, [state]);

  const onPageChange = (value: number) => {
    searchParams.set(PARAMS.page, value + "");
    setSearchParams(searchParams);
  };
  const onSizeChange = (value: number) => {
    searchParams.set(PARAMS.page, "1");
    searchParams.set(PARAMS.size, value + "");
    setSearchParams(searchParams);
  };

  const funcCheckSortOrder = (
    columnName: string,
    sortType: string | null,
    sortBy: string | null,
  ): 'ASC' | 'DESC' | undefined => {
    if (columnName === sortBy) {
      return !sortType ? undefined : sortType === 'ASC' ? 'ASC' : 'DESC';
    }
  };
  const funcSortTable = (column: string, sortOrder: string) => {
    searchParams.set(PARAMS.sortBy, column);
    searchParams.set(PARAMS.sortType, sortOrder);
    setSearchParams(searchParams);
  };


  const onFilter = (filter: any) => {
    if (state === MENU_ITEM[0].code) {
      searchParams.set(PARAMS.userId, filter.technician || "");
      searchParams.set(PARAMS.groupId, filter.department || "");
    } else {
      if (filter.lastReferred !== undefined) {
        searchParams.set('lastReferred', filter.lastReferred ? 'true' : 'false');
      } else {
        searchParams.delete('lastReferred');
      }
      dispatch(setShouldFetch(true))
    }

    setSearchParams(searchParams);
  };

  const openPopupDetail = (value: any) => {
    dispatch(setOpenModalCcus061(true))
    dispatch(setUserId(value))
  }

  const onResetFilter = () => {
    setSelectedTechnician("")
    setSelectedDepartment("")
    searchParams.delete("lastReferred");
    searchParams.set("lastReferred", "false");
    searchParams.delete(PARAMS.groupId);
    searchParams.delete(PARAMS.sortType);
    searchParams.delete(PARAMS.sortBy);
    searchParams.set(PARAMS.page, COMMON.DEFAULT_PAGE + "");
    formFilter.resetFields()
    formTab2.resetFields()
    setSearchParams(searchParams);
  };

  const handleGetTechnician = () => {
    const param: any = {
      page: DEFAULT_PARAM.page,
      size: DEFAULT_PARAM.size,
    }
    if (selectedDepartment) param.groupId = selectedDepartment;
    setListTechnician([]);
    setPageTechnician(1);
    setSizeTechnician(10);
    getTechnicianListCCUS(param);
  };

  const handleScrollTechnician = (e: any) => {
    const target = e.target;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageTechnician(pageTechnician + 1);
      const param: any = {
        page: pageTechnician + 1,
        size: sizeTechnician,
        keyword: keywordOptionTechnician ? keywordOptionTechnician : undefined,
      }
      if (selectedDepartment) param.groupId = selectedDepartment;
      getTechnicianListCCUS(param);
    }
  };

  const handleGetDepartment = () => {
    const param: any = {
      page: DEFAULT_PARAM.page,
      size: DEFAULT_PARAM.size,
    }

    if (selectedTechnician) param.userId = selectedTechnician;
    setListDepartment([]);
    setPageDepartment(1);
    setSizeDepartment(10);
    getDepartmentListCCUS(param);
  };

  const handleScrollDepartment = (e: any) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageDepartment(pageTechnician + 1);
      const param: any = {
        state,
        page: pageDepartment + 1,
        size: sizeDepartment,
      }
      if (selectedTechnician) param.userId = selectedTechnician;
      getDepartmentListCCUS(param);
    }
  };

  const getTechnicianListCCUS = async (params: {
    groupId?: number;
    page: number;
    size: number;
    keyword?: string;
  }) => {
    setLoadingTechnician(true)
    try {
      const response = await service.getTechnicianListCCUS(params);
      if (params.page === 1) {
        setListTechnician(response);
      } else {
        setListTechnician([...listTechnician, ...response]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingTechnician(false)
    }
  };

  const handleSearchOptionTechnician = (searchValue: string) => {
    getTechnicianListCCUS({
      page: 1,
      size: 10,
      keyword: searchValue,
    });
    setKeywordOptionTechnician(searchValue);
    setPageTechnician(1);
    setSizeTechnician(10);
  };

  const getDepartmentListCCUS = async (params: {
    userId?: number;
    state?: string;
    page: number;
    size: number;
  }) => {
    setLoadingDepartment(true)
    try {
      const response = await service.getDepartmentListCCUS(params);
      if (params.page === 1) {
        setListDepartment(response);
      } else {
        setListDepartment([...listDepartment, ...response]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingDepartment(false)
    }
  };

  const getListEmployeeVerification = async (params: {
    projectId?: number;
    companyId?: number;
    approverId?: number;
    state?: string;
    from?: string;
    to?: string;
    syncTime?: string;
    registerType?: string;
    sortBy?: string;
    sortType?: string;
    page?: number;
    size?: number;
  }) => {
    dispatch(setLoading(true))
    try {
      const response = await service.getListEmployeeVerification(params);

      setDataTableEmployee(convertDataEmployee(response.results));

      setTotalRecord(response.pagination.count || 0)
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false))
    }
  };

  const convertDataEmployee = (data: any) => {
    const result = data.map((item: any, index: number) => {
      return {
        key: item?.id,
        No: index + 1 + (Number(page) - 1) * Number(size),
        fullName: item?.fullName,
        group: item?.group?.name,
        ccusId: item?.ccusId,
        expiredDate: moment(item?.expiredDate, true).isValid()
          ? moment(item?.expiredDate).format(COMMON.FORMAT_DATE)
          : "",
      }
    })
    return result
  }

  const getListCompanyEmployee = async (params: { lastReferred?: boolean }) => {
    dispatch(setLoading(true));
    try {
      const response = await service.getListCompanyEmployee(params);
      const data = Array.isArray(response?.results) ? [...response.results] : [];
      const stateList = [0, 20, 10];
      if (data.length == 0) ErrorNotification(NOTIFICATION_TITLE.ERROR_CCUS);
      const result = data.sort((a, b) => {
        return stateList.indexOf(a.state) - stateList.indexOf(b.state);
      });

      setDataTableRelaseKey(convertDataCompanyEmployee(result));
      handleDataFormCCUS062(convertDataCompanyEmployee(result));
      funcSetDefaultRowKey(convertDataCompanyEmployee(result));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };


  const convertDataCompanyEmployee = (data: any) => {
    const result = data.map((item: any, index: number) => {
      return {
        ...item,
        key: item?.ccusId,
        dateOfBirth: moment(item?.dateOfBirth, true).isValid()
          ? moment(item?.dateOfBirth).format(COMMON.FORMAT_DATE_CCUS062)
          : "",
      }
    })
    return result
  }

  const handleDataFormCCUS062 = (data: any) => {
    const results: any = {}
    if (data?.length > 0) {
      data.forEach((item: any) => {
        if (item.ccusId) {
          results[`record${item.key}`] = {
            ccusId: item.ccusId,
          };
        }

        if (item.lastName) {
          results[`record${item.key}`] = {
            ...results[`record${item.key}`],
            lastName: item.lastName,
          };
        }

        if (item.firstName) {
          results[`record${item.key}`] = {
            ...results[`record${item.key}`],
            firstName: item.firstName,
          };
        }

        if (item.lastNameKatakana) {
          results[`record${item.key}`] = {
            ...results[`record${item.key}`],
            lastNameKatakana: item.lastNameKatakana,
          };
        }

        if (item.firstNameKatakana) {
          results[`record${item.key}`] = {
            ...results[`record${item.key}`],
            firstNameKatakana: item.firstNameKatakana,
          };
        }

        if (item.confirmCode) {
          results[`record${item.key}`] = {
            ...results[`record${item.key}`],
            confirmCode: item.confirmCode,
          };
        }

        if (item.dateOfBirth) {
          results[`record${item.key}`] = {
            ...results[`record${item.key}`],
            dateOfBirth: moment(item?.dateOfBirth, true).isValid()
              ? moment(item?.dateOfBirth).format(COMMON.FORMAT_DATE_CCUS062)
              : "",
          };
        }
        results[`record${item.key}`] = {
          ...results[`record${item.key}`],
          state: item.state,
        };
        results[`record${item.key}`] = {
          ...results[`record${item.key}`],
          isForeigner: item.isForeigner,
        };
      });
    }
    formTab2.setFieldsValue(results)
  }

  const onChangeTechnician = (value: string) => {
    setSelectedTechnician(value);
  };

  const onChangeDepartment = (value: string) => {
    setSelectedDepartment(value);
  };

  const getSelectedKey = (values: React.Key[]) => {
    setSelectedRowsKey(values)
  };

  const postEmployeeCompanyVerify = async (data: any) => {
    try {
      const response = await service.postEmployeeCompanyVerify({ items: data });
      SuccessNotification(
        response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
      dispatch(setShouldFetch(true))
      setIsRefresh(!isRefresh)
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  }

  const OnSubmitAuthentication = (value: any) => {
    const dataForm = Object.values(value)
    const dataPayload = dataForm.map((item: any) => {
      return {
        ccusId: item.ccusId,
        confirmCode: item.confirmCode ?? "",
        dateOfBirth: item.dateOfBirth ?? "",
        firstName: item.firstName ?? "",
        firstNameKatakana: item.firstNameKatakana ?? "",
        lastName: item.lastName ?? "",
        lastNameKatakana: item.lastNameKatakana ?? "",
        state: item.state,
        isForeigner: item.isForeigner ?? false
      }
    })

    const listKey = funcFilterKeySuccess(selectedRowsKey, dataPayload)
    const selectedRowValues = listKey.map((key) => value[`record${key}`]);
    const errors: any[] = [];
    const fieldsToClear: any[] = [];

    formTab2.getFieldsError().forEach(({ name }) => {
      const rowKey = name[0].toString().replace("record", "");
      if (!listKey.includes(parseInt(rowKey))) {
        fieldsToClear.push({ name, errors: [] });
      }
    });

    if (!selectedRowValues.length && Object.keys(rowErrors).length != 0) {
      return
    }

    selectedRowValues.forEach((rowValue, index) => {
      const fieldErrors = [];

      // Validate lastNameKatakana
      // if (rowValue.lastNameKatakana) {
      //   let value = rowValue.lastNameKatakana.trim().replaceAll(" ", "").replaceAll("　", "");
      //   if (REGEX.ONLY_KATAKANA.test(value)) {
      //     fieldErrors.push({
      //       name: [`record${listKey[index]}`, `lastNameKatakana`],
      //       errors: [MESSAGE.MESSAGE_PM027_MSG_012],
      //     });
      //   } else {
      //     fieldsToClear.push({
      //       name: [`record${listKey[index]}`, `lastNameKatakana`],
      //       errors: [],
      //     });
      //   }
      // }

      if (
        !(rowValue.lastName && rowValue.lastName.trim().replaceAll(" ", "").replaceAll("　", ""))
        && !(rowValue.firstName && rowValue.firstName.trim().replaceAll(" ", "").replaceAll("　", ""))
        && !(rowValue.lastNameKatakana && rowValue.lastNameKatakana.trim().replaceAll(" ", "").replaceAll("　", ""))
        && !(rowValue.firstNameKatakana && rowValue.firstNameKatakana.trim().replaceAll(" ", "").replaceAll("　", ""))
        && !(rowValue.confirmCode && rowValue.confirmCode.trim().replaceAll(" ", "").replaceAll("　", ""))
      ) {
        fieldErrors.push({
          name: [`record${listKey[index]}`, `lastName`],
          errors: [MESSAGE.MESSAGE_CCUS061_VALID],
        });
        handleRowError(
          rowValue.ccusId,
          MESSAGE.MESSAGE_CCUS061_VALID,
          "lastName"
        );
      }

      // Validate lastName
      if (rowValue.lastName) {
        let value = rowValue.lastName.trim().replaceAll(" ", "").replaceAll("　", "");
        if (!REGEX.NOT_VIETNAM.test(value)) {
          fieldErrors.push({
            name: [`record${listKey[index]}`, `lastName`],
            errors: [MESSAGE.MESSAGE_INVALID_INPUT],
          });
        } else {
          fieldsToClear.push({
            name: [`record${listKey[index]}`, `lastName`],
            errors: [],
          });
        }
      }

      // Validate lastName
      if (rowValue.firstName) {
        let value = rowValue.firstName.trim().replaceAll(" ", "").replaceAll("　", "");
        if (!REGEX.NOT_VIETNAM.test(value)) {
          fieldErrors.push({
            name: [`record${listKey[index]}`, `firstName`],
            errors: [MESSAGE.MESSAGE_INVALID_INPUT],
          });
        } else {
          fieldsToClear.push({
            name: [`record${listKey[index]}`, `firstName`],
            errors: [],
          });
        }
      }

      // Validate dateOfBirth
      if (rowValue.dateOfBirth) {
        const value = rowValue.dateOfBirth;
        if (!/^\d{8}$/.test(value)) {
          fieldErrors.push({
            name: [`record${listKey[index]}`, `dateOfBirth`],
            errors: [MESSAGE.MESSAGE_INVALID_INPUT],
          });
        } else {
          const [year, month, day] = [value.slice(0, 4), value.slice(4, 6), value.slice(6, 8)];
          const isValidDate = moment(`${year}-${month}-${day}`, "YYYY-MM-DD", true).isValid();

          if (

            year > new Date().getFullYear() ||
            !/^(0[1-9]|1[0-2])$/.test(month) ||
            !isValidDate
          ) {
            fieldErrors.push({
              name: [`record${listKey[index]}`, `dateOfBirth`],
              errors: ["入力内容が不正です。"],
            });
          } else {
            fieldsToClear.push({
              name: [`record${listKey[index]}`, `dateOfBirth`],
              errors: [],
            });
          }
        }
      } else {
        fieldErrors.push({
          name: [`record${listKey[index]}`, `dateOfBirth`],
          errors: [MESSAGE.MESSAGE_REQUIRED_CCUS],
        });
        handleRowError(
          rowValue.ccusId,
          MESSAGE.MESSAGE_REQUIRED_CCUS,
          "dateOfBirth"
        );
      }
      if (fieldsToClear.length > 0) {
        formTab2.setFields(fieldsToClear);
      }
      if (fieldErrors.length > 0) {
        errors.push(...fieldErrors);
      }
    });

    if (errors.length > 0 || Object.keys(rowErrors).length > 0) {

      formTab2.setFields(errors);
      setNotSubmit(false);
    } else {
      if (isNotSubmit) {
        setNotSubmit(false);
        return
      } else {
        ModalConfirmCCUS({
          onOk: async () => {
            const filteredItems = dataPayload.filter((item: any) => listKey.includes(item.ccusId));
            const result = filteredItems.map((item: any) => {
              return {
                ccusId: item.ccusId,
                confirmCode: item.confirmCode ?? "",
                dateOfBirth: moment(item?.dateOfBirth, true).isValid()
                  ? moment(item?.dateOfBirth).format(COMMON.FORMAT_DATE_CCUS)
                  : "",
                firstName: item.firstName ?? "",
                firstNameKatakana: item.firstNameKatakana ?? "",
                lastName: item.lastName ?? "",
                lastNameKatakana: item.lastNameKatakana ?? "",
                isForeigner: item.isForeigner ?? false
              }
            }
            );
            await postEmployeeCompanyVerify(result)
            setRowErrors({})
          },
          onCancel: () => { },
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          description: [MESSAGE.CONTENT_SUBMIT_AUTHENTICATION_CCUS, MESSAGE.CONTENT_SUBMIT_AUTHENTICATION_CCUS_2],
          title: MESSAGE.TITLE_SUBMIT_AUTHENTICATION_CCUS,
          iconUrl: IconSuccess
        })
      }

    }

  }

  const openModalError = (error: any) => {
    ErrorModalCCUS({
      description: [error.message, error.action],
      okText: LABEL_MESSAGE.CANCEL_MODAL,
      title: MESSAGE.TITLE_ERROR_CCUS,
      onOk: () => {
      },
      iconUrl: IconWarning
    });
  }

  const funcSetDefaultRowKey = (raw: any) => {
    if (!raw) return;
    const listDataAuthen = raw.filter(
      (row: any) => row.state === AUTHENTICATION_KEY[1].key
    );
    const listKey = listDataAuthen?.map((element: any) => {
      return element.ccusId;
    });
    setSelectedRowsKey(listKey);
  };

  const funcFilterKeySuccess = (rawKey: any[], raw: any[]) => {
    if (!raw) return [];
    const listData = raw.filter(
      (row) =>
        row.state != AUTHENTICATION_KEY[1].key &&
        rawKey?.includes(row?.ccusId)
    );

    const listKey = listData?.map((element) => {
      return element.ccusId;
    });

    return listKey;
  };


  const handleRowError = (key: number, errorMessage: string, field: string) => {
    setRowErrors(prevErrors => {

      const updatedFields = {
        ...prevErrors[key],
        [field]: errorMessage,
      };

      const filteredFields = Object.entries(updatedFields)
        .filter(([_, value]) => value !== "")
        .reduce((acc, [field, value]) => {
          acc[field] = value;
          return acc;
        }, {} as { [field: string]: string });


      if (Object.keys(filteredFields).length === 0) {
        const { [key]: _, ...remainingErrors } = prevErrors;
        return remainingErrors;
      }

      return {
        ...prevErrors,
        [key]: filteredFields,
      };
    });
  };
  return {
    state,
    sortType,
    sortBy,
    listUser,
    formFilter,
    formTab2,
    listTechnician,
    listDepartment,
    loadingTechnician,
    loadingDepartment,
    totalRecord,
    dataTableEmployee,
    dataTableRelaseKey,
    selectedRowsKey,
    isRefresh,
    isNotSubmit,
    rowErrors,
    setNotSubmit,
    handleChangeMenuChosen,
    onPageChange,
    onSizeChange,
    funcCheckSortOrder,
    funcSortTable,
    onFilter,
    onResetFilter,
    getTechnicianListCCUS,
    getDepartmentListCCUS,
    handleGetTechnician,
    handleGetDepartment,
    handleScrollTechnician,
    handleScrollDepartment,
    OnSubmitAuthentication,
    getListEmployeeVerification,
    handleSearchOptionTechnician,
    onChangeTechnician,
    onChangeDepartment,
    getListCompanyEmployee,
    getSelectedKey,
    openModalError,
    funcFilterKeySuccess,
    openPopupDetail,
    handleRowError
  };
};

export default CCUS002Handler;
