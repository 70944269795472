// Built-in
import React, { useState } from "react";

// 3rd party lib
import moment from "moment";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";

// Source files
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { CollapsedTableServiceImpl } from "./../../usecase/ServiceImpl";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON from "../../../../../common/constants/COMMON";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import { RootState } from "../../../../../store";
import { setCheckedCollapse, setDataMaterialCost } from "../slice/Slice";
import { STATUS_PARENT } from "./HandlerMaterialCost";
import { STATUS_CM_CHILD_TABLE } from "../../../../../common/constants/COMMON";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import helpers from "../../../../../common/helpers/common";
import { setDocumentView } from "../../../../../common/components/modal-approve-view/presenter/slice/Slice";
import { setDocumentEdit } from "../../../../../common/components/modal-approve-edit/presenter/slice/Slice";
import { setEvidenceName } from "../../../CM005/presenter/slice/Slice";

const SCREEN_NAME = "CM0013";

const CollapsedTableHandler = (service: CollapsedTableServiceImpl) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [data, setData] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [editingKeys, setEditingKeys] = useState<any[]>([]);
  const [newLines, setNewLines] = useState<any[]>([]);
  const [newCount, setNewCount] = useState<number>(0);
  const [refresh, setRefresh] = useState(false);
  const [linkEvidence, setLinkEvidence] = useState("");
  const [saveKeys, setSaveKeys] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [parentData, setParentData] = useState<any>();
  const dataMaterialCost = useSelector(
    (state: RootState) => state.cm0013.dataMaterialCost
  );

  const setFirstData = (data: any) => {
    setData(data ?? []);
    setOriginalData(data ?? []);
  };
  const isEnableSaveButton = (record: any): boolean =>
    saveKeys.some((element) => element === record.key + "");

  const checkEnableButtons = (changedValues: any, values: any) => {
    const recordChangedValue = changedValues[Object.keys(changedValues)[0]];
    const changedValue = recordChangedValue[Object.keys(recordChangedValue)[0]];
    const recordKeyName = Object.keys(changedValues)[0]?.replaceAll(
      "record",
      ""
    );
    if (changedValue !== undefined && changedValue !== null) {
      // find in saveKeys
      const foundKey = saveKeys.find((key: any) => {
        return recordKeyName === key;
      });
      if (foundKey) return;
      setSaveKeys([...saveKeys, recordKeyName]);
    } else {
      const changedValueObj = values[Object.keys(changedValues)[0]];
      const isAllUndefined = Object.values(changedValueObj).every(
        (x) => x === undefined || x === null
      );
      if (isAllUndefined) {
        const newSaveKeys = saveKeys.filter(
          (key: any) => key !== recordKeyName
        );
        setSaveKeys(newSaveKeys);
      } else {
        const foundKey = saveKeys.find((key: any) => {
          return recordKeyName === key;
        });
        if (foundKey) return;
        setSaveKeys([...saveKeys, recordKeyName]);
      }
    }
  };

  const afterClearEvidence = (formName: any) => {
    const evidenceChangeValue = form.getFieldValue(formName[0]);
    const recordKeyName = formName[0]?.replaceAll("record", "");
    console.log(formName)
    const isAllUndefined = Object.values(evidenceChangeValue).every(
      (x) => x === undefined || x === null
    );
    if (isAllUndefined) {
      const newSaveKeys = saveKeys.filter((key: any) => key !== recordKeyName);
      setSaveKeys(newSaveKeys);
    } else {
      const foundKey = saveKeys.find((key: any) => {
        return recordKeyName === key;
      });
      if (foundKey) return;
      setSaveKeys([...saveKeys, recordKeyName]);
    }
  };
  const getMoneyDirectCostLaborList = async (params: {
    documentDirectLaborCostId: number;
    sortBy?: string;
    sortType?: string;
    page: number;
    size: number;
  }): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getMoneyDirectCostLaborList(params);
      let finalResult = responseData?.results ?? [];

      let checkApproved = true;
      let checkWaitingApproved = false;
      if (finalResult.length === 0) checkApproved = false;
      const dataView = finalResult.map((data, i: number) => {
        if (data.status?.code !== STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE)
          checkApproved = false;
        if (
          data.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
        )
          checkWaitingApproved = true;
        return {
          key: data.id,
          no: i + 1,
          id: data.id,
          targetPeriodFrom: data.startDate
            ? moment(data.startDate).format(COMMON.FORMAT_DATE2)
            : "",
          targetPeriodTo: data.endDate
            ? moment(data.endDate).format(COMMON.FORMAT_DATE2)
            : "",
          datePayment: data.paymentDate
            ? moment(data.paymentDate).format(COMMON.FORMAT_DATE2)
            : "",
          amountPaid: data.planLaborCost,
          evidence: data.evidence,
          actualCost: data.actualCost,
          note: data.note,
          status: data.status,
        };
      });

      const materialCost = dataMaterialCost.map((material) => {
        if (material.id === params.documentDirectLaborCostId) {
          const actualCost = finalResult.reduce((acc, cur) => {
            if (cur.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE)
              return acc + cur.actualCost;
            return acc;
          }, 0);
          const obj = {
            ...material,
            actualCost: actualCost,
            budgetBalance: material.budgetAmount - actualCost,
          };
          if (checkApproved) obj.status = STATUS_PARENT.approved;
          else if (checkWaitingApproved)
            obj.status = STATUS_PARENT.waiting_for_approve;
          else obj.status = STATUS_PARENT.rejected;
          obj.child = dataView;
          return obj;
        }
        return material;
      });
      dispatch(setDataMaterialCost(materialCost));

      // using deep copy to avoid 2 state aim the same memory address
      // setData(JSON.parse(JSON.stringify(dataView)));
      setOriginalData(JSON.parse(JSON.stringify(dataView)));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onValueNumberChange = (record: any, property: string, e: any) => {
    const value = e;
    const toNumber = Number(value);

    if (String(record.id).includes("new")) {
      // new data
      const newNewLines = newLines.map((element) => {
        if (element.id === record.id)
          return {
            ...element,
            isDisable: false,
            [`${property}`]: isNaN(toNumber) ? 0 : toNumber,
          };

        return element;
      });
      setNewLines(newNewLines);
    } else {
      // old data
      const newData = data.map((element) => {
        if (element.id === record.id)
          return {
            ...element,
            [`${property}`]: isNaN(toNumber) ? 0 : toNumber,
          };

        return element;
      });
      setData(newData);
    }
  };

  const addNewLine = () => {
    const newLineKey = `new${newCount}`;

    const newLinesData: any = {
      key: newLineKey,
      id: newLineKey,
      no: newLineKey,
      targetPeriodFrom: null,
      targetPeriodTo: null,
      datePayment: null,
      amountPaid: null,
      evidence: "",
      actualCost: null,
      status: "pending",
      isDisable: true,
    };
    setNewLines([...newLines, newLinesData]);
    setEditingKeys([...editingKeys, newLineKey]);
    setNewCount(newCount + 1);
  };

  const isEditing = (record: any): boolean =>
    editingKeys.some((element) => element === record.key);

  const edit = (record: any) => {
    setEditingKeys([...editingKeys, record.key]);
    form.setFields([
      {
        name: [`record${record.id}`, `actualCost`],
        value: record.actualCost,
      },
      {
        name: [`record${record.id}`, `datePayment`],
        value: record.datePayment ? moment(record.datePayment) : undefined,
      },
      {
        name: [`record${record.id}`, `targetPeriod`],
        value: [
          record.targetPeriodFrom ? moment(record.targetPeriodFrom) : undefined,
          record.targetPeriodTo ? moment(record.targetPeriodTo) : undefined,
        ],
      },
      {
        name: [`record${record.id}`, `amountPaid`],
        value: record.amountPaid,
      },
      {
        name: [`record${record.id}`, `evidence`],
        value: [
          {
            name: record.evidence,
            uid: Math.random(),
            key: Math.random(),
          },
        ],
      },
    ]);
  };

  const getLinkPresignUpload = async (params: any) => {
    try {
      const response = await service.getLinkPresignUpload(params);

      if (response) {
        return response.results;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const uploadTos3 = async (url: any, data: any) => {
    try {
      const response = await service.uploadToS3(url, data);
      return response.results;
    } catch (error) {
      throw error;
    }
  };

  const save = async (record: any, documentDirectLaborCostId: number) => {
    try {
      dispatch(setLoading(true));
      const { key } = record;
      const dataUpdate = form.getFieldValue(`record${key}`);
      if (String(key).includes("new")) {
        const response = await service.postMoneyDirectCostLaborCreate({
          documentDirectLaborCostId,
          data: [
            {
              actualCost: dataUpdate?.actualCost ?? 0,
              endDate: dataUpdate?.targetPeriod
                ? helpers.getFilterTimeTo(dataUpdate.targetPeriod[1])
                : null,
              paymentDate:
                helpers.getFilterTime(dataUpdate?.datePayment) ?? null,
              planLaborCost: dataUpdate?.amountPaid ?? 0,
              startDate: dataUpdate?.targetPeriod
                ? helpers.getFilterTime(dataUpdate.targetPeriod[0])
                : null,
              evidence: dataUpdate.evidence?.file
                ? dataUpdate.evidence?.file.name
                : "",
            },
          ],
        });

        if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
          if (dataUpdate.evidence?.file?.originFileObj) {
            try {
              const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
                ?.company?.id;
              const linkPreSignUpload = await getLinkPresignUpload({
                keyName: `re/${companyId}/${SCREEN_NAME}/${response.results}/${dataUpdate.evidence?.file?.name}`,
              });
              await uploadTos3(
                linkPreSignUpload,
                dataUpdate.evidence.file?.originFileObj
              );

              SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
              setRefresh(!refresh);
              const resultLine = newLines.filter((line) => line.key !== key);
              setNewLines(resultLine);
            } catch (error) {
              await service.deleteMoneyDirectCostLaborDelete({ id: +response.results });
            }

          } else {
            SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
            setRefresh(!refresh);
            const resultLine = newLines.filter((line) => line.key !== key);
            setNewLines(resultLine);
          }

        } else ErrorNotification(NOTIFICATION_TITLE.ERROR);



      } else {
        const payload = {
          id: Number(key),
          actualCost: dataUpdate?.actualCost ?? 0,
          endDate: dataUpdate?.targetPeriod
            ? helpers.getFilterTimeTo(dataUpdate?.targetPeriod[1])
            : null,
          paymentDate: helpers.getFilterTime(dataUpdate?.datePayment) ?? null,
          planLaborCost: dataUpdate?.amountPaid ?? 0,
          startDate: dataUpdate?.targetPeriod
            ? helpers.getFilterTime(dataUpdate?.targetPeriod[0])
            : null,
          evidence: dataUpdate.evidence?.file?.name || (dataUpdate.evidence && dataUpdate.evidence[0] && dataUpdate.evidence[0].name ? dataUpdate.evidence[0].name : '') || '',
          note: record?.note ?? "",
          ...(dataUpdate.evidence?.file?.originFileObj && { fileEvidence: dataUpdate.evidence })
        };
        if (
          record?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
        ) {
          dispatch(setDocumentEdit(payload));
          return;
        }

        if (dataUpdate.evidence?.file?.originFileObj) {
          try {
            const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
              ?.company?.id;
            const linkPreSignUpload = await getLinkPresignUpload({
              keyName: `re/${companyId}/${SCREEN_NAME}/${Number(key)}/${dataUpdate.evidence?.file?.name
                }`,
            });
            await uploadTos3(
              linkPreSignUpload,
              dataUpdate.evidence.file?.originFileObj
            );

            payload.evidence = dataUpdate.evidence?.file?.name ||
              (dataUpdate.evidence && dataUpdate.evidence[0] && dataUpdate.evidence[0].name ? dataUpdate.evidence[0].name : '') || '';
          } catch (error) {
            payload.evidence = '';
            ErrorNotification(NOTIFICATION_TITLE.ERROR);
          }
        }

        const response = await service.putMoneyDirectCostLaborUpdate(payload);

        if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
          setRefresh(!refresh);
          const newEditKey = editingKeys.filter((keyEdit) => keyEdit !== key);
          setEditingKeys(newEditKey);
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        } else {
          throw new Error(NOTIFICATION_TITLE.ERROR);
        }
      }

    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const updateOnNoteModal = async (payload: any) => {
    if (payload.fileEvidence?.file?.originFileObj) {
      try {
        const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
          ?.company?.id;
        const linkPreSignUpload = await getLinkPresignUpload({
          keyName: `re/${companyId}/${SCREEN_NAME}/${Number(payload.id)}/${payload.fileEvidence?.file?.name
            }`,
        });
        await uploadTos3(
          linkPreSignUpload,
          payload.fileEvidence.file?.originFileObj
        );
        payload.evidence = payload.fileEvidence?.file?.name || '';
      } catch (error) {
        payload.evidence = '';
        ErrorNotification(NOTIFICATION_TITLE.ERROR);
      }
    }
    delete payload.fileEvidence;

    const response = await service.putMoneyDirectCostLaborUpdate(payload);

    if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
      setRefresh(!refresh);
      const newEditKey = editingKeys.filter(
        (keyEdit) => keyEdit + "" !== payload.id + ""
      );
      setEditingKeys(newEditKey);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } else {
      throw new Error(NOTIFICATION_TITLE.ERROR);
    }

  };

  const viewApproveModal = (record: any) => {
    const payload = {
      id: Number(record.key),
      actualCost: record.actualCost,
      endDate: record.endDate, // blank case
      startDate: record.startDate, // blank case
      paymentDate: record.paymentDate,
      planLaborCost: record.planLaborCost,
      evidence: record.evidence,
      note: record.note,
    };
    dispatch(
      setDocumentView({
        ...payload,
        status: record.status,
      })
    );
  };

  const cancelEditing = (record: any) => {
    const valueEdit = form.isFieldTouched(`record${record.key}`);
    if (!String(record.id).includes("new")) {
      const evidence = form.getFieldValue([`record${record.key}`, "evidence"]);
      if (valueEdit || (!evidence && record.evidence)) {
        ConfirmModal({
          onOk: () => {
            const filteredKeys = editingKeys.filter(
              (element: any) => element !== record.key
            );
            setEditingKeys(filteredKeys);
            const dataCurrent = data.map((element) => {
              if (element.key === record.key)
                return {
                  ...originalData?.find((item) => item.key === record.key),
                };

              return element;
            });
            setData(dataCurrent);
          },
          onCancel: () => { },
          title: MESSAGE.MESSAGE_020,
          description: MESSAGE.MESSAGE_022_1,
          extraDescription: MESSAGE.MESSAGE_022_2,
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
        });
      } else {
        const filteredKeys = editingKeys.filter(
          (element: any) => element !== record.key
        );
        setEditingKeys(filteredKeys);
      }
    } else {
      if (valueEdit) {
        ConfirmModal({
          onOk: () => {
            const resultsLine = newLines.filter(
              (element: any) => element.key !== record.key
            );
            setNewLines(resultsLine);
            const filteredKeys = editingKeys.filter(
              (element: any) => element !== record.key
            );
            setEditingKeys(filteredKeys);
          },
          onCancel: () => { },
          title: MESSAGE.MESSAGE_020,
          description: MESSAGE.MESSAGE_022_1,
          extraDescription: MESSAGE.MESSAGE_022_2,
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
        });
      } else {
        const resultsLine = newLines.filter(
          (element: any) => element.key !== record.key
        );
        setNewLines(resultsLine);
        const filteredKeys = editingKeys.filter(
          (element: any) => element !== record.key
        );
        setEditingKeys(filteredKeys);
      }
    }
  };

  const deleteRecord = async (value: any) => {
    ConfirmModalAsync({
      onOk: async () => {
        dispatch(setLoading(true));
        try {
          const response = await service.deleteMoneyDirectCostLaborDelete({
            id: value.id,
          });
          if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
            const newData = data.filter((element) => element.id !== value.id);
            setData(newData);
            SuccessNotification(
              response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
          }
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_7,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
      className: "confirm__modal",
    });
  };

  const handleViewEvidence = async (record: any) => {
    try {
      dispatch(setLoading(true));
      const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
        ?.company?.id;
      const response = await service.getPresignLinkS3({
        keyName: `re/${companyId}/${SCREEN_NAME}/${Number(record.id)}/${record.evidence
          }`,
      });
      setLinkEvidence(response.results);
      const checkFile = record.evidence?.split(".").pop();
      const evidenceName = record.evidence?.split(".").slice(0, -1).join(".");
      dispatch(setEvidenceName(evidenceName));
      if (checkFile === "pdf") {
        dispatch(showPdfViewerModal());
      } else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const resetEditingLines = () => {
    setNewLines([]);
    setEditingKeys([]);
  };

  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of data) {
        if (
          element?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
        )
          checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);
      dispatch(
        setCheckedCollapse({
          id: parentData.id,
          checked: checkedItems,
        })
      );
    } else {
      setCheckedList([]);
      dispatch(
        setCheckedCollapse({
          id: parentData.id,
          checked: [],
        })
      );
    }
  };
  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of data) {
      if (
        element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
      )
        possibleItems.push(element.id);
    }
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
    dispatch(
      setCheckedCollapse({
        id: parentData.id,
        checked: newCheckList,
      })
    );
  };
  const checkParentCBChange = (
    parentData: any,
    checkedCollapse: { id: number; checked: number[] }[]
  ) => {
    const isChecked = checkedCollapse.some(
      (element) => element.id === parentData.id
    );
    if (!isChecked) {
      setCheckAll(false);
      setCheckedList([]);
    }
  };

  return {
    form,
    editingKeys,
    data,
    refresh,
    newLines,
    linkEvidence,
    saveKeys,
    checkAll,
    checkedList,
    isEditing,
    edit,
    save,
    cancelEditing,
    addNewLine,
    onValueNumberChange,
    deleteRecord,
    getMoneyDirectCostLaborList,
    setFirstData,
    handleViewEvidence,
    checkEnableButtons,
    isEnableSaveButton,
    afterClearEvidence,
    resetEditingLines,
    onCheckAllChange,
    isCheckbox,
    onCheckboxChange,
    setParentData,
    checkParentCBChange,
    viewApproveModal,
    updateOnNoteModal,
  };
};

export default CollapsedTableHandler;
