import { Button, Form, Table, Tooltip } from "antd";
import { COLUMN } from "./ColumsTableCCUS";
import { CCUS061Handler } from "../handler/HandlerImpl";
import { MENU_ITEM, PARAMS } from "../../constants";
import EmptyData from "../../../../../assets/icons/empty-data.svg";
import PaginationComponent from "./Pagination";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import IconInfo from "../../../../../assets/icons/info-square-ccus.svg";

interface props {
  handler: CCUS061Handler;
}
const TableCCUS = ({ handler }: props) => {
  const { columnsTab1, columnsTab2, rowSelection } = COLUMN(handler);
  const [searchParams] = useSearchParams();
  const sortType = searchParams.get(PARAMS.sortType);

  const sortedData = useMemo(() => {
    if (!sortType) return handler.dataTableRelaseKey;
    return [...handler.dataTableRelaseKey].sort((a, b) => {
      if (sortType === "ASC") {
        return a.ccusId.localeCompare(b.ccusId);
      } else if (sortType === "DESC") {
        return b.ccusId.localeCompare(a.ccusId);
      }
      return 0;
    });
  }, [handler.dataTableRelaseKey, sortType]);
  return (
    <div className="w-100-percent h-100-percent">
      {handler.state === MENU_ITEM[0].code ? (
        handler.dataTableEmployee.length == 0 ? (
          <div className="empty-data-container">
            <img src={EmptyData} alt="" />
          </div>
        ) : (
          <div className="table-ccus061-container">
            <Table
              columns={columnsTab1}
              dataSource={handler.dataTableEmployee}
              pagination={false}
              className="table-ccus"
              scroll={{ y: "60vh", x: "max-content" }}
            />
            <PaginationComponent
              onPageChange={handler.onPageChange}
              onSizeChange={handler.onSizeChange}
              total={handler.totalRecord}
            />
          </div>
        )
      ) : (
        <>
          {handler.dataTableRelaseKey.length == 0 ? (
            <div className="empty-data-container">
              <img src={EmptyData} alt="" />
            </div>
          ) : (
            <Form
              form={handler.formTab2}
              onFinish={handler.OnSubmitAuthentication}
            >
              <div className="header-infor-ccus062">
                <div className="left-header-ccus062">
                  <div className="title-left-content">※入力時の注意事項：</div>
                  <div>
                    <div className="text-content">
                      生年月日は入力必須、さらに下記①～③の項目のいずれか入力必須です。
                    </div>
                    <div className="text-content">
                      ①技能者姓、技能者名 &nbsp;
                      <Tooltip
                        title={
                          <div>
                            技能者姓、技能者名に入力可能な文字
                            <br />
                            &nbsp;漢字、ひらがな、半角数字、半角アルファベット大文字小文字、全角カタカナ
                            <br />
                            &nbsp;！”＃＄％＆’＊＋，−．／：；＜＝＞？＠［￥］＾＿‘｛｜｝￣以外の全角記号
                            <br />
                            &nbsp;、。以外の半角記号
                            <br />
                            ※国籍が日本以外の場合、以下の文字のみ入力可能となります。
                            <br />
                            &nbsp;半角英字大文字
                            <br />
                            &nbsp;,　. 半角スペース　-　'　(　)　/　
                          </div>
                        }
                      >
                        <img src={IconInfo} />
                      </Tooltip>
                    </div>
                    <div className="text-content">
                      ②技能者姓(カタカナ)、技能者名(カタカナ)&nbsp;
                      <Tooltip
                        title={
                          <div>
                            技能者姓(カタカナ)、技能者名(カタカナ)に入力可能な文字
                            <br />
                            &nbsp;半角英字小文字、半角英字大文字、半角数字、全角カタカナ　
                            <br />
                            &nbsp;半角記号：`　~　!　@　#　$　%　^　&　*　(　)　_　+　[　]　\
                            <br />
                            &nbsp;
                            {
                              ';　\'　,　.　/　{　}　|　:　""　<　>　?　=　-　 '
                            }{" "}
                            半角スペース
                          </div>
                        }
                      >
                        <img src={IconInfo} />
                      </Tooltip>
                    </div>
                    <div className="text-content">
                      ③本人確認番号&nbsp;
                      <Tooltip
                        title={<div>4桁固定の半角英数字のみ入力可能</div>}
                      >
                        <img src={IconInfo} />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="btn-container-ccus062">
                  <Button
                    htmlType="submit"
                    size="large"
                    className="button-purple btn-submit-ccus062 cm047-btn-submit cm024-btn-submit"
                    type="primary"
                    disabled={
                      handler.funcFilterKeySuccess(
                        handler.selectedRowsKey,
                        handler.dataTableRelaseKey
                      )?.length === 0
                    }
                  >
                    認証実行
                  </Button>
                </div>
              </div>

              <Table
                columns={columnsTab2}
                dataSource={sortedData}
                rowSelection={{
                  ...rowSelection,
                }}
                pagination={false}
                rowClassName="row-table-ccus row-table-ccus062"
                className="table-ccus table-ccus062"
                scroll={{ y: "58vh", x: "max-content" }}
              />
            </Form>
          )}
        </>
      )}
    </div>
  );
};

export default TableCCUS;
