import { Form, FormInstance, message } from "antd";
import moment from "moment";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { CR003UseCase } from "../../usecase/ServiceImpl";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import COMMON, { FILE_NAME } from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";
import {
  setChangeChosenCompany,
  setTypeModal,
  setViewChooseCompany,
} from "../../../CR002/presenter/slice/Slice";
import { RootState } from "../../../../../store";
import { useSearchParams, useParams } from "react-router-dom";
import {
  setListCompany,
  setViewModalCR001,
} from "../../../CR001-mail-contractors/presenter/slice/Slice";
import { exportCR } from "../../../../../common/helpers/exports/cr_ci/construction_budget";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";
import WarningModalCR001 from "../../../../../common/components/modal/WarningModalCR001";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
import { setVersionIdCR005 } from "../../../CR005/presenter/slice/Slice";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import { RcFile } from "antd/es/upload";
import { setFileName } from "../slice/Slice";
import { PARAMS } from "../CONSTANT";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";

const DROP_1 = "construction_dropdown1";
const DROP_2 = "construction_dropdown2";
const DROP_3 = "construction_dropdown3";

const MODAL_WARNING_TITLE = "外注業者の招待について";
const MODAL_WARNING_DESCRIPTION = [
  "実行予算書のステータスが「承認済」であっても「工期」を入力していない外注業者は招待することができません。",
  "このまま保存してもよろしいでしょうか？",
];

const DEFAULT_MATERIAL_COST = {
  key: Math.random(),
  id: 0,
  traderName: "",
  materialName: "",
  contractTime: "",
  endTime: "",
  startTime: "",
  estimateMoney: null,
  costMoney: null,
  budgetMoney: null,
  orderMoney: null,
  document: "",
};

const DEFAULT_SUBCONTRACT_COST = {
  id: 0,
  key: Math.random(),
  company: {
    name: "",
  },
  contractTime: "",
  endTime: "",
  startTime: "",
  constructionName: "",
  estimateMoney: null,
  costMoney: null,
  budgetMoney: null,
  orderMoney: null,
  isInvited: false,
};

const DEFAULT_LABOR_COST = {
  key: Math.random(),
  id: 0,
  manager: "",
  estimateMoney: null,
  costMoney: null,
  budgetMoney: null,
};

const DEFAULT_INDIRECT = {
  key: Math.random(),
  id: 0,
  item: "",
  estimateMoney: null,
  costMoney: null,
  budgetMoney: null,
};
export const TYPE_USER = {
  owner_submit: "owner_submit",
  owner_not_submit: "owner_not_submit",
  approve: "approve",
  view: "view",
  user_create: "user_create",
  manager_approve: "manager_approve",
};

const PARAM_TAB = {
  current: "1",
  name: "tab",
};

export const TAB_VERSION = {
  versionChange: {
    key: "1",
    value: "versionChange",
  },
  versionApprove: {
    key: "2",
    value: "versionApprove",
  },
};

const handleCalculator = (numerator: number, denominator: number) => {
  if (numerator == null || numerator == undefined || !denominator || denominator === 0) return null;
  return Math.round((numerator / denominator) * 10000) / 100;
};

interface ResponseUpdate {
  data: any;
  documentId: number;
  note: string;
}
export const MESSAGE_DUPLICATE = [
  "工事名は既存されました。",
  "他の工事名を記入してください。",
];

const CR003Handler = (cr003Service: CR003UseCase) => {
  const dispatch = useDispatch();
  const { userId, projectManagerId, summaryId } = useParams();
  const [form] = Form.useForm();
  const [formAmount] = Form.useForm();
  const [formReject] = Form.useForm();
  const [basicInfo, setBasicInfo] = useState<any>({});
  const [inquiryCategory, setInquiryCategory] = useState<string>("");
  const [decisionMethod, setDecisionMethod] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState<string[]>([]);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const [screenMaster, setScreenMaster] = useState<any>({});
  const [isChangeEdit, setIsChangeEdit] = useState<boolean>(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [materialCostList, setMaterialCostList] = useState<any[]>([
    DEFAULT_MATERIAL_COST,
  ]);
  const [subcontractCostList, setSubcontractCostList] = useState<any[]>([
    DEFAULT_SUBCONTRACT_COST,
  ]);
  const [laborCostList, setLaborCostList] = useState<any[]>([
    DEFAULT_LABOR_COST,
  ]);
  const [listIndirect, setListIndirect] = useState<any[]>([DEFAULT_INDIRECT]);
  const [isViewModalExternal, setIsViewModalExternal] =
    useState<boolean>(false);
  const [listRelateMoney, setListRelateMoney] = useState<any>({});
  const [estimateContractMoney, setEstimateContractMoney] = useState<any>({});
  const [isRefreshAmount, setIsRefreshAmount] = useState<boolean>(false);
  const [companyChange, setCompanyChange] = useState("");

  //handle note and version
  const [isViewModalHistory, setIsViewModalHistory] = useState<boolean>(false);
  const [detailHistoryNote, setDetailHistoryNote] = useState<string>("");
  const [isEditNote, setIsEditNote] = useState<boolean>(false);
  const [listVersion, setListVersion] = useState<any[]>([]);
  const [listVersionApproval, setListVersionApproval] = useState<any[]>([]);

  const [versionNew, setVersionNew] = useState<any>({});
  const [loadingNote, setLoadingNote] = useState<boolean>(false);
  const [isVersionNow, setIsVersionNow] = useState<boolean>(true);
  const [listApprovalProcess, setListApprovalProcess] = useState<any[]>([]);
  const [currentUserApproval, setCurrentUserApproval] = useState<any>({});
  const [isApprove, setIsApprove] = useState(false);
  const [isApproveOrReject, setIsApproveOrReject] = useState(true);
  const [noteApprove, setNoteApprove] = useState<string>("");
  const [refreshApprove, setRefreshApprove] = useState(false);
  const [dataRelate, setDataRelate] = useState<any>([]);
  const [errorAmount, setErrorAmount] = useState(false);
  const [outsourcingFee, setOutsourcingFee] = useState({
    outsourceEstimateCost: 0,
    outsourceTotalCost: 0,
    outsourceBudgetCost: 0,
  });
  const [currentOption, setCurrentOption] = useState([]);
  const [members, setMembers] = useState<any>([]);
  const [pageMember, setPageMember] = useState(1);
  const [keywordMember, setKeywordMember] = useState("");
  const [requiredMaterial, setRequiredMaterial] = useState<any[]>([]);
  const [listApprover, setListApprover] = useState<any[]>([]);
  const [tabVersion, setTabVersion] = useState(TAB_VERSION.versionChange.key);
  const [fileList, setFileList] = useState<any[]>([]);
  const [fileListAmountRelate, setFileListAmountRelate] = useState<any[]>([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfUrlAmountRelate, setPdfUrlAmountRelate] = useState("");
  const [descriptionPopup, setDescriptionPopup] = useState("");
  const [titlePopup, setTitlePopup] = useState("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const chosenCompany = useSelector(
    (state: RootState) => state.cr002.chosenCompany
  );
  const listCompany = useSelector(
    (state: RootState) => state.cr002.listCompany
  );
  const type = useSelector((state: RootState) => state.cr002.type);
  const isRoleAdmin = [SYSTEM_ROLES.ADMIN_SYSTEM, SYSTEM_ROLES.ADMIN].includes(
    useSelector((state: RootState) => state.auth.authData?.role?.code) ?? ""
  );
  //params
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get(PARAM_TAB.name) || PARAM_TAB.current;
  const [detailProject, setDetailProject] = useState<any>({});

  const resetScreen = () => {
    setIsEdit(false);
  };

  const dropDownBasicInfo = useMemo(() => {
    if (Object.keys(screenMaster)?.length === 0)
      return {
        dropDown1: [],
        dropDown2: [],
        dropDown3: [],
      };
    const results1 = screenMaster?.categorys?.filter(
      (category: any) => category.type === DROP_1
    );
    const results2 = screenMaster?.categorys?.filter(
      (category: any) => category.type === DROP_2
    );
    const results3 = screenMaster?.categorys?.filter(
      (category: any) => category.type === DROP_3
    );

    return {
      dropDown1: results1,
      dropDown2: results2,
      dropDown3: results3,
    };
  }, [screenMaster]);

  const categoryValue = useMemo(() => {
    const category1 = basicInfo?.categories?.find((category: any) => {
      return dropDownBasicInfo.dropDown1?.some(
        (ele: any) => category.categoryId === ele.id
      );
    });
    const category1Data = dropDownBasicInfo.dropDown1?.find((category: any) => {
      return basicInfo?.categories?.some(
        (ele: any) => ele.categoryId === category.id
      );
    });
    const category2 = basicInfo?.categories?.find((category: any) => {
      return dropDownBasicInfo.dropDown2?.some(
        (ele: any) => category.categoryId === ele.id
      );
    });
    const category2Data = dropDownBasicInfo.dropDown2?.find((category: any) => {
      return basicInfo?.categories?.some(
        (ele: any) => ele.categoryId === category.id
      );
    });
    const category3 = basicInfo?.categories?.find((category: any) => {
      return dropDownBasicInfo.dropDown3?.some(
        (ele: any) => category.categoryId === ele.id
      );
    });
    const category3Data = dropDownBasicInfo.dropDown3?.find((category: any) => {
      return basicInfo?.categories?.some(
        (ele: any) => ele.categoryId === category.id
      );
    });

    return {
      category1: { ...category1, ...category1Data },
      category2: { ...category2, ...category2Data },
      category3: { ...category3, ...category3Data },
    };
  }, [basicInfo, dropDownBasicInfo]);

  useEffect(() => {
    const results = { ...basicInfo };
    if (
      basicInfo.constructionSummaryFromDate &&
      basicInfo.constructionSummaryToDate
    ) {
      results.constructionSummaryDate = [
        moment(basicInfo.constructionSummaryFromDate),
        moment(basicInfo.constructionSummaryToDate),
      ];
    } else {
      results.constructionSummaryDate = ["", ""];
    }

    if (basicInfo.startDate && basicInfo.endDate) {
      results.startEndDate = [
        moment(basicInfo.startDate),
        moment(basicInfo.endDate),
      ];
    } else {
      results.startEndDate = "";
    }
    if (basicInfo.contractCreateDate) {
      results.contractCreateDate = moment(basicInfo.contractCreateDate);
    } else {
      results.contractCreateDate = "";
    }
    results.requireDirect =
      basicInfo.requireDirect == null
        ? ""
        : basicInfo.requireDirect
          ? "yes"
          : "no";
    results.requireItem =
      basicInfo.requireItem == null ? "" : basicInfo.requireItem ? "yes" : "no";
    results.requireEstimation =
      basicInfo.requireEstimation == null
        ? ""
        : basicInfo.requireEstimation
          ? "yes"
          : "no";

    const category1 = basicInfo?.categories?.find((category: any) => {
      return dropDownBasicInfo.dropDown1?.some(
        (ele: any) => category.categoryId === ele.id
      );
    });
    const category2 = basicInfo?.categories?.find((category: any) => {
      return dropDownBasicInfo.dropDown2?.some(
        (ele: any) => category.categoryId === ele.id
      );
    });
    const category3 = basicInfo?.categories?.find((category: any) => {
      return dropDownBasicInfo.dropDown3?.some(
        (ele: any) => category.categoryId === ele.id
      );
    });

    if (category1) {
      results.category1 = category1.categoryId;
    }
    if (category2) {
      results.category2 = category2.categoryId;
    }
    if (category3) {
      results.category3 = category3.categoryId;
    }

    form.setFieldsValue(results);
  }, [basicInfo, screenMaster]);
  const getCR003BasicInfo = async (params: any) => {
    dispatch(setLoading(true));
    try {
      const response = await cr003Service.getCR003BasicInfo(params);
      setBasicInfo(response.data.results);
      if (response.data.results?.documentCr?.length != 0) {

        const result = response.data.results?.documentCr.map((item: any) => {
          return {
            key: `${Math.random()}`,
            uid: `${Math.random()}`,
            name: item,
            path: `construction/${summaryId}/cr/${item}`,
          }
        })
        setFileList(result);
      } else {
        setFileList([]);
      }

    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setBasicInfo({});
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getScreenMaster = async (params: any) => {
    dispatch(setLoading(true));
    try {
      const response = await cr003Service.getScreenMaster(params);
      setScreenMaster(response.data.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const updateCR003BasicInfo = async (data: any) => {
    dispatch(setLoading(true));
    try {
      const response = await cr003Service.updateCR003BasicInfo(data);
      const resultDocument = fileList.filter((element: any) => element.isAddNew)
      if (resultDocument.length != 0) {
        try {
          handleUploadFileBasicInfo(resultDocument)
          dispatch(setLoading(true));
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
        finally {
          dispatch(setLoading(false));
        }
      }
      setIsRefresh(!isRefresh);
      setIsEdit(false);
      setIsChangeEdit(false);
      SuccessNotification(
        response?.data?.message
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setBasicInfo({});
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getApprovalProcess = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await cr003Service.getApprovalProcess(params);
      const listApproverResult = response?.data?.results.listApprover ?? [];

      const submitter = {
        ...response?.data?.results.submitter,
        isSubmitter: true
      }
      if (listApproverResult.length != 0) {
        if (response?.data?.results.submitter) {
          setListApprovalProcess([submitter, ...listApproverResult]);
          setIsSubmitted(true)
        }
        else {
          setListApprovalProcess(listApproverResult);
          setIsSubmitted(false)
        }
      } else setListApprovalProcess([]);
      if (response?.data?.results.submitter !== null) {
        setIsSubmitted(true)
      } else setIsSubmitted(false)
      setCurrentUserApproval(response.data?.results?.currentUserApproval);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setCurrentUserApproval({});
      setListApprovalProcess([]);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const postApprovalProcess = async (data?: any) => {
    try {
      dispatch(setLoading(true));
      const response = await cr003Service.postApprovalProcess(data);
      formReject.resetFields();
      setRefreshApprove(!refreshApprove);
      SuccessNotification(
        response?.data?.message
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const resetApproval = async (data: any) => {
    try {
      dispatch(setLoading(true));
      const response = await cr003Service.resetApproval(data);
      setRefreshApprove(!refreshApprove);
      SuccessNotification(
        response?.data?.message
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onChangeInquiryCategorySelect = (value: string) => {
    setInquiryCategory(value);
  };

  const onChangeDecisionMethod = (value: string) => {
    setDecisionMethod(value);
  };

  const onClickCheckEdit = (value: boolean) => {
    setIsEdit(value);
    if (value) {
      if (materialCostList?.length === 0) {
        setMaterialCostList([DEFAULT_MATERIAL_COST]);
      }
      if (subcontractCostList?.length === 0) {
        setSubcontractCostList([DEFAULT_SUBCONTRACT_COST]);
      }
      if (laborCostList?.length === 0) {
        setLaborCostList([DEFAULT_LABOR_COST]);
      }
      if (listIndirect?.length === 0) {
        setListIndirect([DEFAULT_INDIRECT]);
      }
    }
  };

  const onChangeCurrentTab = (value: string) => {
    if (isEdit && isChangeEdit) {
      ConfirmModal({
        onOk: () => {
          searchParams.set(PARAM_TAB.name, value);
          setSearchParams(searchParams);
          setIsEdit(false);
          setIsChangeEdit(false);
        },
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      searchParams.set(PARAM_TAB.name, value);
      setSearchParams(searchParams);
      setIsEdit(false);
    }
  };

  const onSubmitBasic = (value: any) => {
    const results = { ...basicInfo };
    results.estimationCode = value?.estimationCode || "";
    results.constructionCode = value?.constructionCode || "";
    results.description = value.description || "";
    results.constructionName = value.constructionName || "";
    results.departmentIc = value.departmentIc || "";
    results.picMainName = value.picMainName || "";
    results.picSubName = value.picSubName || "";
    results.picBusinessName = value.picBusinessName || "";
    results.rootOrderName = value.rootOrderName || "";
    results.picCustomerName = value.picCustomerName || "";
    results.location = value.location || "";
    results.phoneNumber = value.phoneNumber || "";
    results.fax = value.fax || "";
    results.constructionSummaryFromDate = value.constructionSummaryDate
      ? value.constructionSummaryDate[0]?._d
      : // value.constructionSummaryDate ||
      null;
    results.constructionSummaryToDate = value.constructionSummaryDate
      ? value.constructionSummaryDate[1]?._d
      : // value.constructionSummaryDate ||
      null;
    results.endDate = value.startEndDate ? value.startEndDate[1]?._d : null;
    results.startDate = value.startEndDate ? value.startEndDate[0]?._d : null;
    results.requireEstimation =
      value.requireEstimation === "yes"
        ? true
        : value.requireEstimation === "no"
          ? false
          : null;
    results.requireDirect =
      value.requireDirect === "yes"
        ? true
        : value.requireDirect === "no"
          ? false
          : null;
    results.requireItem =
      value.requireItem === "yes"
        ? true
        : value.requireItem === "no"
          ? false
          : null;
    results.contractCreateDate = value.contractCreateDate?._d || "";
    results.conditionPayment = value.conditionPayment || "";

    results.areaFloor = value.areaFloor === "" || value.areaFloor === null || value.areaFloor === undefined
      ? null
      : Number(value.areaFloor);

    results.costArea = value.costArea === "" || value.costArea === null || value.costArea === undefined
      ? null
      : Number(value.costArea);

    results.estimateArea = value.estimateArea === "" || value.estimateArea === null || value.estimateArea === undefined
      ? null
      : Number(value.estimateArea);

    results.categories = [];
    results.subCategories = [];

    const category1 = screenMaster?.categorys?.find(
      (ele: any) => ele.id === value.category1
    );
    const category2 = screenMaster?.categorys?.find(
      (ele: any) => ele.id === value.category2
    );
    const category3 = screenMaster?.categorys?.find(
      (ele: any) => ele.id === value.category3
    );

    if (category1) {
      results.categories.push({
        categoryId: category1.id,
        note: "",
      });
    }
    if (category2) {
      results.categories.push({
        categoryId: category2.id,
        note: "",
      });
    }
    if (category3) {
      results.categories.push({
        categoryId: category3.id,
        note: "",
      });
    }
    results.documentCr = fileList.map((item: any) => item.name)
    updateCR003BasicInfo(results);
  };

  const onCancelSubmitFormBasic = () => {
    if (isEdit && isChangeEdit) {
      ConfirmModal({
        onOk: () => {
          setIsEdit(false);
          setIsChangeEdit(false);
          setIsRefresh(!isRefresh);
        },
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      setIsEdit(false);
    }
  };

  const onChangeCheckBox = (value: any) => {
    setCheckbox(value);
  };

  const handleFormatPhone = (e: any, key: string) => {
    const phone = e.target.value;
    let results = "";
    let checkPhone = 0;
    if (phone?.length > 0) {
      const phoneList = phone.split("").filter((item: string) => item !== "-");
      if (phoneList.length <= 10) {
        phoneList.forEach((item: string, index: number) => {
          if (index === 5) {
            checkPhone = index;
          }
          if (index <= 2 || index === 4 || index === 5) {
            results = `${results}${item}`;
          } else if (index === 3) {
            results = `${results}-${item}`;
          } else {
            if (index === checkPhone + 1) {
              results = `${results}-${item}`;
            } else {
              results = `${results}${item}`;
            }
            if (index === checkPhone + 4) {
              checkPhone = index;
            }
          }
        });
      } else if (phoneList.length === 11) {
        phoneList.splice(3, 0, "-");
        phoneList.splice(8, 0, "-");
        results = phoneList.join("");
      } else {
        phoneList.forEach((item: string, index: number) => {
          if (index === 3) {
            checkPhone = index;
          }
          if (index <= 3) {
            results = `${results}${item}`;
          } else {
            if (index === checkPhone + 1) {
              results = `${results}-${item}`;
            } else {
              results = `${results}${item}`;
            }
            if (index === checkPhone + 4) {
              checkPhone = index;
            }
          }
        });
      }
    }
    form.setFieldsValue({ [key]: results });
  };

  const onChangeForm = () => setIsChangeEdit(true);

  const handleAddMaterialCost = () => {
    setIsChangeEdit(true);
    const results = [
      ...materialCostList,
      { ...DEFAULT_MATERIAL_COST, key: Math.random() },
    ];

    setMaterialCostList(results);
  };

  const handleDeleteMaterialCost = (keyItem: number) => {
    ConfirmModal({
      onOk: async () => {
        setIsChangeEdit(true);
        const results = materialCostList.filter(
          (element: any) => element.key !== keyItem
        );
        setMaterialCostList(results);
      },
      onCancel: () => { },
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_7,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
      className: "confirm__modal",
    });
  };

  const handleAddSubcontractCost = () => {
    setIsChangeEdit(true);
    setSubcontractCostList([
      ...subcontractCostList,
      { ...DEFAULT_SUBCONTRACT_COST, key: Math.random() },
    ]);
  };
  const handleDeleteSubcontractCost = (keyItem: number) => {
    ConfirmModal({
      onOk: async () => {
        setIsChangeEdit(true);
        const results = subcontractCostList.filter(
          (element) => element.key !== keyItem
        );
        setSubcontractCostList(results);
      },
      onCancel: () => { },
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_7,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
      className: "confirm__modal",
    });
  };

  const handleAddLaborCost = () => {
    setIsChangeEdit(true);
    setLaborCostList([
      ...laborCostList,
      { ...DEFAULT_LABOR_COST, key: Math.random() },
    ]);
  };

  const handleDeleteLaborCost = (keyItem: number) => {
    ConfirmModal({
      onOk: async () => {
        setIsChangeEdit(true);
        const results = laborCostList.filter(
          (element: any) => keyItem !== element.key
        );
        setLaborCostList(results);
      },
      onCancel: () => { },
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_7,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
      className: "confirm__modal",
    });
  };

  const handleAddListIndirect = () => {
    setIsChangeEdit(true);
    setListIndirect([
      ...listIndirect,
      { ...DEFAULT_INDIRECT, key: Math.random() },
    ]);
  };

  const handleDeleteListIndirect = (keyItem: number) => {
    ConfirmModal({
      onOk: async () => {
        setIsChangeEdit(true);
        const results = listIndirect.filter(
          (element: any) => element.key !== keyItem
        );
        setListIndirect(results);
      },
      onCancel: () => { },
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_7,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
      className: "confirm__modal",
    });
  };

  // Amount Relate

  const getListRelateMoney = async (params: any) => {
    dispatch(setLoading(true));
    try {
      const response = await cr003Service.getListRelateMoney({
        constructionId: params.constructionId,
      });
      const resultsData = response?.data?.results;
      if (!resultsData) return;
      setListRelateMoney(resultsData);
      handleDataRelateMoney(resultsData);
      setOutsourcingFee({
        outsourceEstimateCost: resultsData.outsourceEstimateCost,
        outsourceTotalCost: resultsData.outsourceTotalCost,
        outsourceBudgetCost: resultsData.outsourceBudgetCost,
      });
      await Promise.all([
        getListVersion({
          documentId: resultsData?.documentId,
          versionId: params.versionId,
        }),
        getListVersionApproval({
          documentId: resultsData?.documentId,
          versionId: params.versionId,
        }),
        getVersionData({
          documentPaymentConfirmDetailId: resultsData?.id,
        }),
      ]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDataRelateMoney = (raw: any) => {
    let contractMoney = 0;
    if (raw?.estimateContract?.length > 0) {
      setEstimateContractMoney(raw?.estimateContract[0]);
      contractMoney = raw?.estimateContract[0].contractMoney;
    } else {
      setEstimateContractMoney([]);
    }

    if (raw?.materialRegisterCost) {
      const valueMater = raw?.materialRegisterCost?.map((element: any) => {
        const obj = {
          ...element,
          key: element.id,
          budgetMoneyPercent: handleCalculator(
            element.budgetMoney,
            contractMoney
          ),
          orderMoneyPercent: handleCalculator(
            element.orderMoney,
            contractMoney
          ),
        };
        return obj;
      });
      setMaterialCostList(valueMater);
    } else {
      setMaterialCostList([]);
    }

    if (raw?.outsourceRegisterCost) {
      const valueOutSource = raw?.outsourceRegisterCost?.map((element: any) => {
        const obj = {
          ...element,
          key: element.id,
          budgetMoneyPercent: handleCalculator(
            element.budgetMoney,
            contractMoney
          ),
          orderMoneyPercent: handleCalculator(
            element.orderMoney,
            contractMoney
          ),
        };
        return obj;
      });
      setSubcontractCostList(valueOutSource);
    } else {
      setSubcontractCostList([]);
    }

    if (raw?.laborRegisterCost.length) {
      const listMember: any = [];
      const valueLabor = raw?.laborRegisterCost?.map((element: any) => {
        const obj = {
          ...element,
          managerId: element.manager?.id,
          key: element.id,
          budgetMoneyPercent: handleCalculator(
            element.budgetMoney,
            contractMoney
          ),
          orderMoneyPercent: handleCalculator(
            element.orderMoney,
            contractMoney
          ),
        };
        if (element.manager) listMember.push(element.manager);
        return obj;
      });
      setLaborCostList(valueLabor);
      setCurrentOption(listMember);
      setMembers(listMember);
    } else {
      setLaborCostList([]);
    }

    if (raw?.indirectConstructCost) {
      const valueIndirect = raw?.indirectConstructCost?.map((element: any) => {
        const obj = {
          ...element,
          key: element.id,
          budgetMoneyPercent: handleCalculator(
            element.budgetMoney,
            contractMoney
          ),
          orderMoneyPercent: handleCalculator(
            element.orderMoney,
            contractMoney
          ),
        };
        return obj;
      });
      setListIndirect(valueIndirect);
    } else {
      setListIndirect([]);
    }
  };

  //set value for form amount relate
  useEffect(() => {
    const results: any = {
      estimateContractMoney: {},
      outsourceEstimateCost: listRelateMoney.outsourceEstimateCost,
      outsourceTotalCost: listRelateMoney.outsourceTotalCost,
      outsourceBudgetCost: listRelateMoney.outsourceBudgetCost,
    };
    // estimate contract money

    if (estimateContractMoney.estimateMoney != null) {
      results.estimateContractMoney.estimateMoney =
        estimateContractMoney.estimateMoney;
    }

    if (estimateContractMoney.contractMoney != null) {
      results.estimateContractMoney = {
        ...results.estimateContractMoney,
        contractMoney: estimateContractMoney.contractMoney,
      };
    }

    if (estimateContractMoney.unitPersonCost != null) {
      results.estimateContractMoney = {
        ...results.estimateContractMoney,
        unitPersonCost: estimateContractMoney.unitPersonCost,
      };
    }

    if (estimateContractMoney.estimateLaborCost != null) {
      results.estimateContractMoney = {
        ...results.estimateContractMoney,
        estimateLaborCost: estimateContractMoney.estimateLaborCost,
      };
    }

    if (materialCostList?.length > 0) {
      materialCostList.forEach((materialCost: any) => {
        if (materialCost.traderName) {
          results[`materialCost${materialCost.key}`] = {
            traderName: materialCost.traderName,
          };
        }
        if (materialCost.materialName) {
          results[`materialCost${materialCost.key}`] = {
            ...results[`materialCost${materialCost.key}`],
            materialName: materialCost.materialName,
          };
        }

        if (materialCost.estimateMoney != null) {
          results[`materialCost${materialCost.key}`] = {
            ...results[`materialCost${materialCost.key}`],
            estimateMoney: materialCost.estimateMoney,
          };
        }

        if (materialCost.costMoney != null) {
          results[`materialCost${materialCost.key}`] = {
            ...results[`materialCost${materialCost.key}`],
            costMoney: materialCost.costMoney,
          };
        }

        if (materialCost.budgetMoney != null) {
          results[`materialCost${materialCost.key}`] = {
            ...results[`materialCost${materialCost.key}`],
            budgetMoney: materialCost.budgetMoney,
          };
        }

        if (materialCost.orderMoney != null) {
          results[`materialCost${materialCost.key}`] = {
            ...results[`materialCost${materialCost.key}`],
            orderMoney: materialCost.orderMoney,
          };
        }
      });
    }

    if (subcontractCostList?.length > 0) {
      subcontractCostList.forEach((outsourceRegisterCost: any) => {
        if (outsourceRegisterCost.company) {
          results[`outsourceRegisterCost${outsourceRegisterCost.key}`] = {
            company: outsourceRegisterCost.company,
          };
        }
        if (outsourceRegisterCost.constructionName) {
          results[`outsourceRegisterCost${outsourceRegisterCost.key}`] = {
            constructionName: outsourceRegisterCost.constructionName,
          };
        }
        if (outsourceRegisterCost.contractTime) {
          results[`outsourceRegisterCost${outsourceRegisterCost.key}`] = {
            ...results[`outsourceRegisterCost${outsourceRegisterCost.key}`],
            contractTime: moment(outsourceRegisterCost.contractTime),
          };
        } else {
          results[`outsourceRegisterCost${outsourceRegisterCost.key}`] = {
            ...results[`outsourceRegisterCost${outsourceRegisterCost.key}`],
            contractTime: "",
          };
        }
        if (outsourceRegisterCost.startTime && outsourceRegisterCost.endTime) {
          results[`outsourceRegisterCost${outsourceRegisterCost.key}`] = {
            ...results[`outsourceRegisterCost${outsourceRegisterCost.key}`],
            timeSpace: [
              moment(outsourceRegisterCost.startTime),
              moment(outsourceRegisterCost.endTime),
            ],
          };
        } else {
          results[`outsourceRegisterCost${outsourceRegisterCost.key}`] = {
            ...results[`outsourceRegisterCost${outsourceRegisterCost.key}`],
            timeSpace: ["", ""],
          };
        }

        if (outsourceRegisterCost.estimateMoney != null) {
          results[`outsourceRegisterCost${outsourceRegisterCost.key}`] = {
            ...results[`outsourceRegisterCost${outsourceRegisterCost.key}`],
            estimateMoney: outsourceRegisterCost.estimateMoney,
          };
        }

        if (outsourceRegisterCost.costMoney != null) {
          results[`outsourceRegisterCost${outsourceRegisterCost.key}`] = {
            ...results[`outsourceRegisterCost${outsourceRegisterCost.key}`],
            costMoney: outsourceRegisterCost.costMoney,
          };
        }

        if (outsourceRegisterCost.budgetMoney != null) {
          results[`outsourceRegisterCost${outsourceRegisterCost.key}`] = {
            ...results[`outsourceRegisterCost${outsourceRegisterCost.key}`],
            budgetMoney: outsourceRegisterCost.budgetMoney,
          };
        }

        if (outsourceRegisterCost.orderMoney != null) {
          results[`outsourceRegisterCost${outsourceRegisterCost.key}`] = {
            ...results[`outsourceRegisterCost${outsourceRegisterCost.key}`],
            orderMoney: outsourceRegisterCost.orderMoney,
          };
        }
      });
    }

    if (laborCostList?.length > 0) {
      laborCostList.forEach((laborCost: any) => {
        if (laborCost.manager) {
          results[`laborCost${laborCost.key}`] = {
            managerId: laborCost.manager?.id,
          };
        }

        if (laborCost.estimateMoney != null) {
          results[`laborCost${laborCost.key}`] = {
            ...results[`laborCost${laborCost.key}`],
            estimateMoney: laborCost.estimateMoney,
          };
        }

        if (laborCost.costMoney != null) {
          results[`laborCost${laborCost.key}`] = {
            ...results[`laborCost${laborCost.key}`],
            costMoney: laborCost.costMoney,
          };
        }

        if (laborCost.budgetMoney != null) {
          results[`laborCost${laborCost.key}`] = {
            ...results[`laborCost${laborCost.key}`],
            budgetMoney: laborCost.budgetMoney,
          };
        }
      });
    }

    if (listIndirect?.length > 0) {
      listIndirect.forEach((indirectCost: any) => {
        if (indirectCost.item) {
          results[`indirectCost${indirectCost.key}`] = {
            item: indirectCost.item,
          };
        }
        if (indirectCost.estimateMoney != null) {
          results[`indirectCost${indirectCost.key}`] = {
            ...results[`indirectCost${indirectCost.key}`],
            estimateMoney: indirectCost.estimateMoney,
          };
        }

        if (indirectCost.costMoney != null) {
          results[`indirectCost${indirectCost.key}`] = {
            ...results[`indirectCost${indirectCost.key}`],
            costMoney: indirectCost.costMoney,
          };
        }

        if (indirectCost.budgetMoney != null) {
          results[`indirectCost${indirectCost.key}`] = {
            ...results[`indirectCost${indirectCost.key}`],
            budgetMoney: indirectCost.budgetMoney,
          };
        }
      });
    }
    if (currentTab === "2") {
      formAmount.setFieldsValue(results);
    }
  }, [listRelateMoney]);

  const updateRelateMoney = async (data: any) => {
    dispatch(setLoading(true));
    try {
      const { responseUpdate, formValue } = data;
      const response = await cr003Service.updateRelateMoney(responseUpdate);
      const responseListRelateMoney = await cr003Service.getListRelateMoney({
        constructionId: summaryId,
      });
      const resultsListRelateMoney = responseListRelateMoney?.data?.results;

      const resultDocument = fileListAmountRelate.filter((element: any) => element.isAddNew)
      if (resultDocument.length != 0) {
        try {
          await handleUploadFileBasicInfo(resultDocument)
          dispatch(setLoading(true));
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
        finally {
          dispatch(setLoading(false));
        }
      }

      setIsViewModalHistory(false);
      setLoadingNote(false);
      setIsEditNote(false);
      setDetailHistoryNote("");
      await uploadAmountRelate({ resultsListRelateMoney, formValue })
      SuccessNotification(
        response?.data?.message
      );
      setIsEdit(false);
      setIsRefreshAmount(!isRefreshAmount);

    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      window.addEventListener("beforeunload", helpers.unloadCallback);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onCancelSubmitFormAmountRelate = () => {
    if (isEdit && isChangeEdit) {
      ConfirmModal({
        onOk: () => {
          setIsEdit(false);
          setIsChangeEdit(false);
          formAmount.resetFields();
          setIsRefreshAmount(!isRefreshAmount);
        },
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      setIsEdit(false);
      setIsRefreshAmount(!isRefreshAmount);
    }
  };

  const onClickBreadcrumb = (callback: () => void) => {
    if (isEdit && isChangeEdit) {
      ConfirmModal({
        onOk: () => {
          setIsEdit(false);
          setIsChangeEdit(false);
          setIsRefreshAmount(!isRefreshAmount);
          callback();
        },
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      callback();
      setIsEdit(false);
    }
  };

  const onClickMenu = (callback: (value: any) => void, value: any) => {
    if (isEdit && isChangeEdit) {
      ConfirmModal({
        onOk: () => {
          setIsEdit(false);
          setIsChangeEdit(false);
          setIsRefreshAmount(!isRefreshAmount);
          callback(value);
        },
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      callback(value);
      setIsEdit(false);
    }
  };

  const handleSubmitFormAmount = async (
    value: any,
    summaryId: string | undefined
  ) => {
    const formValue = value;
    const results: any = {
      outsourceEstimateCost: value.outsourceEstimateCost,
      outsourceTotalCost: value.outsourceTotalCost,
      outsourceBudgetCost: value.outsourceBudgetCost,
    };

    if (value.estimateContractMoney) {
      const valueEstimateContract = {
        ...value.estimateContractMoney,
        id: estimateContractMoney?.id || null,
      };
      results.estimateContractMoney = [valueEstimateContract];
    }

    const materialCost: any[] = [];
    const companyRequired: any[] = [];

    materialCostList.forEach((element) => {
      const valueMete = { ...element };

      if (element.materialCostCompany && element.materialCostCompany.id) {
        valueMete.materialCostCompanyId = element.materialCostCompany.id;
        valueMete.traderName = element.materialCostCompany.name;
      } else {
        valueMete.materialCostCompanyId = null;
        valueMete.traderName = "";
      }
      const found =
        valueMete.materialCostCompanyId ||
        valueMete.traderName ||
        valueMete.materialName ||
        valueMete.contractTime ||
        valueMete.endTime ||
        valueMete.startTime ||
        valueMete.document ||
        (valueMete.estimateMoney ?? valueMete.costMoney ?? valueMete.orderMoney ?? valueMete.budgetMoney)
      if (!valueMete.materialCostCompanyId) {
        companyRequired.push(element.key);
      }
      if (found !== null && found !== undefined) {
        materialCost.push(valueMete);
      }
    });

    // setRequiredMaterial(companyRequired);
    // if (companyRequired?.length > 0) return;
    const outsourceRegisterCost: any[] = [];
    subcontractCostList.forEach((element) => {
      const valueOutSource = { ...element };
      if (element?.company && element?.company?.name !== "") {
        valueOutSource.companyName = element?.company?.name;
        valueOutSource.companyColabId = element?.company?.id;
      } else {
        valueOutSource.companyName = "";
        valueOutSource.companyColabId = null;
      }

      const found =
        valueOutSource.companyName ||
        valueOutSource.constructionName ||
        valueOutSource.companyColabId ||
        valueOutSource.contractTime ||
        valueOutSource.endTime ||
        valueOutSource.startTime ||
        valueOutSource.document ||
        (valueOutSource.estimateMoney ?? valueOutSource.costMoney ?? valueOutSource.orderMoney ?? valueOutSource.budgetMoney)

      if (found !== null && found !== undefined) {
        outsourceRegisterCost.push(valueOutSource);
      }
    });

    const laborCost: any[] = [];
    laborCostList.forEach((element) => {
      const valueLabor = { ...element };
      delete valueLabor.manager;
      const found =
        valueLabor.managerId ||
        valueLabor.document ||
        (valueLabor.estimateMoney ?? valueLabor.costMoney ?? valueLabor.budgetMoney)

      if (found !== null && found !== undefined) {
        laborCost.push(valueLabor);
      }
    });

    const indirectCost: any[] = [];
    listIndirect.forEach((element) => {
      const valueIndirectCost = { ...element };
      const found =
        valueIndirectCost.item ||
        valueIndirectCost.document ||
        (valueIndirectCost.estimateMoney ?? valueIndirectCost.costMoney ?? valueIndirectCost.budgetMoney)
      if (found !== null && found !== undefined) {
        indirectCost.push(valueIndirectCost);
      }
    });

    const version = listVersion.length != 0 ? Number(listVersion[0]?.version) : 1

    results.materialRegisterCost = materialCost;
    results.outsourceRegisterCost = outsourceRegisterCost;
    results.laborRegisterCost = laborCost;
    results.indirectConstructCost = indirectCost;
    results.listDocument = fileListAmountRelate.map((element: any) => {
      return {
        fileId: element.isAddNew ? 0 : element.fileId,
        rootPrefix: element.isAddNew ? (listVersion.length == 0 ? 1 : version + 1) : element.rootPrefix,
        fileName: element.name
      }
    })

    const responseUpdate: ResponseUpdate = {
      data: results,
      documentId: Number(summaryId),
      note: "",
    };

    const isWarning =
      subcontractCostList?.length !== 0
        ? subcontractCostList.some(
          (element: any) => !element?.startTime && !element?.endTime
        )
        : false;

    const callAPI = async () => {
      if (listVersion?.length === 0) {
        await updateRelateMoney({
          responseUpdate: responseUpdate,
          formValue: formValue,
        });
        return;
      }

      if (!errorAmount) {
        setDataRelate({
          responseUpdate: responseUpdate,
          formValue: formValue,
        });
        setIsViewModalHistory(true);
        setIsEditNote(true);
      }
    };

    if (isWarning) {
      WarningModalCR001({
        onOk: async () => {
          await callAPI();
        },
        onCancel: () => { },
        title: MODAL_WARNING_TITLE,
        description: MODAL_WARNING_DESCRIPTION,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else await callAPI();
  };

  const checkValueInput = (inputValue: number) => {
    if (inputValue || inputValue === 0 || inputValue === null) return true;
    return false;
  };

  const handleChangeFormAmount = (value: any) => {
    setIsChangeEdit(true);
    const newOutsourcingFee: any = {
      ...outsourcingFee,
    };

    if (checkValueInput(value.outsourceEstimateCost)) {
      newOutsourcingFee.outsourceEstimateCost = value.outsourceEstimateCost;
    }

    if (checkValueInput(value.outsourceTotalCost)) {
      newOutsourcingFee.outsourceTotalCost = value.outsourceTotalCost;
    }

    if (checkValueInput(value.outsourceBudgetCost)) {
      newOutsourcingFee.outsourceBudgetCost = value.outsourceBudgetCost;
    }

    setOutsourcingFee(newOutsourcingFee);

    let contractMoney: number = 0;
    if (checkValueInput(estimateContractMoney.contractMoney)) {
      contractMoney = estimateContractMoney.contractMoney;
    }

    const newEstimateContractMoney = { ...estimateContractMoney };

    if (checkValueInput(value["estimateContractMoney"]?.contractMoney)) {
      newEstimateContractMoney.contractMoney =
        value["estimateContractMoney"]?.contractMoney;
      contractMoney = value["estimateContractMoney"]?.contractMoney;
    }

    if (checkValueInput(value["estimateContractMoney"]?.estimateMoney)) {
      newEstimateContractMoney.estimateMoney =
        value["estimateContractMoney"]?.estimateMoney;
    }

    if (checkValueInput(value["estimateContractMoney"]?.unitPersonCost)) {
      newEstimateContractMoney.unitPersonCost =
        value["estimateContractMoney"]?.unitPersonCost;
    }

    if (checkValueInput(value["estimateContractMoney"]?.estimateLaborCost)) {
      newEstimateContractMoney.estimateLaborCost =
        value["estimateContractMoney"]?.estimateLaborCost;
    }

    setEstimateContractMoney(newEstimateContractMoney);

    const materialCostChange = materialCostList.map((materialCost: any) => {
      const materialCostObj = {
        ...materialCost,
      };

      materialCostObj.budgetMoneyPercent = handleCalculator(
        materialCostObj.budgetMoney,
        contractMoney
      );
      materialCostObj.orderMoneyPercent = handleCalculator(
        materialCostObj.orderMoney,
        contractMoney
      );

      if (value[`materialCost${materialCost.key}`]) {
        if (value[`materialCost${materialCost.key}`].traderName) {
          materialCostObj.traderName =
            value[`materialCost${materialCost.key}`].traderName;
        }

        if (value[`materialCost${materialCost.key}`].traderName === "") {
          materialCostObj.traderName = "";
        }
        if (value[`materialCost${materialCost.key}`].materialName) {
          materialCostObj.materialName =
            value[`materialCost${materialCost.key}`].materialName;
        }

        if (value[`materialCost${materialCost.key}`].materialName === "") {
          materialCostObj.materialName = "";
        }

        if (
          checkValueInput(
            value[`materialCost${materialCost.key}`].estimateMoney
          )
        ) {
          materialCostObj.estimateMoney =
            value[`materialCost${materialCost.key}`].estimateMoney;
        }


        if (
          checkValueInput(value[`materialCost${materialCost.key}`].costMoney)
        ) {
          materialCostObj.costMoney =
            value[`materialCost${materialCost.key}`].costMoney;
        }


        if (
          checkValueInput(value[`materialCost${materialCost.key}`].budgetMoney)
        ) {
          materialCostObj.budgetMoney =
            value[`materialCost${materialCost.key}`].budgetMoney;
          materialCostObj.budgetMoneyPercent = handleCalculator(
            value[`materialCost${materialCost.key}`].budgetMoney,
            contractMoney
          );
        }

        if (
          checkValueInput(value[`materialCost${materialCost.key}`].orderMoney)
        ) {
          materialCostObj.orderMoney =
            value[`materialCost${materialCost.key}`].orderMoney;
          materialCostObj.orderMoneyPercent = handleCalculator(
            value[`materialCost${materialCost.key}`].orderMoney,
            contractMoney
          );
        }


        if (value[`materialCost${materialCost.key}`]?.documentMaterial) {
          if (value[`materialCost${materialCost.key}`].documentMaterial.file?.originFileObj?.name !== materialCost.document) {
            materialCostObj.document = value[`materialCost${materialCost.key}`].documentMaterial.file?.originFileObj?.name;
            materialCostObj.documentRootPrefix = null
          }
        }

      }
      return materialCostObj;
    });
    setMaterialCostList(materialCostChange);

    const subCostChange = subcontractCostList.map((subCost: any) => {
      const subCostCostObj = {
        ...subCost,
      };
      subCostCostObj.budgetMoneyPercent = handleCalculator(
        subCostCostObj.budgetMoney,
        contractMoney
      );
      subCostCostObj.orderMoneyPercent = handleCalculator(
        subCostCostObj.orderMoney,
        contractMoney
      );
      if (value[`outsourceRegisterCost${subCost.key}`]) {
        if (value[`outsourceRegisterCost${subCost.key}`].constructionName) {
          subCostCostObj.constructionName =
            value[`outsourceRegisterCost${subCost.key}`].constructionName;
        }

        if (
          value[`outsourceRegisterCost${subCost.key}`].constructionName === ""
        ) {
          subCostCostObj.constructionName = "";
        }
        if (value[`outsourceRegisterCost${subCost.key}`].contractTime) {
          subCostCostObj.contractTime =
            value[`outsourceRegisterCost${subCost.key}`].contractTime?._d;
        }
        if (
          value[`outsourceRegisterCost${subCost.key}`].contractTime === null
        ) {
          subCostCostObj.contractTime = "";
        }

        if (value[`outsourceRegisterCost${subCost.key}`].timeSpace) {
          subCostCostObj.startTime =
            value[`outsourceRegisterCost${subCost.key}`].timeSpace[0]?._d;
          subCostCostObj.endTime =
            value[`outsourceRegisterCost${subCost.key}`].timeSpace[1]?._d;
        }
        if (value[`outsourceRegisterCost${subCost.key}`].timeSpace === null) {
          subCostCostObj.startTime = "";
          subCostCostObj.endTime = "";
        }
        if (
          checkValueInput(
            value[`outsourceRegisterCost${subCost.key}`].estimateMoney
          )
        ) {
          subCostCostObj.estimateMoney =
            value[`outsourceRegisterCost${subCost.key}`].estimateMoney;
        }

        if (
          checkValueInput(
            value[`outsourceRegisterCost${subCost.key}`].costMoney
          )
        ) {
          subCostCostObj.costMoney =
            value[`outsourceRegisterCost${subCost.key}`].costMoney;
        }

        if (
          checkValueInput(
            value[`outsourceRegisterCost${subCost.key}`].budgetMoney
          )
        ) {
          subCostCostObj.budgetMoney =
            value[`outsourceRegisterCost${subCost.key}`].budgetMoney;
          subCostCostObj.budgetMoneyPercent = handleCalculator(
            value[`outsourceRegisterCost${subCost.key}`].budgetMoney,
            contractMoney
          );
        }

        if (
          checkValueInput(
            value[`outsourceRegisterCost${subCost.key}`].orderMoney
          )
        ) {
          subCostCostObj.orderMoney =
            value[`outsourceRegisterCost${subCost.key}`].orderMoney;
          subCostCostObj.orderMoneyPercent = handleCalculator(
            value[`outsourceRegisterCost${subCost.key}`].orderMoney,
            contractMoney
          );
        }



        if (value[`outsourceRegisterCost${subCost.key}`]
          ?.documentOutsourceRegisterCost) {
          if (value[`outsourceRegisterCost${subCost.key}`]
            .documentOutsourceRegisterCost?.file?.originFileObj?.name !== subCost.document) {
            subCostCostObj.document = value[`outsourceRegisterCost${subCost.key}`]
              .documentOutsourceRegisterCost?.file?.originFileObj?.name
            subCostCostObj.documentRootPrefix = null
          }
        }
      }
      return subCostCostObj;
    });
    setSubcontractCostList(subCostChange);

    const laborCostChange = laborCostList.map((laborCost: any) => {
      const laborCostObj = {
        ...laborCost,
      };
      laborCostObj.budgetMoneyPercent = handleCalculator(
        laborCostObj.budgetMoney,
        contractMoney
      );

      if (value[`laborCost${laborCost.key}`]) {
        if (value[`laborCost${laborCost.key}`].managerId) {
          laborCostObj.managerId = value[`laborCost${laborCost.key}`].managerId;
        }
        if (value[`laborCost${laborCost.key}`].manager === "") {
          laborCostObj.manager = null;
        }
        if (checkValueInput(value[`laborCost${laborCost.key}`].estimateMoney)) {
          laborCostObj.estimateMoney =
            value[`laborCost${laborCost.key}`].estimateMoney;
        }

        if (checkValueInput(value[`laborCost${laborCost.key}`].costMoney)) {
          laborCostObj.costMoney = value[`laborCost${laborCost.key}`].costMoney;
        }


        if (checkValueInput(value[`laborCost${laborCost.key}`].budgetMoney)) {
          laborCostObj.budgetMoney =
            value[`laborCost${laborCost.key}`].budgetMoney;
          laborCostObj.budgetMoneyPercent = handleCalculator(
            value[`laborCost${laborCost.key}`].budgetMoney,
            contractMoney
          );
        }

        if (checkValueInput(value[`laborCost${laborCost.key}`].orderMoney)) {
          laborCostObj.orderMoney =
            value[`laborCost${laborCost.key}`].orderMoney;
          laborCostObj.orderMoneyPercent = handleCalculator(
            value[`laborCost${laborCost.key}`].orderMoney,
            contractMoney
          );
        }

        if (value[`laborCost${laborCost.key}`].documentLaborCost) {
          if (value[`laborCost${laborCost.key}`].documentLaborCost?.file
            ?.originFileObj?.name !== laborCost.document) {
            laborCostObj.document = value[`laborCost${laborCost.key}`].documentLaborCost?.file
              ?.originFileObj?.name
            laborCostObj.documentRootPrefix = null
          }
        }

      }
      return laborCostObj;
    });
    setLaborCostList(laborCostChange);

    const indirectCostChange = listIndirect.map((indirect: any) => {
      const indirectObj = {
        ...indirect,
      };
      indirectObj.budgetMoneyPercent = handleCalculator(
        indirectObj.budgetMoney,
        contractMoney
      );
      if (value[`indirectCost${indirect.key}`]) {
        if (
          value[`indirectCost${indirect.key}`].item ||
          value[`indirectCost${indirect.key}`].item === ""
        ) {
          indirectObj.item = value[`indirectCost${indirect.key}`].item;
        }

        if (
          checkValueInput(value[`indirectCost${indirect.key}`].estimateMoney)
        ) {
          indirectObj.estimateMoney =
            value[`indirectCost${indirect.key}`].estimateMoney;
        }

        if (checkValueInput(value[`indirectCost${indirect.key}`].costMoney)) {
          indirectObj.costMoney =
            value[`indirectCost${indirect.key}`].costMoney;
        }

        if (checkValueInput(value[`indirectCost${indirect.key}`].budgetMoney)) {
          indirectObj.budgetMoney =
            value[`indirectCost${indirect.key}`].budgetMoney;
          indirectObj.budgetMoneyPercent = handleCalculator(
            value[`indirectCost${indirect.key}`].budgetMoney,
            contractMoney
          );
        }

        if (checkValueInput(value[`indirectCost${indirect.key}`].orderMoney)) {
          indirectObj.orderMoney =
            value[`indirectCost${indirect.key}`].orderMoney;
          indirectObj.orderMoneyPercent = handleCalculator(
            value[`indirectCost${indirect.key}`].orderMoney,
            contractMoney
          );
        }

        if (
          checkValueInput(
            value[`indirectCost${indirect.key}`].documentIndirectCost?.file
              ?.originFileObj?.name
          )
        ) {
          indirectObj.document =
            value[
              `indirectCost${indirect.key}`
            ].documentIndirectCost?.file?.originFileObj?.name;
        }

        if (value[`indirectCost${indirect.key}`].documentIndirectCost) {
          if (value[`indirectCost${indirect.key}`].documentIndirectCost?.file
            ?.originFileObj?.name !== indirect.document) {
            indirectObj.document = value[`indirectCost${indirect.key}`].documentIndirectCost?.file
              ?.originFileObj?.name
            indirectObj.documentRootPrefix = null

          }
        }

      }
      return indirectObj;
    });
    setListIndirect(indirectCostChange);
  };

  const handleCalculatorPercent = (dataRaw: any[], valueOther: any) => {
    if (dataRaw?.length === 0) {
      return {
        estimateMoney: null,
        costMoney: null,
        budgetMoney: null,
        orderMoney: null,
        budgetMoneyPercent: null,
        orderMoneyPercent: null,
      };
    }

    const results: any = {
      estimateMoney: dataRaw.every((item: any) => item.estimateMoney === null || item.estimateMoney === undefined) ? null : dataRaw.reduce((acc: any, cur: any) => {

        if (cur.estimateMoney) return acc + cur.estimateMoney;
        return acc;
      }, 0),
      costMoney: dataRaw.every((item: any) => item.costMoney === null || item.costMoney === undefined) ? null : dataRaw.reduce((acc: any, cur: any) => {

        if (cur.costMoney) return acc + cur.costMoney;
        return acc;
      }, 0),
      budgetMoney: dataRaw.every((item: any) => item.budgetMoney === null || item.budgetMoney === undefined) ? null : dataRaw.reduce((acc: any, cur: any) => {

        if (cur.budgetMoney) return acc + cur.budgetMoney;
        return acc;
      }, 0),
      orderMoney: dataRaw.every((item: any) => item.orderMoney === null || item.orderMoney === undefined) ? null : dataRaw.reduce((acc: any, cur: any) => {
        if (cur.orderMoney) return acc + cur.orderMoney;
        return acc;
      }, 0),
    };

    results.budgetMoneyPercent = handleCalculator(
      results.budgetMoney,
      valueOther
    );
    results.orderMoneyPercent = handleCalculator(
      results.orderMoney,
      valueOther
    );

    return results;
  };

  const totalMaterialCost = useMemo(() => {

    return handleCalculatorPercent(
      materialCostList,
      estimateContractMoney.contractMoney
    );
  }, [materialCostList, estimateContractMoney]);

  const totalSubContract = useMemo(() => {
    return handleCalculatorPercent(
      subcontractCostList,
      estimateContractMoney.contractMoney
    );
  }, [subcontractCostList, estimateContractMoney]);

  const totalLaborList = useMemo(() => {
    return handleCalculatorPercent(
      laborCostList,
      estimateContractMoney.contractMoney
    );
  }, [laborCostList, estimateContractMoney]);

  const totalIndirectCost = useMemo(() => {
    return handleCalculatorPercent(
      listIndirect,
      estimateContractMoney.contractMoney
    );
  }, [listIndirect, estimateContractMoney]);

  const totalDirectConstruction = useMemo(() => {

    return {
      estimateMoney: totalMaterialCost.estimateMoney == null && totalSubContract.estimateMoney == null && totalLaborList.estimateMoney == null ? null :
        totalMaterialCost.estimateMoney +
        totalSubContract.estimateMoney +
        totalLaborList.estimateMoney,
      costMoney: totalMaterialCost.costMoney == null && totalSubContract.costMoney == null && totalLaborList.costMoney == null ? null :
        totalMaterialCost.costMoney +
        totalSubContract.costMoney +
        totalLaborList.costMoney,
      budgetMoney: totalMaterialCost.budgetMoney == null && totalSubContract.budgetMoney == null && totalLaborList.budgetMoney == null ? null :
        totalMaterialCost.budgetMoney +
        totalSubContract.budgetMoney +
        totalLaborList.budgetMoney,
      orderMoney: totalMaterialCost.orderMoney == null && totalSubContract.orderMoney == null && totalLaborList.orderMoney == null ? null :
        totalMaterialCost.orderMoney +
        totalSubContract.orderMoney +
        totalLaborList.orderMoney,
      budgetMoneyPercent: totalMaterialCost.budgetMoney == null && totalSubContract.budgetMoney == null && totalLaborList.budgetMoney == null && estimateContractMoney.contractMoney == null ? null :
        handleCalculator(
          totalMaterialCost.budgetMoney +
          totalSubContract.budgetMoney +
          totalLaborList.budgetMoney,
          estimateContractMoney.contractMoney
        ),
      orderMoneyPercent:
        totalMaterialCost.orderMoney == null && totalSubContract.orderMoney == null && totalLaborList.orderMoney == null && estimateContractMoney.contractMoney == null ? null :
          handleCalculator(
            totalMaterialCost.orderMoney +
            totalSubContract.orderMoney +
            totalLaborList.orderMoney,
            estimateContractMoney.contractMoney
          ),
    };

  }, [
    totalMaterialCost,
    totalSubContract,
    totalLaborList,
    estimateContractMoney,
  ]);

  const consumptionRate = useMemo(() => {

    const result = {
      value: outsourcingFee.outsourceBudgetCost != null && totalSubContract.orderMoney != null ? outsourcingFee.outsourceBudgetCost - totalSubContract.orderMoney : null,
      percent:
        totalSubContract.orderMoney != null && outsourcingFee.outsourceBudgetCost != null ?
          handleCalculator(
            totalSubContract.orderMoney,
            outsourcingFee.outsourceBudgetCost
          ) : null,
    };
    return result;
  }, [totalSubContract.orderMoney, outsourcingFee.outsourceBudgetCost]);

  const totalAll = useMemo(() => {
    return {
      estimateMoney: totalDirectConstruction.estimateMoney == null && totalIndirectCost.estimateMoney == null ? null :
        totalDirectConstruction.estimateMoney + totalIndirectCost.estimateMoney,
      costMoney: totalDirectConstruction.costMoney == null && totalIndirectCost.costMoney == null ? null :
        totalDirectConstruction.costMoney + totalIndirectCost.costMoney,
      budgetMoney: totalDirectConstruction.budgetMoney == null && totalIndirectCost.budgetMoney == null ? null :
        totalDirectConstruction.budgetMoney + totalIndirectCost.budgetMoney,
      orderMoney: totalDirectConstruction.orderMoney == null && totalIndirectCost.orderMoney == null ? null :
        (totalDirectConstruction.orderMoney + totalIndirectCost.orderMoney),
      budgetMoneyPercent: totalDirectConstruction.budgetMoney == null && totalIndirectCost.budgetMoney == null &&
        estimateContractMoney.contractMoney == null ? null :
        handleCalculator(
          totalDirectConstruction.budgetMoney + totalIndirectCost.budgetMoney,
          estimateContractMoney.contractMoney
        ),
      orderMoneyPercent:
        totalDirectConstruction.orderMoney == null && totalIndirectCost.orderMoney == null && estimateContractMoney.contractMoney == null ? null :
          handleCalculator(
            totalDirectConstruction.orderMoney + totalIndirectCost.orderMoney,
            estimateContractMoney.contractMoney
          ),
    };
  }, [totalDirectConstruction, totalIndirectCost, estimateContractMoney]);

  const valueCalculator = useMemo(() => {

    return {
      profitAmount: estimateContractMoney.contractMoney == null && totalDirectConstruction.budgetMoney == null && totalIndirectCost.budgetMoney == null ? null :
        estimateContractMoney.contractMoney -
        (totalDirectConstruction.budgetMoney +
          totalIndirectCost.budgetMoney),
      grossProfit: handleCalculator(
        estimateContractMoney.contractMoney -
        (totalDirectConstruction.budgetMoney + totalIndirectCost.budgetMoney),
        estimateContractMoney.contractMoney
      ),
    };
  }, [estimateContractMoney, totalDirectConstruction, totalIndirectCost]);

  const checkFormatValue = (
    value: number | null,
    isPercent: boolean,
    isViewZero?: boolean
  ) => {
    if (isViewZero && value === 0) return isPercent ? "(0%)" : "0";
    if (value === null) return isPercent ? "(%)" : "";
    if (!value) {
      return "";
    }
    if (!isPercent) {
      return Number(value)?.toLocaleString("en-US", {
        maximumFractionDigits: 2,
      });
    }

    return `(${helpers.funcFormatter(value, 2)}%)`;
  };

  const checkFormatDate = (date1: string, date2: string) => {
    if (!date1 || (!date2 && date2 !== "")) {
      return "";
    }

    if (date2 === "") return `${moment(date1).format(COMMON.FORMAT_DATE)}`;

    return `${moment(date1).format(COMMON.FORMAT_DATE)} - ${moment(
      date2
    ).format(COMMON.FORMAT_DATE)}`;
  };

  //handle version and note
  const getListVersion = async (params: any) => {
    try {
      const response = await cr003Service.getListVersion({
        documentId: params.documentId,
      });
      const resultsData = response.data?.results ?? [];

      setListVersion(resultsData);

      if (params.versionId) {
        const foundVersion = resultsData.find(
          (element: any) => element.id + "" === params.versionId
        );
        if (foundVersion)
          setVersionNew({
            versionNumber: foundVersion?.version,
            versionView: foundVersion?.version,
          });
        return;
      }

      if (response.data?.results?.length > 0) {
        setVersionNew({
          versionNumber: resultsData[0]?.version,
          versionView: resultsData[0]?.version,
        });
      } else {
        setVersionNew({ versionNumber: 0, versionView: 0 });
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };
  const getListVersionApproval = async (params: any) => {
    try {
      const response = await cr003Service.getListVersionApproval({
        documentId: params.documentId,
      });
      const resultsData = response.data?.results ?? [];

      setListVersionApproval(resultsData);
      if (params.versionId) {
        const foundVersion = resultsData.find(
          (element: any) => element.id + "" === params.versionId
        );
        if (foundVersion)
          setVersionNew({
            versionNumber: foundVersion?.version,
            versionView: foundVersion?.version,
          });
        return;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };
  const getVersionData = async (params: {
    documentPaymentConfirmDetailId: string | number;
  }) => {
    dispatch(setLoading(true));
    try {
      const response = await cr003Service.getVersionData(params);
      setListRelateMoney(response.data.results);
      if (response.data.results?.listDocument?.length != 0) {
        const version = listVersion.length != 0 ? Number(listVersion[0]?.version) : 1
        const result = response.data.results?.listDocument.map((item: any, index: number) => {
          return {
            key: `${Math.random()}${index}`,
            uid: `${Math.random()}${index}`,
            name: item.fileName,
            path: `construction/${summaryId}/cr_payment_detail/${listVersion.length == 0 ? 1 : version + 1}/${item.fileName}`,
            rootPrefix: item.rootPrefix,
            fileId: item.fileId
          }
        })
        setFileListAmountRelate(result);
      } else {
        setFileListAmountRelate([]);
      }
      handleDataRelateMoney(response.data.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
  };
  const handleOpenNoteHistory = (note: string, noteVersion: number) => {
    setIsViewModalHistory(true);
    setIsOpenDrawer(false);
    setDetailHistoryNote(note);
    setVersionNew({
      ...versionNew,
      versionNumber: noteVersion,
    });
  };

  const handleCloseNoteHistory = () => {
    setIsViewModalHistory(false);
    setDetailHistoryNote("");
    setIsEditNote(false);
    if (!isEditNote) {
      setIsOpenDrawer(true);
    }
  };

  const onChangeDetailHistoryNote = (e: any) => {
    setDetailHistoryNote(
      helpers.toLowerCaseNonAccentVietnamese(e.target.value)
    );
  };

  const handleKeepNote = () => {
    const results = {
      formValue: dataRelate?.formValue,
      responseUpdate: {
        data: dataRelate?.responseUpdate?.data,
        documentId: dataRelate?.responseUpdate?.documentId,
        note: detailHistoryNote
      }
    };

    window.removeEventListener("beforeunload", helpers.unloadCallback);
    updateRelateMoney(results);
  };

  const handleShowVersion = (
    id: number | string,
    versionNumber: number,
    type: string
  ) => {
    setIsOpenDrawer(false);
    setIsVersionNow(false);
    getVersionData({ documentPaymentConfirmDetailId: id });
    setVersionNew({
      versionView: versionNumber,
      versionNumber: versionNumber,
    });
    searchParams.set(PARAMS.VERSION_TYPE, type);
    searchParams.set(PARAMS.VERSION_ID, id + "");
    setSearchParams(searchParams);
  };

  const handleChangeVersionNow = () => {
    setIsRefreshAmount(!isRefreshAmount);
    setIsVersionNow(true);
    searchParams.delete(PARAMS.VERSION_ID);
    searchParams.delete(PARAMS.VERSION_TYPE);
    setSearchParams(searchParams);
  };

  const handleFinishNote = () => {
    setIsViewModalHistory(false);
    setIsOpenDrawer(true);
  };

  const handleApproveCI = (descPopup: string, titlePopup: string) => {
    setTitlePopup(titlePopup)
    setDescriptionPopup(descPopup);
    setIsApprove(true);
    setIsApproveOrReject(true);
  };

  const handleCancelApprove = () => {
    setIsApprove(false);
  };
  const handleRejectCI = () => {
    setDescriptionPopup("実行予算書を否認してもよろしいでしょうか？");
    setIsApprove(true);
    setIsApproveOrReject(false);
  };

  const checkAutApprove = (currentUser: any, listApprover: any[]) => {
    const checkAut = JSON.parse(localStorage.getItem("user") || "{}");
    if (projectManagerId == checkAut?.id) {
      if (checkAut?.id == currentUser?.id) {
        if (currentUser.priority !== 1) return TYPE_USER.manager_approve;
        return TYPE_USER.owner_submit;
      }

      return TYPE_USER.owner_not_submit;
    } else {
      if (userId == checkAut?.id) return TYPE_USER.user_create;
      if (checkAut?.id == currentUser?.id) {
        if (currentUser.reject) {
          return TYPE_USER.view;
        }
        return TYPE_USER.approve;
      } else {
        return TYPE_USER.view;
      }
    }
  };

  const funcCheckEdit = (currentUser: any) => {
    const checkAut = +JSON.parse(localStorage.getItem("user") || "{}")?.id;
    if (!checkAut || !projectManagerId || !userId) return false;
    return (
      checkAut === +projectManagerId && currentUser?.id === +projectManagerId
    );
  };

  const funcCheckCr002 = (currentUser: any) => {
    const checkAut = +JSON.parse(localStorage.getItem("user") || "{}")?.id;
    if (
      !checkAut ||
      (!projectManagerId && projectManagerId !== "0") ||
      (!userId && userId !== "0")
    )
      return false;
    return (
      checkAut === +projectManagerId && currentUser?.id === +projectManagerId
    );
  };

  const handleOkApprove = (constructionId: string, note: any) => {
    setIsApprove(false);
    if (isApproveOrReject) {
      postApprovalProcess({
        constructionId: Number(constructionId),
        approval: true,
        note: note?.rejectReason,
      });
    } else {
      postApprovalProcess({
        constructionId: Number(constructionId),
        approval: false,
        note: note?.rejectReason,
      });
    }
  };

  const handleChangeNoteApprove = (e: any) => {
    setNoteApprove(helpers.toLowerCaseNonAccentVietnamese(e.target.value));
  };

  const handleWithDraw = (constructionId: string | undefined) => {
    ConfirmModal({
      onOk: () => {
        resetApproval({
          constructionId: Number(constructionId),
        });
      },
      onCancel: () => { },
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_CR_EXTRA,
      canceText: "キャンセル",
      okText: "はい",
    });
  };

  const handleViewModalExternal = (
    value: string,
    company: any,
    type: "subcontractor" | "materialSuppliers" | ""
  ) => {
    setCompanyChange(value);
    dispatch(setChangeChosenCompany(company?.id));
    dispatch(setViewChooseCompany(true));
    dispatch(setTypeModal(type));
  };

  const handleCloseModalExternal = () => {
    dispatch(setViewChooseCompany(false));
  };

  const handleCancelChooseCompany = () => {
    dispatch(setViewChooseCompany(false));
  };

  const handleConfirmChooseCompany = () => {
    const company = listCompany.find(
      (company: any) => company.id === chosenCompany
    );
    if (type === "subcontractor") {
      const newSubSource = subcontractCostList.map((subcontract: any) => {
        const subcontractObj = { ...subcontract };

        if (companyChange === `outsourceRegisterCost${subcontract.key}`) {
          subcontractObj.company = company;
        }
        return subcontractObj;
      });
      setSubcontractCostList(newSubSource);
    } else {
      const newMaterial = materialCostList.map((material: any) => {
        const materialObj = { ...material };
        if (companyChange === `materialCost${material.key}`) {
          materialObj.materialCostCompany = company;
          const newRequired = requiredMaterial.filter(
            (ele) => ele.key === material.key
          );
          setRequiredMaterial([]);
        }
        return materialObj;
      });
      setMaterialCostList(newMaterial);
    }

    dispatch(setViewChooseCompany(false));
  };
  const funcViewModalMailContractor = () => {
    const listUnInvited = subcontractCostList.filter(
      (element) => !element.isInvited && element?.company?.id
      // element.contractTime &&
      // element.startTime &&
      // element.endTime,
    );
    const listCompany = listUnInvited.map((element) => {
      return {
        ...element.company,
        constructionName: listRelateMoney.constructionName,
        constructionNameChild: element.constructionName,
        id: element.id,
        startTime: element?.startTime,
        endTime: element?.endTime,
      };
    });
    dispatch(setListCompany(listCompany));

    dispatch(setViewModalCR001());
  };
  const funcCheckDuplicate = async (
    listOutsource: any[],
    documentId: number,
    constructionName: string,
    outsourceRegisterCostDocumentId: number,
    key: string
  ) => {
    try {
      const data = {
        documentId,
        constructionName,
        outsourceRegisterCostDocumentId,
      };
      const newError = formAmount.getFieldsError();

      if (constructionName) {
        const response = await cr003Service.checkDuplicate(data);
        if (response.data.results) {
          newError.forEach((element) => {
            if (
              element.name[0] === key &&
              element.errors?.length === 0 &&
              element.name[1] === "constructionName"
            ) {
              element.errors = MESSAGE_DUPLICATE;
            }
          });
        }
        formAmount.setFields(newError);
      }
    } catch (error) { }
  };

  const handleResetError = (form: FormInstance) => {
    const currentError = form.getFieldsError();
    const newError = currentError.map((element) => {
      const obj = { ...element };
      obj.errors = element?.errors?.filter(
        (ele) => ele !== MESSAGE_DUPLICATE[0] && ele !== MESSAGE_DUPLICATE[1]
      );
      return obj;
    });
    return newError;
  };

  const handleAfterSendMail = (keyItem: React.Key[]) => {
    const newOutsource = subcontractCostList.map((element) => {
      const obj = { ...element };
      if (keyItem.includes(element.id)) {
        obj.isInvited = true;
      }
      return obj;
    });
    setSubcontractCostList(newOutsource);
  };

  const funcCheckButtonSendMail = (): boolean => {
    const user = localStorage.getItem("user");

    if (!user || !projectManagerId || !(listApprovalProcess?.length > 0))
      return false;
    const userId = +JSON.parse(user).id;
    const approve = listApprovalProcess.every((ele) => ele.isApprove);
    return approve && (userId === +projectManagerId || isRoleAdmin);
  };

  const [listTooltip, setListTooltip] = useState<any>([]);
  useEffect(() => {
    const listTextTooltip = document.getElementsByClassName("text-tooltip");
    const results = [...listTooltip];
    for (let element of listTextTooltip) {
      if (element.clientWidth !== element.scrollWidth) {
        results.push(element.id);
        setListTooltip(results);
      }
    }
  }, [subcontractCostList, materialCostList, listIndirect, laborCostList]);

  //select member

  const getListMember = async (params: {
    page: number;
    size: number;
    ignoreUser: string;
    keyword?: string;
  }) => {
    try {
      const paramsGet: any = { ...params };
      const response = await cr003Service.getListMember(paramsGet);
      if (params.page === 1) {
        setMembers(
          handleDuplicateMember(response.results, currentOption, params.keyword)
        );
      } else {
        setMembers(
          handleDuplicateMember(
            [...members, ...response.results],
            currentOption,
            params.keyword
          )
        );
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setMembers([]);
    }
  };

  const handleDuplicateMember = (
    raw: any,
    currentRaw: any,
    keyword?: string
  ) => {
    if (keyword) return raw;
    const results = [...currentRaw, ...raw];
    const check: any = {};
    const newResults: any = [];
    results.forEach((element: any) => {
      if (!check[element.id]) {
        newResults.push(element);
        check[element.id] = element;
      }
    });
    return newResults;
  };

  const handleGetListMember = () => {
    const user = localStorage.getItem("user");
    let ignoreUser = "";
    if (user) {
      ignoreUser = JSON.parse(user).id;
    }

    if (form.getFieldValue("projectManager") !== undefined) {
      ignoreUser += "," + String(form.getFieldValue("projectManager"));
    }
    getListMember({ page: 1, size: 100, ignoreUser: String(ignoreUser) });
  };

  const handleSearchOption = (searchValue: string) => {
    const user = localStorage.getItem("user");
    let ignoreUser = "";
    if (user) {
      ignoreUser = JSON.parse(user).id;
    }
    if (form.getFieldValue("projectManager") !== undefined) {
      ignoreUser += "," + String(form.getFieldValue("projectManager"));
    }
    getListMember({
      page: 1,
      size: 100,
      keyword: searchValue,
      ignoreUser: ignoreUser,
    });
    setKeywordMember(searchValue);
    setPageMember(1);
  };
  const handleScrollMember = (e: any) => {
    const user = localStorage.getItem("user");
    let ignoreUser = "";
    if (user) {
      ignoreUser = JSON.parse(user).id;
    }
    if (form.getFieldValue("projectManager") !== undefined) {
      ignoreUser += "," + String(form.getFieldValue("projectManager"));
    }
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageMember(pageMember + 1);
      getListMember({
        page: pageMember + 1,
        size: 100,
        keyword: keywordMember ? keywordMember : undefined,
        ignoreUser: ignoreUser,
      });
    }
  };

  const handleLoadingExport = (value: boolean) => {
    dispatch(setLoading(value));
  };

  const handleExportCR = () => {
    const sheetName: string = "実行予算書_テンプレート";
    const fileName = "実行予算書_テンプレート";

    const itemTable1 = materialCostList?.map((ele, index) => {
      return {
        a: index + 1,
        b: ele.materialName,
        c: ele.traderName,
        d: checkFormatValue(ele.estimateMoney, false),
        e: checkFormatValue(ele.costMoney, false),
        f: `${checkFormatValue(ele.budgetMoney, false)} ${checkFormatValue(
          ele.budgetMoneyPercent,
          true
        )}`,
        h: `${checkFormatValue(ele.orderMoney, false)} ${checkFormatValue(
          ele.orderMoneyPercent,
          true
        )}`,
      };
    });

    const itemTable2 = subcontractCostList?.map((ele) => {
      return {
        a: `${ele.constructionName}`,
        date1: checkFormatDate(ele?.contractTime, ""),
        date2: checkFormatDate(ele?.startTime, ele.endTime),
        b: ele.company?.name,
        c: checkFormatValue(ele.estimateMoney, false),
        d: checkFormatValue(ele.costMoney, false),
        e: `${checkFormatValue(ele.budgetMoney, false)} ${checkFormatValue(
          ele.budgetMoneyPercent,
          true
        )}`,
        g: `${checkFormatValue(ele.orderMoney, false)} ${checkFormatValue(
          ele.orderMoneyPercent,
          true
        )}`,
      };
    });

    const itemTable3 = laborCostList?.map((ele, index) => {
      return {
        a: index + 1,
        b: ele.manager?.fullName,
        c: checkFormatValue(ele.estimateMoney, false),
        d: checkFormatValue(ele.costMoney, false),
        e: `${checkFormatValue(ele.budgetMoney, false)} ${checkFormatValue(
          ele.budgetMoneyPercent,
          true
        )}`,
      };
    });

    const itemTable4 = listIndirect?.map((ele, index) => {
      return {
        a: index + 1,
        b: ele.item,
        c: checkFormatValue(ele.estimateMoney, false),
        d: checkFormatValue(ele.costMoney, false),
        e: `${checkFormatValue(ele.budgetMoney, false)} ${checkFormatValue(
          ele.budgetMoneyPercent,
          true
        )}`,
      };
    });

    const data: any = {
      tableOne: {
        a: basicInfo?.constructionCode,
        b: basicInfo?.estimationCode,
        c: basicInfo?.departmentIc,
        d: basicInfo?.constructionName,
        e: basicInfo?.picMainName,
        f: basicInfo?.picSubName,
        g:
          basicInfo?.constructionSummaryFromDate &&
            basicInfo?.constructionSummaryToDate
            ? `${moment(basicInfo?.constructionSummaryFromDate).format(
              COMMON.FORMAT_DATE_CI
            )} - ${moment(basicInfo?.constructionSummaryToDate).format(
              COMMON.FORMAT_DATE_CI
            )}`
            : "",
        h: basicInfo?.picBusinessName,
        i: basicInfo?.location,
        a1: basicInfo?.phoneNumber,
        a11: basicInfo?.rootOrderName,
        b1: basicInfo?.picCustomerName,
        c1: basicInfo?.fax,
        d1:
          basicInfo?.startDate && basicInfo?.endDate
            ? `${moment(basicInfo?.startDate).format(
              COMMON.FORMAT_DATE_CI
            )} - ${moment(basicInfo?.endDate).format(COMMON.FORMAT_DATE_CI)}`
            : "",
        e1: checkFormatValue(basicInfo?.areaFloor, false),
        f1: basicInfo?.contractCreateDate
          ? moment(basicInfo?.contractCreateDate).format(COMMON.FORMAT_DATE_CI)
          : "",
        g1: basicInfo?.conditionPayment,
        h1: checkFormatValue(basicInfo?.estimateArea, false),
        a2: basicInfo?.requireEstimation ? "有" : "無",
        c2: basicInfo?.requireItem ? "有" : "無",
        e2: basicInfo?.requireDirect ? "有" : "無",
        f2: checkFormatValue(basicInfo?.costArea, false),
        ee1: categoryValue?.category1?.description,
        ee2: categoryValue?.category2?.description,
        ee3: categoryValue?.category3?.description,
      },
      tableTwo: {
        a: checkFormatValue(estimateContractMoney.estimateMoney, false),
        d: checkFormatValue(estimateContractMoney.contractMoney, false),
        c: checkFormatValue(estimateContractMoney.unitPersonCost, false),
        b: checkFormatValue(estimateContractMoney.estimateLaborCost, false),
      },
      tableThree: {
        other: {
          a: checkFormatValue(consumptionRate?.value, false),
          b: checkFormatValue(consumptionRate?.percent, true)
            ?.replace("(", "")
            ?.replace(")", ""),
          c: checkFormatValue(listRelateMoney?.outsourceEstimateCost, false),
          d: checkFormatValue(listRelateMoney?.outsourceTotalCost, false),
          e: checkFormatValue(listRelateMoney?.outsourceBudgetCost, false),
          f: `${checkFormatValue(
            totalSubContract.orderMoney,
            false
          )} ${checkFormatValue(totalSubContract.orderMoneyPercent, true)}`,
        },
        itemOne: [
          // headers
          {
            a: "",
            b: "項目名",
            c: "業者名",
            d: "見積金額（円）",
            e: "原価金額（円）",
            f: "予算金額（円）",
            g: "率（％）",
            h: "発注金額（円）",
          },
          // contents
          ...itemTable1,
        ],
        totalItemOne: {
          a: checkFormatValue(totalMaterialCost.estimateMoney, false),
          b: checkFormatValue(totalMaterialCost.costMoney, false),
          c: `${checkFormatValue(
            totalMaterialCost.budgetMoney,
            false
          )} ${checkFormatValue(totalMaterialCost.budgetMoneyPercent, true)}`,
          d: `${checkFormatValue(
            totalMaterialCost.orderMoney,
            false
          )} ${checkFormatValue(totalMaterialCost.orderMoneyPercent, true)}`,
        },
        itemTwo: [
          // headers
          {
            a: "契約工事名",
            b: "業者名",
            c: "見積金額（円）",
            d: "原価金額（円）",
            e: "予算金額（円）",
            f: "率（％）",
            g: "発注金額（円）",
          },
          // contents
          ...itemTable2,
        ],
        totalItemTwo: {
          a: checkFormatValue(totalSubContract.estimateMoney, false),
          b: checkFormatValue(totalSubContract.costMoney, false),
          c: `${checkFormatValue(
            totalSubContract.budgetMoney,
            false
          )} ${checkFormatValue(totalSubContract.budgetMoneyPercent, true)}`,
          d: `${checkFormatValue(
            totalSubContract.orderMoney,
            false
          )} ${checkFormatValue(totalSubContract.orderMoneyPercent, true)}`,
        },
        itemThree: [
          // headers
          {
            a: "",
            b: "担当者",
            c: "見積金額（円）",
            d: "原価金額（円）",
            e: "予算金額（円）",
            f: "率（％）",
            g: "",
          },
          // contents
          ...itemTable3,
        ],
        totalItemThree: {
          a: checkFormatValue(totalLaborList.estimateMoney, false),
          b: checkFormatValue(totalLaborList.costMoney, false),
          c: `${checkFormatValue(
            totalLaborList.budgetMoney,
            false
          )} ${checkFormatValue(totalLaborList.budgetMoneyPercent, true)}`,
        },
        totalDirect: {
          a: checkFormatValue(totalDirectConstruction.estimateMoney, false),
          b: checkFormatValue(totalDirectConstruction.costMoney, false),
          c: `${checkFormatValue(
            totalDirectConstruction.budgetMoney,
            false
          )} ${checkFormatValue(
            totalDirectConstruction.budgetMoneyPercent,
            true
          )}`,
          d: `${checkFormatValue(
            totalDirectConstruction.orderMoney,
            false
          )} ${checkFormatValue(
            totalDirectConstruction.orderMoneyPercent,
            true
          )}`,
        },
        itemFour: [
          // headers
          {
            a: "",
            b: "項目名",
            c: "見積金額（円",
            d: "原価金額（円）",
            e: "予算金額（円）",
            f: "率（％）",
            g: "",
          },
          // contents
          ...itemTable4,
        ],
        totalItemFour: {
          a: checkFormatValue(totalIndirectCost.estimateMoney, false),
          b: checkFormatValue(totalIndirectCost.costMoney, false),
          c: `${checkFormatValue(
            totalIndirectCost.budgetMoney,
            false
          )} ${checkFormatValue(totalIndirectCost.budgetMoneyPercent, true)}`,
        },
        grossProfit: {
          a: checkFormatValue(valueCalculator?.profitAmount, false),
        },
        total: {
          a: checkFormatValue(totalAll.estimateMoney, false),
          b: checkFormatValue(totalAll.costMoney, false),
          c: `${checkFormatValue(
            totalAll.budgetMoney,
            false
          )} ${checkFormatValue(totalAll.budgetMoneyPercent, true)}`,
          d: `${checkFormatValue(
            totalAll.orderMoney,
            false
          )} ${checkFormatValue(totalAll.orderMoneyPercent, true)}`,
        },
      },
      tableFour: {
        role: listApprovalProcess.map((item: any) => item?.role?.name ?? ""),
        name: listApprovalProcess.map((item: any) => item.fullName),
        date: listApprovalProcess.map((item: any) =>
          moment(item.approveDate, COMMON.FORMAT_DATE_CI, true).isValid()
            ? moment(item.approveDate).format(COMMON.FORMAT_DATE_CI)
            : ""
        ),
      },
    };
    exportCR(sheetName, fileName, data, handleLoadingExport);
  };

  const getProjectForPermission = async (projectId: string) => {
    dispatch(setLoading(true));
    try {
      const response = await cr003Service.getDetailProject({
        projectId: projectId,
      });
      setDetailProject(response.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const checkApproved = (listApproval: any[]) => {
    let flag = true;
    if (listApproval.length !== 0) {
      listApproval.forEach((element: any) => {
        if (element.isApprove || element.reject) flag = false;
      });
    }
    return flag;
  };

  const exportTemplate = async (
    summaryId: string | undefined,
    projectManagerId: string | undefined
  ) => {
    dispatch(setLoading(true));
    try {
      const params = {
        projectId: projectManagerId,
        summaryId: summaryId,
        category1: categoryValue?.category1?.description || "",
        category2: categoryValue?.category2?.description || "",
        category3: categoryValue?.category3?.description || "",
        constructionId: summaryId,
      };
      const projectName = searchParams.get("projectName");

      const response = await cr003Service.exportTemplate(params);
      doExportForResponse(
        response,
        projectName +
        "_" +
        basicInfo.constructionName +
        "_" +
        FILE_NAME.CR003 +
        ".xlsx"
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setBasicInfo({});
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOpenStepNoteHistory = (value: any) => {
    dispatch(setVersionIdCR005(value.id));
  };

  const switchTabVersion = (tab: string) => {
    setTabVersion(tab);
  };

  const beforeUpload = (file: RcFile): boolean => {
    const isJpgOrPng = ["image/jpeg", "image/png", "application/pdf", "image/jpg"].includes(file.type);
    const fileSizeInMB = file.size / 1024 / 1024
    const isLt8M = fileSizeInMB < 8;

    if (!isJpgOrPng || !isLt8M) {
      if (!errorDisplayed) {
        if (!isJpgOrPng) {
          message.error("ファイルの形式が不正です。");
        } else if (!isLt8M) {
          message.error("8MB以上のファイルはアップロードできません。");
        }
        errorDisplayed = true;
      }
      return false;
    }
    return true;
  };

  const checkDuplicateFileName = (fileList: RcFile[], newFiles: RcFile[]): boolean => {
    const existingFileNames = fileList.map(file => file.name);
    return newFiles.some(file => existingFileNames.includes(file.name));
  };

  let errorDisplayed = false;
  let messageDisplayed = false;

  const onChangeUpload = (value: any): void => {
    if (value?.fileList && value?.fileList.length > 0) {
      let filteredFiles: RcFile[] = value.fileList.filter((file: RcFile) => beforeUpload(file));
      const maxFiles = 20;

      if (currentTab === PARAM_TAB.current) {
        handleFileUpload(fileList, setFileList, filteredFiles, maxFiles);
      }
    }
    setIsChangeEdit(true)
  };

  const handleFileUpload = (
    fileList: RcFile[],
    setFileList: React.Dispatch<React.SetStateAction<RcFile[]>>,
    filteredFiles: RcFile[],
    maxFiles: number
  ): void => {
    const currentFileListCount = fileList.length;
    const totalFilesCount = currentFileListCount + filteredFiles.length;

    // Remove duplicate file names
    const hasDuplicateFileName = checkDuplicateFileName(fileList, filteredFiles);
    if (hasDuplicateFileName) {
      if (!messageDisplayed) {
        message.error("ファイル名が重複しております。");
        messageDisplayed = true;
      }
      filteredFiles = filteredFiles.filter(file => !checkDuplicateFileName(fileList, [file]));
    }

    // Check for max file count
    if (totalFilesCount > maxFiles) {
      if (!messageDisplayed) {
        message.error("最大20ファイルしかアップロードできません。");
        messageDisplayed = true;
      }
      filteredFiles = filteredFiles.slice(0, maxFiles - currentFileListCount);
    }

    const resultForFileList = filteredFiles.map(item => ({ ...item, isAddNew: true }));
    setFileList((prevFileList) => {
      // Filter out files with duplicate names before adding new files
      const filteredPrevFileList = prevFileList.filter(
        prevFile => !resultForFileList.some(newFile => newFile.name === prevFile.name)
      );
      return [...filteredPrevFileList, ...resultForFileList];
    });
  };

  const onDropUpload = (value: any) => {
    ConfirmModalAsync({
      onOk: async () => {
        if (value.isAddNew) {
          const result = fileList.filter((item: any) => item.name != value.name)
          setFileList(result)
        } else {
          await deleteFileCI(

            `construction/${summaryId}/cr/${value.name}`
          )
          setIsRefresh(!isRefresh)
        }
      },
      onCancel: () => { },
      title: "削除確認",
      className: "confirm__modal ",
      description: "このファイルを削除してもよろしいでしょうか？",
      canceText: "キャンセル",
      okText: "削除",
    });
  };

  const deleteFileCI = async (keyName: any) => {
    try {
      dispatch(setLoading(true));
      const response = await cr003Service.deleteFileCI(keyName);
      SuccessNotification(response?.message, "");
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getPdfMap = async (value: any) => {
    const fileName = value.name?.split(".").slice(0, -1).join(".");
    dispatch(setFileName(fileName));
    if (value.path)
      try {
        const params = {
          keyName: value.path,
        };
        const response = await cr003Service.getPresignLinkS3(params);
        setPdfUrl(response.results);
        const checkFile = response.results
          ?.split(".")
          .pop()
          ?.split("?")
          .shift();

        if (checkFile === "pdf") return dispatch(showPdfViewerModal());
        dispatch(showImageViewerModal());
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      }
    else {
      if (!value.url && !value.preview) {
        value.preview = await helpers.getBase64(value.originFileObj as RcFile);
      }
      setPdfUrl(value.url || (value.preview as string));
      if (value.type.includes("image/")) {
        dispatch(showImageViewerModal());
      } else {
        dispatch(showPdfViewerModal());
      }
    }
  };

  const getLinkPresignUpload = async (params: any) => {
    try {
      const response = await cr003Service.getLinkPresignUpload(params);

      if (response) {
        return response.results;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const uploadTos3 = async (url: any, data: any) => {
    try {
      const response = await cr003Service.uploadToS3(url, data);
      return response.results;
    } catch (error) {
      throw error;
    }
  };

  const uploadToAwsS3 = async (keyName: string, originFileObj: any) => {
    const linkPreSignUpload = await getLinkPresignUpload({
      keyName: keyName,
    });
    await uploadTos3(linkPreSignUpload, originFileObj);
  };

  const handleUploadFile = async (dataPayload: any) => {
    const { resultsListRelateMoney, formValue } = dataPayload;

    const indirectConstructCost: any[] = [];
    const laborRegisterCost: any[] = [];
    const materialRegisterCost: any[] = [];
    const outsourceRegisterCost: any[] = [];
    for (const key in formValue) {
      if (key.includes("materialCost"))
        materialRegisterCost.push(formValue[key]);
      else if (key.includes("laborCost"))
        laborRegisterCost.push(formValue[key]);
      else if (key.includes("outsourceRegisterCost"))
        outsourceRegisterCost.push(formValue[key]);
      else if (key.includes("indirectCost"))
        indirectConstructCost.push(formValue[key]);
    }

    const promiseAllCollection: any[] = [];
    resultsListRelateMoney.materialRegisterCost.forEach(
      (element: any, index: number) => {
        if (materialRegisterCost[index].documentMaterial)
          promiseAllCollection.push(
            uploadToAwsS3(
              `material/${element.documentRootPrefix}/${materialRegisterCost[index].documentMaterial.file?.name}`,
              materialRegisterCost[index].documentMaterial.file?.originFileObj
            )
          );
      }
    );

    resultsListRelateMoney.outsourceRegisterCost.forEach(
      (element: any, index: number) => {
        if (outsourceRegisterCost[index].documentOutsourceRegisterCost)
          promiseAllCollection.push(
            uploadToAwsS3(
              `outsource/${element.documentRootPrefix}/${outsourceRegisterCost[index].documentOutsourceRegisterCost.file?.name}`,
              outsourceRegisterCost[index].documentOutsourceRegisterCost.file
                ?.originFileObj
            )
          );
      }
    );

    resultsListRelateMoney.indirectConstructCost.forEach(
      (element: any, index: number) => {
        if (indirectConstructCost[index].documentIndirectCost)
          promiseAllCollection.push(
            uploadToAwsS3(
              `indirect/${element.documentRootPrefix}/${indirectConstructCost[index].documentIndirectCost.file?.name}`,
              indirectConstructCost[index].documentIndirectCost.file
                ?.originFileObj
            )
          );
      }
    );
    resultsListRelateMoney.laborRegisterCost.forEach(
      (element: any, index: number) => {
        if (laborRegisterCost[index].documentLaborCost)
          promiseAllCollection.push(
            uploadToAwsS3(
              `labor/${element.documentRootPrefix}/${laborRegisterCost[index].documentLaborCost.file?.name}`,
              laborRegisterCost[index].documentLaborCost.file?.originFileObj
            )
          );
      }
    );
    await Promise.all(promiseAllCollection);
  };

  const handleViewFile = async (record: any, type: string) => {
    try {
      dispatch(setLoading(true));
      const response = await cr003Service.getPresignLinkS3({
        keyName: `${type}/${record.documentRootPrefix == null ? record.id : record.documentRootPrefix}/${record.document}`,
      });
      setPdfUrlAmountRelate(response.results);
      const checkFile = record.document?.split(".").pop();
      const fileName = record.document?.split(".").slice(0, -1).join(".");
      dispatch(setFileName(fileName));
      if (checkFile === "pdf") {
        dispatch(showPdfViewerModal());
      } else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleUploadFileBasicInfo = async (data: any) => {

    const promiseAllCollection: any[] = [];
    data.forEach(
      (element: any) => {
        if (element?.originFileObj?.name)
          promiseAllCollection.push(
            uploadToAwsS3(

              currentTab === "1" ? `construction/${summaryId}/cr/${element?.originFileObj?.name}`
                : `construction/${summaryId}/cr_payment_detail/${element.rootPrefix}/${element?.originFileObj?.name}`
              ,
              element?.originFileObj
            )
          );
      }
    );

    await Promise.all(promiseAllCollection);
  };

  const afterClear = (formName: any, listData: any, type: string) => {
    const recordKeyName = formName[0]?.replaceAll(type, "");
    const data = type == "indirectCost" ? listIndirect : type == "laborCost" ? laborCostList : type === "materialCost"
      ? materialCostList : type === "outsourceRegisterCost" ? subcontractCostList : []
    const result = data.map((item: any) => {
      if (+item.key === +recordKeyName) {
        return {
          ...item,
          document: ""
        }
      }
      return item
    })
    switch (type) {
      case "indirectCost":
        setListIndirect(result)
        break;
      case "laborCost":
        setLaborCostList(result)
        break;
      case "materialCost":
        setMaterialCostList(result)
        break;
      case "outsourceRegisterCost":
        setSubcontractCostList(result)
        break;
      default:
        break;
    }
  }
  const uploadAmountRelate = async (dataPayload: any) => {
    dispatch(setLoading(true))
    try {
      await handleUploadFile(dataPayload);

    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false))
    }
  }

  let messageDisplayedAmountRelate = false;

  const onChangeUploadAmountRelate = (value: any): void => {
    if (value?.fileList && value?.fileList.length > 0) {
      let filteredFiles: RcFile[] = value.fileList.filter((file: RcFile) => beforeUpload(file));
      const maxFiles = 20;


      handleFileUploadAmountRelate(fileListAmountRelate, setFileListAmountRelate, filteredFiles, maxFiles);

    }
    setIsChangeEdit(true)
  };

  const handleFileUploadAmountRelate = (
    fileList: RcFile[],
    setFileList: React.Dispatch<React.SetStateAction<RcFile[]>>,
    filteredFiles: RcFile[],
    maxFiles: number
  ): void => {
    const currentFileListCount = fileList.length;
    const totalFilesCount = currentFileListCount + filteredFiles.length;

    // Remove duplicate file names
    const hasDuplicateFileName = checkDuplicateFileName(fileList, filteredFiles);
    if (hasDuplicateFileName) {
      if (!messageDisplayedAmountRelate) {
        message.error("ファイル名が重複しております。");
        messageDisplayedAmountRelate = true;
      }
      filteredFiles = filteredFiles.filter(file => !checkDuplicateFileName(fileList, [file]));
    }

    // Check for max file count
    if (totalFilesCount > maxFiles) {
      if (!messageDisplayedAmountRelate) {
        message.error("最大20ファイルしかアップロードできません。");
        messageDisplayedAmountRelate = true;
      }
      filteredFiles = filteredFiles.slice(0, maxFiles - currentFileListCount);
    }
    const version = listVersion.length != 0 ? Number(listVersion[0]?.version) : 1
    const resultForFileList = filteredFiles.map(item => (
      {
        ...item,
        isAddNew: true,
        fileId: 0,
        rootPrefix: listVersion.length == 0 ? 1 : version + 1
      }
    ));
    setFileList((prevFileList) => {
      // Filter out files with duplicate names before adding new files
      const filteredPrevFileList = prevFileList.filter(
        prevFile => !resultForFileList.some(newFile => newFile.name === prevFile.name)
      );
      return [...filteredPrevFileList, ...resultForFileList];
    });
  };

  const onDropUploadAmountRelate = (value: any) => {

    ConfirmModalAsync({
      onOk: async () => {
        if (value.isAddNew) {
          const result = fileListAmountRelate.filter((item: any) => item.name != value.name)
          setFileListAmountRelate(result)
          SuccessNotification(NOTIFICATION_TITLE.SUCCESS);
        } else {
          await deleteFileCI(
            `construction/${summaryId}/cr_payment_detail/${value.rootPrefix}/${value.name}`
          )
          const result = fileListAmountRelate.filter((item: any) => item.name != value.name)
          setFileListAmountRelate(result)
        }
      },
      onCancel: () => { },
      title: "削除確認",
      className: "confirm__modal ",
      description: "このファイルを削除してもよろしいでしょうか？",
      canceText: "キャンセル",
      okText: "削除",
    });
  };

  const getPdfMapAmountRelate = async (value: any) => {
    const fileName = value.name?.split(".").slice(0, -1).join(".");
    dispatch(setFileName(fileName));
    if (value.path) {
      try {

        const paramFileVersion = {
          keyName: `construction/${summaryId}/cr_payment_detail/${value.rootPrefix}/${value.name}`
        };

        const response = await cr003Service.getPresignLinkS3(paramFileVersion);

        setPdfUrlAmountRelate(response.results);


        const checkFile = response.results
          ?.split(".")
          .pop()
          ?.split("?")
          .shift();

        if (checkFile === "pdf") return dispatch(showPdfViewerModal());
        dispatch(showImageViewerModal());
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      }
    }

    else {
      if (!value.url && !value.preview) {
        value.preview = await helpers.getBase64(value.originFileObj as RcFile);
      }
      setPdfUrlAmountRelate(value.url || (value.preview as string));
      if (value.type.includes("image/")) {
        dispatch(showImageViewerModal());
      } else {
        dispatch(showPdfViewerModal());
      }
    }
  };

  return {
    getPdfMapAmountRelate,
    handleViewFile,
    getCR003BasicInfo,
    updateCR003BasicInfo,
    onChangeInquiryCategorySelect,
    onChangeDecisionMethod,
    onClickCheckEdit,
    onChangeCurrentTab,
    onSubmitBasic,
    onChangeCheckBox,
    onCancelSubmitFormBasic,
    handleFormatPhone,
    getScreenMaster,
    resetScreen,
    onChangeForm,
    handleOpenDrawer,
    handleCloseDrawer,
    handleOpenNoteHistory,
    handleCloseNoteHistory,
    onChangeDetailHistoryNote,
    handleAddMaterialCost,
    handleDeleteMaterialCost,
    handleAddSubcontractCost,
    handleDeleteSubcontractCost,
    handleAddLaborCost,
    handleDeleteLaborCost,
    handleAddListIndirect,
    handleDeleteListIndirect,
    onCancelSubmitFormAmountRelate,
    handleCloseModalExternal,
    handleShowVersion,
    getListRelateMoney,
    updateRelateMoney,
    handleSubmitFormAmount,
    handleChangeFormAmount,
    checkFormatValue,
    checkFormatDate,
    getListVersion,
    handleKeepNote,
    handleFinishNote,
    handleChangeVersionNow,
    getApprovalProcess,
    postApprovalProcess,
    handleApproveCI,
    handleRejectCI,
    handleCancelApprove,
    checkAutApprove,
    handleOkApprove,
    handleChangeNoteApprove,
    handleWithDraw,
    handleCancelChooseCompany,
    handleConfirmChooseCompany,
    handleViewModalExternal,
    funcViewModalMailContractor,
    onClickBreadcrumb,
    onClickMenu,
    funcCheckDuplicate,
    handleAfterSendMail,
    handleResetError,
    funcCheckButtonSendMail,
    funcCheckEdit,
    funcCheckCr002,
    handleGetListMember,
    handleSearchOption,
    handleScrollMember,
    handleExportCR,
    getProjectForPermission,
    checkApproved,
    exportTemplate,
    handleOpenStepNoteHistory,
    switchTabVersion,
    onChangeUpload,
    onDropUpload,
    getPdfMap,
    getVersionData,
    afterClear,
    onChangeUploadAmountRelate,
    onDropUploadAmountRelate,

    fileListAmountRelate,
    screenMaster,
    dropDownBasicInfo,
    categoryValue,
    form,
    formReject,
    checkbox,
    currentTab,
    isEdit,
    isChangeEdit,
    decisionMethod,
    inquiryCategory,
    isRefresh,
    basicInfo,
    isOpenDrawer,
    isViewModalHistory,
    detailHistoryNote,
    materialCostList,
    subcontractCostList,
    laborCostList,
    listIndirect,
    isViewModalExternal,
    isEditNote,
    formAmount,
    listRelateMoney,
    estimateContractMoney,
    totalMaterialCost,
    totalSubContract,
    totalLaborList,
    totalDirectConstruction,
    totalIndirectCost,
    totalAll,
    valueCalculator,
    isRefreshAmount,
    listVersion,
    versionNew,
    loadingNote,
    isVersionNow,
    listApprovalProcess,
    currentUserApproval,
    isApprove,
    isApproveOrReject,
    noteApprove,
    refreshApprove,
    companyChange,
    consumptionRate,
    listTooltip,
    members,
    requiredMaterial,
    detailProject,
    listApprover,
    tabVersion,
    fileList,
    pdfUrl,
    listVersionApproval,
    descriptionPopup,
    titlePopup,
    pdfUrlAmountRelate,
    isSubmitted
  };
};

export default CR003Handler;
