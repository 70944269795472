import React, { useMemo } from "react";
import { CM0031Hanlder } from "../handler/HandlerImpl";
import IconDown from "../../../../../assets/icons/icon-down-small.svg";
import { Empty } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import helpers from "../../../../../common/helpers/common";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";

interface props {
  handler: CM0031Hanlder;
}

const NO_DATA = "データなし";

const Table = ({ handler }: props) => {
  const loading = useSelector((state: RootState) => state.common.loading);
  const contentTable = useMemo(() => {
    if (!handler.dataView || handler.dataView.length === 0)
      return !loading ? (
        <tr>
          <td colSpan={10}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={NO_DATA} />
          </td>
        </tr>
      ) : null;
    const elementTable: any = [];
    handler.dataView.forEach((element, index) => {
      elementTable.push(
        <React.Fragment key={element.key}>
          <tr style={{ height: 4 }}></tr>
          <tr
            className="row-level-0 button-style"
            onClick={() => handler.funcSetExpendKey(element.key)}
          >
            <td colSpan={2}>{index + 1}</td>
            <td colSpan={4} style={{ textAlign: "left", paddingLeft: 35 }}>
              <TooltipText content={element.name} />
            </td>
            <td>
              {Math.floor(element.excludingTax).toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
              円{" "}
            </td>
            <td>
              {Math.floor(element.consumptionTax).toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
              円{" "}
            </td>
            <td>
              {Math.floor(element.taxIncluded).toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
              円
            </td>
            <td
              style={{ textAlign: "center", marginTop: 13, padding: "15px 0" }}
            >
              <img
                alt=""
                src={IconDown}
                className={`cm031-icon ${
                  handler.funcCheckKeyExpand(element.key)
                    ? "cm031-icon-active"
                    : ""
                }`}
              />
            </td>
          </tr>
          <tr
            className="bg-fa"
            style={{
              display: handler.funcCheckKeyExpand(element.key)
                ? undefined
                : "none",
            }}
          >
            <td colSpan={2}></td>
            <td>工事名</td>
            <td>項目名</td>
            <td style={{ width: 280 }}>対象期間</td>
            <td>支払日</td>
            <td></td>
            <td> </td>
            <td></td>

            <td style={{ textAlign: "center", marginTop: 13 }}></td>
          </tr>
        </React.Fragment>
      );
      if (element.children && element.children.length > 0) {
        element.children.forEach((child1: any, idx: number) => {
          if (handler.funcCheckKeyExpand(element.key))
            elementTable.push(
              <tr key={`table${child1.key}`} className="bg-fa">
                <td></td>
                <td>{idx + 1}</td>
                <td>
                  <TooltipText content={child1.construction?.description} />
                </td>
                <td>
                  <TooltipText content={child1.item} />
                </td>
                <td>{`${
                  child1.startDate
                    ? moment(child1.startDate).format(COMMON.FORMAT_DATE_OM)
                    : ""
                } - ${
                  child1.endDate
                    ? moment(child1.endDate).format(COMMON.FORMAT_DATE_OM)
                    : ""
                }`}</td>
                <td>
                  {child1.paymentDate
                    ? moment(child1.paymentDate).format(COMMON.FORMAT_DATE_OM)
                    : ""}
                </td>
                <td>
                  {Math.floor(child1.excludingTax).toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })}
                  円{" "}
                </td>
                <td>
                  {Math.floor(child1.consumptionTax).toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })}
                  円{" "}
                </td>
                <td>
                  {Math.floor(child1.taxIncluded).toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })}
                  円
                </td>
                <td></td>
              </tr>
            );
        });
      } else {
        !loading &&
          elementTable.push(
            <tr
              key={`${element.key}nodata`}
              style={{
                display: handler.funcCheckKeyExpand(element.key)
                  ? undefined
                  : "none",
              }}
            >
              <td colSpan={10}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={NO_DATA}
                />
              </td>
            </tr>
          );
      }
    });
    return elementTable;
  }, [handler.dataView, handler.expandKey]);

  return (
    <div className="cm024-table" onScroll={handler.handleScrollTable}>
      <table className="cm0020-table">
        <thead>
          <tr>
            <th colSpan={2} style={{ width: 110 }}>
              No
            </th>
            <th style={{ width: 174, textAlign: "left", paddingLeft: 35 }}>
              案件名
            </th>
            <th style={{ width: 173 }} className="border-left-none"></th>
            <th style={{ width: 280 }} className="border-left-none"></th>
            <th style={{ width: 173 }} className="border-left-none"></th>
            <th>支払金額(税抜)</th>
            <th>消費税</th>
            <th>支払金額(税込)</th>
            <th style={{ width: 40 }}></th>
          </tr>
        </thead>
        <tbody>{contentTable}</tbody>
        <tfoot>
          <tr className="table-foot">
            <td colSpan={2}>合計金額</td>
            <td colSpan={4} style={{ textAlign: "left", paddingLeft: 35 }}></td>
            <td>
              {handler.summary.excludingTax.toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
              円
            </td>
            <td>
              {handler.summary.consumptionTax.toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
              円
            </td>
            <td>
              {handler.summary.taxIncluded.toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
              円
            </td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Table;
