import { Button, Card, Collapse, Form, List } from "antd";
import { memo, useMemo } from "react";
import "moment/locale/ja";

import EditWhiteSvg from "../../../../../assets/icons/edit-white.svg";
import TimeCircle from "../../../../../assets/icons/time-circle.svg";
import TimeCircleWhite from "../../../../../assets/icons/time-circle-white.svg";
import EmptyData from "../../../../../assets/icons/empty-data.svg";

import { CR001AmountRelateHandler } from "../handler/HandlerImpl";
import CR001ModalHistory from "./CR001ModalHistory";
import { useParams, useSearchParams } from "react-router-dom";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import CR002Component from "../../../CR002";
import Estimate from "./Estimate";
import Material from "./Material";
import Subcontract from "./Subcontract";
import Indirect from "./Indirect";
import Labor from "./Labor";
import helpers from "../../../../../common/helpers/common";
import COMMON, { USER_FLOW } from "../../../../../common/constants/COMMON";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import { PRIVILEGE_PM } from "../../../../../common/constants/PRIVILEGE";
import { AUTHORIZATION_CODE } from "../../../../../common/constants/AUTHORIZATION";
import moment from "moment";
import IconDelete from "../../../../../assets/icons/icon-trash-red.svg";
import IconFile from "../../../../../assets/icons/ant-design_file-pdf-filled.svg";
import CloudUpIcon from "../../../../../assets/icons/cloud-up-icon.svg";
import {
  DEFAULT_PERCENT,
  MESSAGE_REQUIRED,
  PARAMS,
  REQUIRED_INPUT,
  VERSION_APPROVAL,
} from "../CONSTANT";
import CR001Drawer from "./CR001Drawer";
import Dragger from "antd/lib/upload/Dragger";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";
import { STATUS_APPROVE } from "../../../../PM/PM005/presenter/handler/Handler";

const { Panel } = Collapse;
const { Item } = Form;

interface props {
  handler: CR001AmountRelateHandler;
}

function CR003AmountRelate({ handler }: props) {
  const { projectManagerId } = useParams();
  const isViewChooseCompany = useSelector(
    (state: RootState) => state.cr002.isViewChooseCompany
  );
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const versionType = searchParams.get(PARAMS.VERSION_TYPE) || "";
  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const versionId = searchParams.get(PARAMS.VERSION_ID) || "";

  const checkBtn = useMemo(() => {
    return helpers.checkButtonWithWorkflowRolePM(
      handler.currentUserApproval,
      handler.listApprovalProcess,
      +(projectManagerId ?? 0)
    );
  }, [
    handler.currentUserApproval,
    handler.listApprovalProcess,
    projectManagerId,
  ]);

  const dummyRequest = (value: any) => {
    setTimeout(() => {
      value.onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <Form
        name="amountRelate"
        form={handler.formAmount}
        onFinish={(value: any) =>
          handler.handleSubmitFormAmount(
            value,
            handler.listRelateMoney?.documentId
          )
        }
        onKeyDown={helpers.preventEnterForm}
        onValuesChange={handler.handleChangeFormAmount}
      >
        <div className="ci001-basic cr001 pb-24">
          {handler.listRelateMoney ? (
            <>
              <div className="cr001-header">
                <h2 className="cr001-header-label">
                  {handler.listVersion?.length > 0 ? (
                    versionType !== VERSION_APPROVAL ? (
                      <div style={{ display: "flex" }}>
                        <section style={{ marginRight: 24 }}>
                          編集履歴 {handler.versionNew.versionView}
                        </section>
                        <section>
                          {handler.listRelateMoney?.documentHistory?.createdAt
                            ? moment(
                                handler.listRelateMoney?.documentHistory
                                  ?.createdAt
                              ).format(COMMON.FORMAT_DATE)
                            : ""}
                        </section>
                      </div>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <section style={{ marginRight: 24 }}>
                          承認履歴 {handler.versionNew.versionView}
                        </section>
                        <section>
                          {handler.listRelateMoney?.documentHistory?.approvedAt
                            ? moment(
                                handler.listRelateMoney?.documentHistory
                                  ?.approvedAt
                              ).format(COMMON.FORMAT_DATE)
                            : ""}
                        </section>
                      </div>
                    )
                  ) : null}
                </h2>
                <div className="cr001-header-btn">
                  {(checkBtn === USER_FLOW.MANAGER_SUBMIT ||
                    isHasPrivilege(handler.detailProject, [PRIVILEGE_PM]) ||
                    isRoleAdmin ||
                    isRoleAdminSystem) &&
                  !handler.isEdit &&
                  // handler.checkApproved(handler.listApprovalProcess) &&
                  handler.listRelateMoney?.latestVersionStatus?.code ===
                    STATUS_APPROVE.not_submit.code ? (
                    <>
                      {(handler.listApprover.length == 1 &&
                        handler.listApprover.every(
                          (element: any) => element.isApprove === true
                        )) ||
                      versionType === VERSION_APPROVAL ? (
                        <></>
                      ) : (
                        <>
                          <Button
                            size="large"
                            className="button-purple"
                            onClick={() => handler.onClickCheckEdit(true)}
                            icon={
                              <img
                                style={{
                                  paddingRight: 12,
                                  paddingLeft: 2,
                                }}
                                alt=""
                                src={EditWhiteSvg}
                              />
                            }
                          >
                            編集
                          </Button>
                        </>
                      )}
                    </>
                  ) : null}
                  {((permission?.includes(AUTHORIZATION_CODE.S0) &&
                    permission?.includes(AUTHORIZATION_CODE.S18)) ||
                    permission?.includes(AUTHORIZATION_CODE.B10) ||
                    checkBtn === USER_FLOW.MANAGER_SUBMIT ||
                    checkBtn === USER_FLOW.MANAGER_FLOW_SUBMITTED ||
                    checkBtn === USER_FLOW.MANAGER_SUBMITTED ||
                    checkBtn === USER_FLOW.FLOW_SUBMITTED ||
                    isHasPrivilege(handler.detailProject, [PRIVILEGE_PM]) ||
                    isRoleAdmin ||
                    isRoleAdminSystem) &&
                  !handler.isEdit ? (
                    //  &&
                    // handler.checkApproved(handler.listApprovalProcess)

                    <>
                      {handler.listVersion?.length +
                        handler.listVersionApproval.length >
                      1 ? (
                        <Button
                          size="large"
                          className={
                            versionId
                              ? "cr001-header-btn-history"
                              : "button-purple"
                          }
                          onClick={handler.handleOpenDrawer}
                          style={{
                            marginLeft: 16,
                          }}
                          icon={
                            <img
                              style={{
                                paddingRight: 12,
                                paddingLeft: 2,
                              }}
                              alt=""
                              src={versionId ? TimeCircle : TimeCircleWhite}
                            />
                          }
                        >
                          履歴を表示
                        </Button>
                      ) : null}
                      {!handler.isVersionNow && (
                        <Button
                          size="large"
                          className="button-purple"
                          style={{
                            marginLeft: 24,
                          }}
                          onClick={handler.handleChangeVersionNow}
                        >
                          最新履歴
                        </Button>
                      )}
                    </>
                  ) : null}
                </div>
              </div>

              <div className="cr001-body cr-color">
                <div className="cr001-scroll">
                  <Collapse
                    className="cr001-collapse folder-collapse"
                    defaultActiveKey={[
                      "contract-amount",
                      "construction-cost",
                      "list-indirect",
                    ]}
                  >
                    <Panel
                      key="contract-amount"
                      header="見積・契約金額"
                      className="cr001-contract-amount-panel cr-text-header-collapse"
                    >
                      <Estimate handler={handler} />
                      <div className="cr001-contract-amount-content-label">
                        <div className="cr001-contract-amount-content-label-left">
                          ファイル
                        </div>
                      </div>
                      <div
                        style={{
                          width: "1447px",
                          paddingLeft: "32px",
                          margin:
                            handler.isEdit ||
                            handler.fileListAmountRelate.length != 0
                              ? "8px 0 0 22px"
                              : 0,
                        }}
                      >
                        {!handler.isEdit &&
                        handler.fileListAmountRelate.length == 0 ? (
                          <></>
                        ) : (
                          <Card
                            bodyStyle={{
                              padding: "0 12px 12px 12px",
                              width: "1423px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              background: "#fafafa",
                            }}
                            bordered={true}
                            style={{ border: "1px dashed #DAE2EE" }}
                          >
                            {handler.isEdit && (
                              <Dragger
                                customRequest={dummyRequest}
                                multiple={true}
                                fileList={[]}
                                onChange={handler.onChangeUploadAmountRelate}
                                onRemove={handler.onDropUploadAmountRelate}
                                iconRender={() => <img alt="" src={IconFile} />}
                                style={{ maxWidth: "1423px", border: "none" }}
                                disabled={
                                  handler.fileListAmountRelate.length >= 20
                                }
                                accept="application/pdf, image/png, image/jpeg"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p className="ant-upload-drag-icon">
                                    <img alt="" src={CloudUpIcon} />
                                  </p>
                                  <p
                                    className="ci-upload-text"
                                    style={{
                                      width: 424,
                                      textAlign: "center",
                                    }}
                                  >
                                    ここにファイルをドラッグ・ドロップ (.pdf
                                    または .png/.jpeg/.jpg)
                                  </p>
                                  <div className="ci-upload-text">
                                    （各ファイルサイズ：8MB）
                                  </div>
                                  <div className="text-gray">または</div>
                                  <Button
                                    className="btn-upload-custom"
                                    style={{
                                      borderRadius: "3px",
                                      padding: "0 24px",
                                      width: "fit-content",
                                      border: "solid 1px #DAE2EE",
                                      marginTop: 5,
                                    }}
                                    disabled={
                                      handler.fileListAmountRelate.length >= 20
                                    }
                                  >
                                    <span
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 700,
                                        color: "#605BFF",
                                      }}
                                    >
                                      ファイルを選択する
                                    </span>
                                    <span
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: "#605BFF",
                                      }}
                                    >
                                      (.pdf または .png/.jpeg/.jpg)
                                    </span>
                                  </Button>
                                </div>
                              </Dragger>
                            )}

                            {handler.fileListAmountRelate.length > 0 && (
                              <div style={{ width: "100%", padding: "0,16px" }}>
                                <List
                                  style={{
                                    marginTop: 16,
                                    maxWidth: "100%",
                                    fontSize: 14,
                                    fontWeight: 700,
                                    color: "#605BFF",
                                    border: "1px solid #DAE2EE",
                                    maxHeight: 250,
                                    overflowY: "auto",
                                  }}
                                  bordered
                                  dataSource={handler.fileListAmountRelate}
                                  renderItem={(item: any) => (
                                    <List.Item
                                      style={{
                                        color: "#605BFF",
                                        maxWidth: "1397px",
                                        overflowX: "hidden",
                                      }}
                                    >
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div
                                          onClick={() =>
                                            handler.getPdfMapAmountRelate(item)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            width: handler.isEdit
                                              ? "97%"
                                              : "100%",
                                          }}
                                        >
                                          <TooltipText
                                            isLarge
                                            content={item.name}
                                            colorText="#605BFF"
                                          />
                                        </div>
                                        {handler.isEdit && (
                                          <img
                                            style={{ cursor: "pointer" }}
                                            src={IconDelete}
                                            onClick={() =>
                                              handler.onDropUploadAmountRelate(
                                                item
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </List.Item>
                                  )}
                                />
                              </div>
                            )}
                          </Card>
                        )}
                      </div>
                    </Panel>

                    <Panel
                      key="construction-cost"
                      header="直接工事費一覧"
                      className="cr001-contract-amount-panel cr-text-header-collapse cr001-construction-cost-panel"
                    >
                      <div className="cr001-construction-cost-item bg-header-row">
                        <div className="cr001-construction-cost-item-child cr-7"></div>
                        <div className="cr001-construction-cost-item-child cr-4-other color-gray">
                          見積金額（円）
                        </div>
                        <div className="cr001-construction-cost-item-child cr-4-other">
                          原価金額（円）
                        </div>
                        <div className="cr001-construction-cost-item-child cr-4-other">
                          予算金額（円）
                        </div>
                        <div className="cr001-construction-cost-item-child cr-4-other">
                          発注金額（円）
                        </div>
                      </div>
                      <div className="cr001-construction-cost-item">
                        <div className="cr001-construction-cost-item-child cr-7">
                          合計金額
                        </div>
                        <div className="cr001-construction-cost-item-child cr-4-other color-gray">
                          {handler.checkFormatValue(
                            handler.totalDirectConstruction?.estimateMoney,
                            false,
                            true
                          )}
                        </div>
                        <div className="cr001-construction-cost-item-child cr-4-other color-gray">
                          {handler.checkFormatValue(
                            handler.totalDirectConstruction?.costMoney,
                            false,
                            true
                          )}
                        </div>
                        <div className="cr001-construction-cost-item-child cr-4-other color-gray">
                          {handler.checkFormatValue(
                            handler.totalDirectConstruction?.budgetMoney,
                            false,
                            true
                          )}{" "}
                          {handler.checkFormatValue(
                            handler.totalDirectConstruction?.budgetMoneyPercent,
                            true,
                            true
                          )}
                        </div>
                        <div className="cr001-construction-cost-item-child cr-4-other color-gray">
                          {handler.checkFormatValue(
                            handler.totalDirectConstruction?.orderMoney,
                            false,
                            true
                          )}{" "}
                          {handler.checkFormatValue(
                            handler.totalDirectConstruction?.orderMoneyPercent,
                            true,
                            true
                          )}
                        </div>
                      </div>
                      <Material handler={handler} />
                      <Subcontract handler={handler} />
                      <Labor handler={handler} />
                    </Panel>
                    <Panel
                      key="list-indirect"
                      header="間接工事費一覧"
                      className="cr001-contract-amount-panel cr-text-header-collapse cr001-construction-cost-panel"
                    >
                      <Indirect handler={handler} />
                    </Panel>
                  </Collapse>
                  <div className="cr001-other-total">
                    <div className="cr001-cost-registration-title bg-title-row padding-tb-15 border-bottom">
                      <span className="text-bold-14">粗利</span>
                    </div>
                    <div className="cr001-cr-item bg-header-row">
                      <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15">
                        予想粗利金額（円）
                      </div>
                      <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15">
                        粗利率
                      </div>
                    </div>
                    <div className="cr001-cr-item ">
                      <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15 color-gray">
                        {handler.checkFormatValue(
                          handler.valueCalculator?.profitAmount,
                          false,
                          true
                        )}
                      </div>
                      <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15 color-gray">
                        {handler.valueCalculator?.grossProfit ||
                        handler.valueCalculator?.grossProfit === 0
                          ? `${handler.valueCalculator?.grossProfit.toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 2,
                              }
                            )}%`
                          : `${DEFAULT_PERCENT} %`}{" "}
                      </div>
                    </div>
                    <div className="cr001-cost-registration-title bg-title-row padding-tb-15 border-bottom">
                      <span className="text-bold-14">総計</span>
                    </div>
                    <div className="cr001-cr-item bg-header-row">
                      <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
                        見積金額（円）
                      </div>
                      <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
                        原価金額（円）
                      </div>
                      <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
                        予算金額（円）
                      </div>
                      <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
                        発注金額（円）
                      </div>
                    </div>
                    <div className="cr001-cr-item ">
                      <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15 color-gray">
                        {handler.checkFormatValue(
                          handler.totalAll?.estimateMoney,
                          false,
                          true
                        )}
                      </div>
                      <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15 color-gray">
                        {handler.checkFormatValue(
                          handler.totalAll?.costMoney,
                          false,
                          true
                        )}
                      </div>
                      <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15 color-gray">
                        {handler.checkFormatValue(
                          handler.totalAll?.budgetMoney,
                          false,
                          true
                        )}{" "}
                        {handler.checkFormatValue(
                          handler.totalAll?.budgetMoneyPercent,
                          true,
                          true
                        )}
                      </div>
                      <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15 color-gray">
                        {handler.checkFormatValue(
                          handler.totalAll?.orderMoney,
                          false,
                          true
                        )}{" "}
                        {handler.checkFormatValue(
                          handler.totalAll?.orderMoneyPercent,
                          true,
                          true
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="data-empty" style={{ height: "56vh" }}>
              <img src={EmptyData} alt="" />
            </div>
          )}
        </div>
        {handler.isEdit && (
          <div className="ci001-form-submit">
            <Item
              rules={[
                {
                  required: REQUIRED_INPUT,
                  message: MESSAGE_REQUIRED,
                },
              ]}
            >
              <Button
                size="large"
                className="button-purple"
                htmlType="submit"
                style={{ marginRight: 16, letterSpacing: -1 }}
              >
                保存
              </Button>
            </Item>
            <Button
              size="large"
              onClick={handler.onCancelSubmitFormAmountRelate}
            >
              キャンセル
            </Button>
          </div>
        )}
      </Form>
      {handler.isOpenDrawer && <CR001Drawer handler={handler} />}
      {handler.isViewModalHistory && <CR001ModalHistory handler={handler} />}
      {isViewChooseCompany && (
        <CR002Component
          handleCancel={handler.handleCancelChooseCompany}
          handleConfirm={handler.handleConfirmChooseCompany}
        />
      )}
    </>
  );
}

export default CR003AmountRelate;
