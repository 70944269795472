import React, { useEffect } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import { Button, Spin, Modal, Row, Col } from "antd";
import TableAboveComponent from "./TableAbove";
import TableBelowComponent from "./TableBelow";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";

import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import IconCircleCheck from "../../../../../assets/icons/akar-icons_circle-check-fill.svg";
import CM017_2Component from "../../../CM017_2";
import CM0012_2Component from "../../../CM0012_2";
import CM0017_3_1Component from "../../../CM0017_3_1";
import CM0017_3_2Component from "../../../CM0017_3_2";
import ApprovalFlow from "../../../../../common/components/approval-flow/ApprovalFlow";
import helpers from "../../../../../common/helpers/common";
import ApproveConfirmModal from "../../../../../common/components/modal-approve-confirm";
import useWindowDimensions from "../../../../../common/helpers/windowDimension";
interface props {
  handler: HandlerImpl;
  disableSubmitOrReject?: boolean;
  afterSuccess?: () => void;
  onSubmit?: () => void;
  onReject?: () => void;
  hasWorkflow?: boolean;
}

const MainUI = ({
  handler,
  disableSubmitOrReject,
  hasWorkflow,
  afterSuccess,
  onSubmit,
  onReject,
}: props) => {
  const documentId = useSelector(
    (state: RootState) => state.cm0017_3.documentId
  );
  const documentInfo = useSelector(
    (state: RootState) => state.cm0017_3.documentInfo
  );
  const enableRetrieveButton = useSelector(
    (state: RootState) => state.cm0017_3.enableRetrieveButton
  );
  const hideRetrieveButton = useSelector(
    (state: RootState) => state.cm0017_3.hideRetrieveButton
  );
  const isVisibleCM0017_3_1 = useSelector(
    (state: RootState) => state.cm0017_3_1.isVisible
  );
  const isVisibleCM0017_3_2 = useSelector(
    (state: RootState) => state.cm0017_3_2.isVisible
  );
  const isVisibleCM0017_2 = useSelector(
    (state: RootState) => state.cm017_2.isVisible
  );
  const { width } = useWindowDimensions();

  useEffect(() => {
    Promise.all([handler.getTax(), handler.handleGetSubCategory()]);
  }, []);

  useEffect(() => {
    if (documentId)
      Promise.all([
        handler.getReSummarySubmittedCompanyOutsourceCost({
          documentId,
        }),
        handler.getReSummarySubmittedCompanyOutsourceCostTotal({
          documentId,
        }),
        hasWorkflow
          ? handler.getWorkflowSummaryCompanyOutsourceApprovalProcess(
              documentId
            )
          : null,
      ]);
  }, [documentId]);

  useEffect(() => {
    if (documentId)
      Promise.all([
        handler.getReSummarySubmittedCompanyOutsourceCostDetail({
          documentId,
        }),
      ]);
  }, [documentId]);
  return (
    <Modal
      className="group-detail-modal modal-fixed-1572"
      style={{
        marginLeft: width > 1872 ? 300 : 0,
        marginTop: 40,
        marginBottom: 40,
      }}
      visible={documentId ? true : false}
      closable={false}
      maskClosable={false}
      width={1572}
      centered={width > 1700}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {onSubmit && onReject ? (
            <>
              {/* DONE */}
              <ApproveConfirmModal onSubmit={onSubmit} />
              <Button
                className="button-purple cm047-btn-submit"
                icon={<img alt="" src={IconCircleCheck} />}
                size="large"
                onClick={handler.showConfirmApproveModal}
                disabled={
                  disableSubmitOrReject ||
                  !(
                    handler.currentUserApproval &&
                    helpers.checkApproveInWorkFlow(handler.currentUserApproval)
                  )
                }
                style={{ marginLeft: 16 }}
              >
                承認
              </Button>
              <Button
                size="large"
                className="button-warning cm047-btn-reject"
                onClick={onReject}
                disabled={
                  disableSubmitOrReject ||
                  !(
                    handler.currentUserApproval &&
                    helpers.checkApproveInWorkFlow(handler.currentUserApproval)
                  )
                }
              >
                否認
              </Button>
            </>
          ) : null}
          {!hideRetrieveButton ? (
            <Button
              disabled={enableRetrieveButton}
              size="large"
              type="primary"
              style={{ marginLeft: 16, width: 120 }}
              onClick={() =>
                documentId
                  ? handler.putReSummaryCompanySummaryOutsourceCostRetrieve(
                      {
                        documentId,
                      },
                      afterSuccess
                    )
                  : null
              }
              className="button-purple cm047-btn-submit"
            >
              取り下げ
            </Button>
          ) : null}

          <Button
            size="large"
            style={{ marginLeft: 16, width: 118 }}
            onClick={handler.onCancel}
          >
            キャンセル
          </Button>
        </div>
      }
    >
      <Spin indicator={LoadingComponent} spinning={handler.loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 "> 工事代金支払明細書</div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        {hasWorkflow ? (
          <div style={{ marginTop: 24, marginBottom: 8 }}>
            <ApprovalFlow
              isNotCheckingUser={true}
              breadcrumb={[]}
              listApprove={handler.workflow}
              currentUserApproval={handler.currentUserApproval}
            />
          </div>
        ) : null}
        <div
          style={{ margin: "24px 24px 24px 24px", backgroundColor: "#ffffff" }}
        >
          <Row style={{ marginBottom: 24 }}>
            <Col span={8} style={{ display: "flex" }}>
              <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                資料名
              </div>
              <div className="CM0017_3-info-content">
                {documentInfo.documentTitle}
              </div>
            </Col>
            <Col span={8} style={{ display: "flex" }}>
              <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                作成日
              </div>
              <div className="CM0017_3-info-content">
                {documentInfo.createdDate}
              </div>
            </Col>
            <Col span={8} style={{ display: "flex" }}>
              <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                対象期間
              </div>
              <div className="CM0017_3-info-content">
                {documentInfo.targetPeriod}
              </div>
            </Col>
          </Row>
          <TableAboveComponent
            funcViewModalCM017_1={handler.funcViewModalCM017_1}
            openCM0017_2={handler.openCM0017_2}
            summaryCost={handler.summaryCost}
            paymentMonth={documentInfo.paymentMonth}
            summaryTotal={handler.summaryTotal}
            taxSetting={handler.taxSetting}
          />
          <div style={{ height: 4 }}></div>
          <TableBelowComponent
            data={handler.costDetail?.results ?? []}
            openCM0012_2={handler.openCM0012_2}
            summaryTotal={handler.summaryTotal}
            targetPeriod={documentInfo.targetPeriod}
            companyName={documentInfo.documentTitle}
          />
        </div>
        {isVisibleCM0017_3_1 ? <CM0017_3_1Component /> : <></>}
        {isVisibleCM0017_3_2 ? <CM0017_3_2Component /> : <></>}
        {isVisibleCM0017_2 ? <CM017_2Component /> : <></>}
      </Spin>
    </Modal>
  );
};

export default MainUI;
