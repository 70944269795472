// Core library
import { useState } from "react";

// Redux
import { useDispatch } from "react-redux";

// Services
import { CM0017_3ServiceImpl } from "./../../usecase/ServiceImpl";

// Common
import COMMON from "../../../../../common/constants/COMMON";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { Form } from "antd";
import {
  ReSummaryCompanySummaryOutsourceCostDetail,
  ReSummaryCompanySummaryOutsourceCostDetailResult,
  ReSummaryCompanySummaryOutsourceCostTotalResult,
} from "../../../CM0017/entity/Entity";
import { resetCM0017_3, setDocumentIdCM0017_3 } from "../slice/Slice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import {
  setIsUpdate,
  setIsVisibleCM017_2,
  setParentCodeCM017_2,
} from "../../../CM017_2/presenter/slice/Slice";
import { ReSummarySubmittedCompanyOutsourceCostResults } from "../../entity/Entity";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import {
  setHeaderDataCM0017_3_1,
  setIsVisibleCM0017_3_1,
  setParentCodeCM0017_3_1,
} from "../../../CM0017_3_1/presenter/slice/Slice";
import {
  setIsVisibleCM0017_3_2,
  setParentCodeCM0017_3_2,
} from "../../../CM0017_3_2/presenter/slice/Slice";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import { SubCategory } from "../../../CM0024/entity/Entity";
import helpers from "../../../../../common/helpers/common";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";

const CM0017_3Handler = (service: CM0017_3ServiceImpl) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [costDetail, setCostDetail] =
    useState<ReSummaryCompanySummaryOutsourceCostDetail>();
  const [summaryTotal, setSummaryTotal] =
    useState<ReSummaryCompanySummaryOutsourceCostTotalResult>({
      actualCost: 0,
      consumptionTax: 0,
      cost: 0,
      deductionCost: 0,
      offsetCostWithTax: 0,
      offsetCostWithoutTax: 0,
      paidCost: 0,
      planCost: 0,
      reserveCost: 0,
      tax: 0,
      orderMoney: 0,
    });
  const [summaryCost, setSummaryCost] =
    useState<ReSummarySubmittedCompanyOutsourceCostResults>();
  const [loading, setLoading] = useState<boolean>(false);
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);
  const [subCategory, setSubCategory] = useState<SubCategory[]>([]);
  const [taxSetting, setTaxSetting] = useState<number>(0);

  const getWorkflowSummaryCompanyOutsourceApprovalProcess = async (
    documentId: number
  ) => {
    try {
      setLoading(true);
      const data =
        await service.getWorkflowSummaryCompanyOutsourceApprovalProcess({
          documentId,
        });
      if (data.results.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          fullName: data.results.currentUserApproval.fullName,
          note: data.results.currentUserApproval.note,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            fullName: element.fullName,
            note: note,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };
  const onCancel = () => {
    dispatch(setDocumentIdCM0017_3(null));
  };

  const getReSummarySubmittedCompanyOutsourceCostDetail = async (params: {
    documentId: number;
  }): Promise<any> => {
    try {
      setLoading(true);
      const responseData =
        await service.getReSummarySubmittedCompanyOutsourceCostDetail({
          ...params,
          page: 1,
          size: COMMON.DEFAULT_SIZE,
        });
      let finalResult: ReSummaryCompanySummaryOutsourceCostDetailResult[] = [];
      const numPages = responseData?.pagination?.numPages ?? 1;
      finalResult = finalResult.concat(responseData.results);
      for (let i = 2; i <= numPages; i++) {
        const responseData2 =
          await service.getReSummarySubmittedCompanyOutsourceCostDetail({
            ...params,
            page: i,
            size: COMMON.DEFAULT_SIZE,
          });
        finalResult = finalResult.concat(responseData2.results);
      }
      setCostDetail({
        pagination: responseData?.pagination,
        results: finalResult,
      });
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const getReSummarySubmittedCompanyOutsourceCostTotal = async (params: {
    documentId: number;
  }): Promise<any> => {
    try {
      setLoading(true);
      const responseData =
        await service.getReSummarySubmittedCompanyOutsourceCostTotal(params);
      if (responseData) setSummaryTotal(responseData);
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const getReSummarySubmittedCompanyOutsourceCost = async (params: {
    documentId: number;
  }): Promise<any> => {
    try {
      setLoading(true);
      const responseData =
        await service.getReSummarySubmittedCompanyOutsourceCost(params);
      setSummaryCost(responseData);
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const putReSummaryCompanySummaryOutsourceCostRetrieve = async (
    params: {
      documentId: number;
    },
    afterSuccess?: () => void
  ) => {
    ConfirmModal({
      onOk: async () => {
        try {
          setLoading(true);
          const response =
            await service.putReSummaryCompanySummaryOutsourceCostRetrieve({
              documentId: params.documentId,
            });
          if (response) {
            if (afterSuccess) afterSuccess();
            dispatch(resetCM0017_3());
            SuccessNotification(response.message ?? NOTIFICATION_TITLE.SUCCESS);
          }
        } catch (error: any) {
          ErrorNotification(NOTIFICATION_TITLE.ERROR);
        } finally {
          setLoading(false);
        }
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_15_1,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const openCM0017_2 = () => {
    dispatch(setIsVisibleCM0017_3_2(true));
    dispatch(
      setParentCodeCM0017_3_2({
        categoryId: null,
        documentOutsourceCostDataSummaryId: Number(summaryCost?.id),
      })
    );
    dispatch(setIsUpdate(false));
  };

  const openCM0012_2 = (value: any) => {
    dispatch(setIsVisibleCM0017_3_1(true));
    dispatch(
      setParentCodeCM0017_3_1({
        categoryId: value.categoryId,
        documentOutsourceCostDataInformationSummaryId: value.id,
      })
    );
    dispatch(
      setHeaderDataCM0017_3_1({
        traderName: value?.companyName ?? "",
        contractWorkName: value?.construction?.name ?? "",
        targetPeriod: value?.targetPeriod ?? "",
      })
    );
  };

  const handleFormatSubCategory = (data: any) => {
    const arr: SubCategory[] = data.map((item: any) => {
      return {
        label: item.description,
        value: item.id,
        code: item.code,
      };
    });
    return arr;
  };

  const handleGetSubCategory = async (): Promise<any> => {
    try {
      const res = await service.getSubCategory();
      if (res?.results) {
        setSubCategory(handleFormatSubCategory(res.results));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const convertCodeToCategoryId = (code: string) => {
    const res = subCategory?.find((item: SubCategory) => {
      return item.code === code;
    });
    return res?.value;
  };
  const funcViewModalCM017_1 = (value: {
    companyId?: number;
    colabRefId?: number;
    code: string;
    from?: string;
    to?: string;
  }) => {
    const categoryId = convertCodeToCategoryId(value.code);
    dispatch(setIsVisibleCM017_2(true));
    dispatch(setIsUpdate(false));
    dispatch(
      setParentCodeCM017_2({
        companyId: value.companyId,
        colabRefId: value.colabRefId,
        categoryId,
        from: value.from ? helpers.getFilterTimeMonth(value.from) : undefined,
        to: value.to ? helpers.getFilterTimeMonthTo(value.to) : undefined,
      })
    );
  };
  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  const getTax = async () => {
    try {
      setLoading(true)
      const response = await service.getTaxSetting({});
      setTaxSetting(response?.results?.taxSetting ?? 0);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false)
    }
  };
  return {
    form,
    costDetail,
    summaryTotal,
    summaryCost,
    loading,
    workflow,
    currentUserApproval,
    taxSetting,
    openCM0012_2,
    openCM0017_2,
    getReSummarySubmittedCompanyOutsourceCostDetail,
    getReSummarySubmittedCompanyOutsourceCostTotal,
    getReSummarySubmittedCompanyOutsourceCost,
    putReSummaryCompanySummaryOutsourceCostRetrieve,
    onCancel,
    getWorkflowSummaryCompanyOutsourceApprovalProcess,
    handleGetSubCategory,
    funcViewModalCM017_1,
    showConfirmApproveModal,
    getTax
  };
};

export default CM0017_3Handler;
