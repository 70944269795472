import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";

// Image
import HomeSvg from "../../../../../assets/icons/home.svg";

// CSS
import "../../../../../assets/styles/SM/SM01.css";

import ScheduleComponent from "./Schedule";
import { SM01HandlerImpl } from "../handler/HandlerImpl";
import COMMON from "../../../../../common/constants/COMMON";
import SM03Component from "../../../SM03";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import React from "react";
import SM04Component from "../../../SM04";
import { useSearchParams } from "react-router-dom";
import { MODE, PARAMS_KEY } from "../handler/Handler";
import moment from "moment";
import helpers from "../../../../../common/helpers/common";
import CalendarFilter from "./FilterCalendar";
import SM010Component from "../../../SM010";

const BREADCRUMB_SM01 = [
  {
    title: "スケジュール",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
];

type props = {
  handler: SM01HandlerImpl;
};

const MainUI = ({ handler }: props) => {
  const [searchParams] = useSearchParams();
  const visibleSm003 = useSelector((state: RootState) => state.sm03.isVisible);
  const visibleSM04 = useSelector((state: RootState) => state.sm04.isVisible);
  const visibleSM010 = useSelector((state: RootState) => state.sm010.isVisible);

  const refresh = useSelector((state: RootState) => state.sm01.refresh);
  const mode = searchParams.get(PARAMS_KEY.MODE) ?? MODE.WEEK.CODE;
  const filterDate =
    searchParams.get(PARAMS_KEY.FILTER_DATE) ??
    `${moment().format(COMMON.FORMAT_DATE2)}`;
  const filterDateCalendar =
    searchParams.get(PARAMS_KEY.FILTER_DATE_CALENDAR) ??
    `${moment().format(COMMON.FORMAT_DATE2)}`;
  const listMember = searchParams.get(PARAMS_KEY.LIST_MEMBER_CHOOSE) ?? "";
  const refreshNotification = useSelector(
    (state: RootState) => state.common.refreshNotification
  );
  React.useEffect(() => {
    handler.initiatingMemberData();
  }, []);

  React.useEffect(() => {
    let from, to;

    switch (mode) {
      case MODE.MONTH.CODE:
        from = moment(filterDate).startOf("month").format(COMMON.FORMAT_DATE2);
        to = moment(filterDate).endOf("month").format(COMMON.FORMAT_DATE2);
        break;
      case MODE.WEEK.CODE:
        from = moment(filterDate).startOf("week").format(COMMON.FORMAT_DATE2);
        to = moment(filterDate).endOf("week").format(COMMON.FORMAT_DATE2);
        break;
      default:
        from = moment(filterDate).startOf("day").format(COMMON.FORMAT_DATE2);
        to = moment(filterDate).endOf("day").format(COMMON.FORMAT_DATE2);
        break;
    }

    if (from && to)
      handler.getListEvents(
        listMember,
        helpers.getFilterTime(from),
        helpers.getFilterTimeTo(to),
        filterDate,
        mode
      );
  }, [
    filterDate,
    handler.valueMember.length,
    refresh,
    mode,
    listMember,
    refreshNotification,
  ]);

  return (
    <MenuWrapperComponent chosenKey={3}>
      <BreadcrumbComponent data={BREADCRUMB_SM01} />
      <div className="sm01-wrapper">
        <div>
          <h1>スケジュール</h1>
        </div>
        <div className="sm01-content">
          <CalendarFilter handler={handler} />
          <ScheduleComponent
            handler={handler}
            filterDate={filterDate}
            filterDateCalendar={filterDateCalendar}
            dateCheck={filterDate}
            mode={mode}
          />
        </div>

        {visibleSm003 ? <SM03Component /> : null}
        {visibleSM04 ? <SM04Component /> : null}
        {visibleSM010 ? <SM010Component /> : null}
      </div>
    </MenuWrapperComponent>
  );
};

export default MainUI;
