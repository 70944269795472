import moment from "moment";
import {
  ChildrenDataEntity,
  DataTotalSummaryEntity,
  SummaryDataEntity,
  TableDataEntity,
} from "./entity/Entity";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../common/constants/COMMON";
import helpers from "../../../common/helpers/common";

const convertSummaryTable = (data: any): SummaryDataEntity => {
  let thresholdValue = "0";
  if (data.contractAmount !== 0) {
    thresholdValue = `${Math.round(((data.billingVolume * 100) / data.contractAmount) * 100) / 100
      } %`;
  } else {
    thresholdValue = "N/A";
  }

  return {
    contractAmount: `${helpers.formatCurrency(data.contractAmount)}円`,
    billingVolume: `${helpers.formatCurrency(data.billingVolume)}円`,
    threshold: thresholdValue,
    rateValue: `${helpers.formatCurrency(
      data.contractAmount - data.billingVolume
    )}円`,
  };
};

const formatDateStr = (dateString: string): string => {
  if (!dateString) return "";

  return moment(dateString).format(COMMON.FORMAT_DATE_CI);
};

const showSignRangeDate = (startDateStr: string, enDateStr: string): string => {
  if (startDateStr === "" && enDateStr === "") return "";
  return "~";
};

const convertDataTable = (data: any[]): any => {
  const output: TableDataEntity[] = [];

  let planingTotal = 0;
  let receiveTotal = 0;
  let offsetTotal = 0;
  let contractorTotal = 0;

  data.forEach((item: any) => {
    let obj: TableDataEntity = {
      ConstructionId: item.constructionId,
      ConstructionName: item.constructionName,
      CreatedAt: item.createdAt,
      Children: [],
    };

    const outputChildren: ChildrenDataEntity[] = [];

    item?.children.forEach((element: any) => {
      if (element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE) {
        let objChildren: ChildrenDataEntity = {
          Id: element.id,
          DateTime: `${formatDateStr(
            element?.startDate ?? ""
          )} ${showSignRangeDate(
            element?.startDate ?? "",
            element?.endDate ?? ""
          )} ${formatDateStr(element?.endDate ?? "")}`,
          ReceiveAt: `${formatDateStr(element?.receiveAt)}`,
          PlanReceiveMoney: `${helpers.formatCurrency(
            element?.planReceiveMoney ?? 0
          )}円`,
          ReceiveMoney: `${helpers.formatCurrency(
            element?.receiveMoney ?? 0
          )}円`,
          OffsetMoney: `${helpers.formatCurrency(element?.offsetMoney ?? 0)}円`,
          ContractorReceiveMoney: `${helpers.formatCurrency(
            element?.contractorReceiveMoney ?? 0
          )}円`,
          DocumentPath: `${element?.evidence ?? ""}`,
          StatusCode: `${element?.status?.code ?? ""}`,
          StatusName: `${element?.status?.name ?? ""}`,
        };

        planingTotal += element?.planReceiveMoney ?? 0;
        receiveTotal += element?.receiveMoney ?? 0;
        offsetTotal += element?.offsetMoney ?? 0;
        contractorTotal += element?.contractorReceiveMoney ?? 0;

        outputChildren.push(objChildren);
      }
    });

    obj.Children = outputChildren;

    output.push(obj);
  });

  // Sort created at asc

  output.sort((a: any, b: any) => {
    a = new Date(a.CreatedAt);
    b = new Date(b.CreatedAt);
    return b > a ? -1 : b < a ? 1 : 0;
  });

  return [
    output,
    helpers.formatCurrency(receiveTotal),
    helpers.formatCurrency(offsetTotal),
    helpers.formatCurrency(contractorTotal),
    helpers.formatCurrency(planingTotal),
  ];
};

const findColorStatus = (statusCode: string): string => {
  const color = STATUS_CM_CHILD_TABLE.hasOwnProperty(
    `${statusCode.toUpperCase()}`
  );
  return color
    ? STATUS_CM_CHILD_TABLE[
      `${statusCode.toUpperCase()}` as keyof typeof STATUS_CM_CHILD_TABLE
    ].COLOR
    : "";
};

const nested = (items: any) => {
  const nestedData: any[] = [];

  items.sort((a: any, b: any) => {
    return b.parentId - a.parentId;
  }); // sort desc with parent id

  items.forEach((ele: any) => {
    const elements: any[] = [];
    let footerTotal = 0;

    ele.nestedData.forEach((a: any, idx: number) => {
      const offset = a?.value ?? 0;

      const _e = {
        no: idx + 1,
        item: a?.name ?? "",
        tax: a?.category?.description ?? "",
        offsetAmount: `${helpers.formatCurrency(offset)}円`,
      };
      footerTotal += offset;
      elements.push(_e);
    });

    elements.unshift({
      no: "No",
      item: "項目",
      tax: "消費税区分",
      offsetAmount: "相殺金額(税抜)",
    });

    const _obj = {
      dateTime: ele?.dateTime ?? "",
      elements: elements,
      footer: `${helpers.formatCurrency(footerTotal)}円`,
    };

    nestedData.push(_obj);
  });

  return nestedData;
};

const convertNestedData = (data: any) => {
  const nestedData: any[] = [];

  for (const [key, value] of Object.entries(data)) {
    const _name = key.split("-");

    const _obj = {
      constructionName: _name.length > 0 ? _name[0] : "",
      orderCompanyName: _name.length > 0 ? _name[2] : "",
      nestedData: nested(value),
    };

    nestedData.push(_obj);
  }

  return nestedData;
};

const convertDataExportCM003 = (
  summaryData: SummaryDataEntity,
  footerData: DataTotalSummaryEntity,
  data: TableDataEntity[],
  dataNested: any[]
) => {
  const contents: any = [];

  data.forEach((item: TableDataEntity) => {
    let obj: any = {
      constructionName: item.ConstructionName,
      elements: [],
    };

    const childrens = [];

    item.Children.forEach((element: ChildrenDataEntity, idx: number) => {
      let child = {
        no: idx + 1,
        paymentDay: element.ReceiveAt,
        targetPeriod: element.DateTime,
        planBillingAmount: element.PlanReceiveMoney,
        billingAmount: element.ReceiveMoney,
        offsetAmount: element.OffsetMoney,
        depositAmount: element.ContractorReceiveMoney,
        status: element.StatusName,
      };

      childrens.push(child);
    });

    // Add title item into top array
    childrens.unshift({
      no: "No",
      paymentDay: "入金日",
      targetPeriod: "対象期間",
      planBillingAmount: "請求予定金額",
      billingAmount: "請求金額",
      offsetAmount: "相殺金額",
      depositAmount: "入金額",
      status: "ステータス",
    });

    obj.elements = childrens;

    contents.push(obj);
  });

  const dataExport: any = {
    information: {
      headers: ["契約金額", "請求出来高", "請求残金", "請求出来高(％)"],
      contents: [
        {
          contract_amount: `${summaryData.contractAmount}`,
          request_come_out: `${summaryData.billingVolume}`,
          request_come_out_rate: `${summaryData.rateValue}`,
          bill_balance: `${summaryData.threshold}`,
        },
      ],
    },
    content: contents,
    footer: {
      PlanningTotal: `${footerData.PlanningTotal}円`,
      ReceiveMoneyTotal: `${footerData.ReceiveMoneyTotal}円`,
      OffsetMoneyTotal: `${footerData.OffsetMoneyTotal}円`,
      ContractorMoneyTotal: `${footerData.ContractorMoneyTotal}円`,
    },
    nestedData: dataNested,
  };

  return dataExport;
};

export {
  convertSummaryTable,
  convertDataTable,
  findColorStatus,
  convertDataExportCM003,
  convertNestedData,
};
