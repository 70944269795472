import { CM0033SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0033SliceType = {
    isRefresh: false,
    isVisibleCM0033: false,
    input: {
        documentId: 0,
        documentName: '',
        documentStatus: '',
        documentType: '',
        submittedDate: '',
        startTimeFilter: '',
        endTimeFilter: '',
    },
};

export const cm0033Slice = createSlice({
    name: 'cm0033',
    initialState,
    reducers: {
        resetCM0033: (state) => {
            return {
                ...initialState,
                isRefresh: state.isRefresh,
            };
        },
        setVisibleCM0033: (state, action) => {
            state.isVisibleCM0033 = action.payload;
        },
        setInputCM0033: (state, action) => {
            state.input = action.payload;
        },
        refreshCM0033: (state) => {
            state.isRefresh = !state.isRefresh;
        },
    },
});

export const { resetCM0033, setVisibleCM0033, setInputCM0033, refreshCM0033 } = cm0033Slice.actions;

export default cm0033Slice.reducer;
