import React, { useMemo } from "react";
import IconDown from "../../../../../assets/icons/icon-collapse-cm.svg";
import IconView from "../../../../../assets/icons/eyeIconBlack.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { Empty } from "antd";
import helpers from "../../../../../common/helpers/common";
import COMMON, { TAX_CODE } from "../../../../../common/constants/COMMON";
import { CM0033Handler } from "../handler/HandlerImpl";
import InfiniteScroll from "react-infinite-scroll-component";

interface props {
  handler: CM0033Handler;
}
const NO_DATA = "データなし";

const TableByProject = ({ handler }: props) => {
  const loading = useSelector((state: RootState) => state.common.loading);
  const contentTable = useMemo(() => {
    if (handler.dataView.length === 0)
      return !loading ? (
        <tr>
          <td colSpan={13}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={NO_DATA} />
          </td>
        </tr>
      ) : null;
    const elementTable: any = [];
    handler.dataView.forEach((element: any, indx) => {
      elementTable.push(
        <React.Fragment key={element.key}>
          <tr style={{ height: 4 }}></tr>
          <tr
            className="row-level-0 button-style"
            onClick={() => {
              handler.funcSetExpendKey(element.key);
            }}
          >
            <td colSpan={2}>{indx + 1}</td>
            <td colSpan={5} style={{ textAlign: "left", paddingLeft: 50 }}>
              {helpers.getShortTextWithToolTip(element.projectName, 20)}
            </td>
            <td>{helpers.formatCurrency(element.taxAtion)}円</td>
            <td>{helpers.formatCurrency(element.taxExempt)}円 </td>
            <td>{helpers.formatCurrency(element.depositAmount)}円 </td>
            <td>{helpers.formatCurrency(element.consumptionTax)}円 </td>
            <td>{helpers.formatCurrency(element?.totalTax)}円 </td>
            <td>
              <img
                alt=""
                src={IconDown}
                className={`cm024-icon ${
                  handler.funcCheckKeyExpand(element.key)
                    ? ""
                    : "cm024-icon-active"
                }`}
              />
            </td>
          </tr>
        </React.Fragment>
      );

      if (element.children) {
        elementTable.push(
          <tr
            key={`table${element.key}`}
            className="bg-fa"
            style={{
              display: handler.funcCheckKeyExpand(element.key)
                ? undefined
                : "none",
            }}
          >
            <td colSpan={2}></td>
            <td style={{ minWidth: 140 }}>工事名</td>
            <td style={{ minWidth: 140 }}>発注元会社名</td>
            <td colSpan={2} className="" style={{ minWidth: 140 }}>
              対象期間
            </td>
            <td className="" style={{ minWidth: 160 }}>
              入金日
            </td>
            <td></td>
            <td></td>
            <td style={{ minWidth: 100 }}></td>
            <td style={{ minWidth: 100 }}></td>
            <td style={{ minWidth: 100 }}></td>
            <td></td>
          </tr>
        );
        if (element?.children && element.children.length > 0) {
          element.children.forEach((child2: any) => {
            elementTable.push(
              <tr
                key={child2.key}
                style={{
                  display: handler.funcCheckKeyExpand(element.key)
                    ? undefined
                    : "none",
                }}
              >
                <td style={{ width: 50 }}></td>
                <td style={{ width: 50 }}>{child2.no}</td>
                <td>
                  {helpers.getShortTextWithToolTip(
                    child2.constructionDescription,
                    13
                  )}{" "}
                </td>
                <td>
                  {helpers.getShortTextWithToolTip(child2.orderAddress, 13)}
                </td>
                <td
                  colSpan={2}
                >{`${child2?.startDate}～${child2?.endDate}`}</td>
                <td>{child2?.receiveAt}</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span style={{ whiteSpace: "nowrap" }}>
                      {helpers.formatCurrency(child2.taxAtion)}円
                    </span>
                    &nbsp;
                    <img
                      className="icon-view"
                      alt=""
                      src={IconView}
                      onClick={() =>
                        handler.handleViewDetail(
                          child2.id,
                          child2.taxId,
                          element.projectName,
                          child2.orderAddress,
                          `${child2?.startDate}～${child2?.endDate}`,
                          false
                        )
                      }
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span style={{ whiteSpace: "nowrap" }}>
                      {helpers.formatCurrency(child2.taxExempt)}円
                    </span>
                    &nbsp;
                    <img
                      className="icon-view"
                      alt=""
                      src={IconView}
                      onClick={() =>
                        handler.handleViewDetail(
                          child2.id,
                          child2.taxFreeId,
                          element.projectName,
                          child2.orderAddress,
                          `${child2?.startDate}～${child2?.endDate}`,
                          false
                        )
                      }
                    />
                  </div>
                </td>
                <td>{helpers.formatCurrency(child2.depositAmount)}円 </td>
                <td>{helpers.formatCurrency(child2.consumptionTax)}円 </td>
                <td>
                  {helpers.formatCurrency(
                    child2.depositAmount + child2.consumptionTax
                  )}
                  円{" "}
                </td>
                <td></td>
              </tr>
            );
          });
        } else {
          !loading &&
            elementTable.push(
              <tr
                key={`table${element.key}nodata`}
                style={{
                  display: handler.funcCheckKeyExpand(element.key)
                    ? undefined
                    : "none",
                }}
              >
                <td colSpan={13}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={NO_DATA}
                  />
                </td>
              </tr>
            );
        }
      }
    });
    return elementTable;
  }, [handler.dataView, handler.expandKey, loading]);

  return (
    <div className="cm024-table" style={{ overflowY: "hidden" }}>
      <InfiniteScroll
        dataLength={handler.dataView.length}
        hasMore={true}
        next={handler.handlerScrollInfinity}
        height={"60vh"}
        loader={null}
      >
        <table>
          <thead>
            <tr>
              <th colSpan={2} rowSpan={2} style={{ minWidth: 100 }}>
                No
              </th>
              <th
                rowSpan={2}
                colSpan={5}
                style={{
                  textAlign: "left",
                  paddingLeft: 50,
                  minWidth: "580px",
                }}
              >
                案件名
              </th>
              <th colSpan={2} style={{ minWidth: "185px" }}>
                相殺金額
              </th>
              <th rowSpan={2} style={{ minWidth: "170px" }}>
                入金額(税抜)
              </th>
              <th rowSpan={2} style={{ minWidth: "130px" }}>
                消費税
              </th>
              <th rowSpan={2} style={{ minWidth: "130px" }}>
                入金額(税込)
              </th>
              <th rowSpan={2} style={{ minWidth: "40px" }}></th>
            </tr>
            <tr className="tr-border-first">
              <th>課税</th>
              <th>非課税</th>
            </tr>
          </thead>
          <tbody>{contentTable}</tbody>
          <tfoot>
            <tr className="table-foot">
              <td colSpan={7} style={{ textAlign: "left", paddingLeft: 40 }}>
                合計金額
              </td>
              <td>{helpers.formatCurrency(handler.footer.taxAtion)}円</td>
              <td>{helpers.formatCurrency(handler.footer.taxExempt)}円 </td>
              <td>{helpers.formatCurrency(handler.footer.depositAmount)}円 </td>
              <td>
                {helpers.formatCurrency(handler.footer.consumptionTax)}円{" "}
              </td>
              <td>{helpers.formatCurrency(handler.footer.totalTax)}円 </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default TableByProject;
