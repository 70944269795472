import { CCUS061Handler } from "../handler/HandlerImpl";
import { ColumnsType } from "antd/es/table";
import {
  TableIssueAuthenType,
  TableTechnicianInfoType,
} from "../../entity/Entity";
import { Checkbox, Form, Input } from "antd";
import { useMemo } from "react";

import SortTable from "../../../../../common/components/sort-table/SortTable";
import { AUTHENTICATION_KEY } from "../../constants";
import IconInfoRed from "../../../../../assets/icons/icon-info-red.svg";
import { getExpirationDateCcus061 } from "../../../../OM/OM002/presenter/ui/MemberTable";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import moment from "moment";
import helpers from "../../../../../common/helpers/common";

export const COLUMN = (handler: CCUS061Handler) => {
  const sortType = useMemo(() => {
    return handler.sortType;
  }, [handler.sortType]);
  const sortBy = useMemo(() => {
    return handler.sortBy;
  }, [handler.sortBy]);

  const validateInputName = (
    value: string,
    setError: (message: string) => void,
    isCheckboxChecked: boolean
  ) => {
    if (!value) {
      setError("");
      return true;
    }

    const patterns = {
      // Khi checkbox chưa được chọn: cho phép Kanji, Hiragana, chữ số half-size, chữ alphabet half-size (cả in hoa và thường), Katakana full-size
      // Cũng cho phép dấu cách full-size (\u3000) và half-size (\s)
      allowedWithoutCheckbox:
        /^[A-Za-z0-9\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF\s\u3000]+$/,
      // Các ký tự đặc biệt full-width không hợp lệ
      invalidFullWidthSpecialChars:
        /[！”＃＄％＆’＊＋，−．／：；＜＝＞？＠［￥］＾＿‘｛｜｝]/,
      // Các ký tự đặc biệt half-width không hợp lệ
      invalidHalfWidthSpecialChars: /[、。]/,
      // Khi checkbox được chọn: chỉ cho phép chữ in hoa, ký tự half-size và không cho phép dấu cách full-size (\u3000)
      allowedWithCheckbox: /^[A-Z,'\-().\/\s]+$/, // Chỉ cho phép dấu cách half-size
      invalidFullWidthSpace: /\u3000/, // Kiểm tra dấu cách full-size
    };

    // Nếu checkbox được chọn
    if (isCheckboxChecked) {
      // Kiểm tra chỉ cho phép chữ alphabet in hoa, ký tự đặc biệt half-size hợp lệ và không cho phép dấu cách full-size
      if (
        !patterns.allowedWithCheckbox.test(value) ||
        patterns.invalidFullWidthSpace.test(value)
      ) {
        setError(MESSAGE.MESSAGE_CCUS061_INVALID_INPUT);
        return false;
      }
    } else {
      // Khi checkbox không được chọn, cho phép cả full-size và half-size dấu cách
      if (!patterns.allowedWithoutCheckbox.test(value)) {
        setError(MESSAGE.MESSAGE_CCUS061_INVALID_INPUT);
        return false;
      }

      // Kiểm tra các ký tự đặc biệt không hợp lệ (full-width và half-width)
      const hasInvalidChars = [
        patterns.invalidFullWidthSpecialChars,
        patterns.invalidHalfWidthSpecialChars,
      ].some((pattern) => pattern.test(value));

      if (hasInvalidChars) {
        setError(MESSAGE.MESSAGE_CCUS061_INVALID_INPUT);
        return false;
      }
    }

    setError("");
    return true;
  };

  const validateInputNameKatakana = (
    value: string,
    setError: (message: string) => void
  ) => {
    // Ký tự hợp lệ half-size: alphabet (chữ thường & in hoa), chữ số, và các ký tự đặc biệt đã liệt kê
    const validHalfWidthPattern =
      /^[A-Za-z0-9~!@#$%^&*()_+\[\]\\;',.\/{}|:"<>?=\- ]+$/;

    // Ký tự Katakana full-size
    const validFullWidthKatakana = /^[\u30A0-\u30FF]+$/;

    // Kiểm tra xem chuỗi có chứa ký tự không hợp lệ không
    const hasInvalidChars = value.split("").some(
      (char) =>
        !validHalfWidthPattern.test(char) && // Không khớp với ký tự half-size hợp lệ
        !validFullWidthKatakana.test(char) // Không khớp với ký tự Katakana full-size
    );

    if (hasInvalidChars) {
      setError(MESSAGE.MESSAGE_CCUS061_INVALID_INPUT);
      return false;
    }

    // Nếu không có ký tự không hợp lệ
    setError("");
    return true;
  };

  const columnsTab1: ColumnsType<TableTechnicianInfoType> = [
    {
      title: "No",
      dataIndex: "No",
      width: 230,
      render: (text) => <div className="text-table-ccus">{text}</div>,
    },
    {
      title: (
        <SortTable
          columnTitle="技能者名"
          sortOrder={handler.funcCheckSortOrder("fullName", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("fullName", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "fullName",
      render: (_, record) => (
        <div
          className="text-table-ccus"
          style={{ color: "#605BFF", cursor: "pointer" }}
          onClick={() => handler.openPopupDetail(record.key)}
        >
          {record.fullName}
        </div>
      ),
      width: 238,
    },
    {
      title: (
        <SortTable
          columnTitle="部門"
          sortOrder={handler.funcCheckSortOrder("group", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("group", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "group",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 238,
    },
    {
      title: "技能者ID",
      dataIndex: "ccusId",
      render: (text) => (
        <div className="text-table-ccus">
          {helpers.convertToCCUSFormat(text || "")}
        </div>
      ),
      width: 238,
    },
    {
      title: (
        <SortTable
          columnTitle="有効期限"
          sortOrder={handler.funcCheckSortOrder(
            "ccusExpiredDate",
            sortType,
            sortBy
          )}
          onChange={(sortOrder) =>
            handler.funcSortTable("ccusExpiredDate", sortOrder)
          }
          justifyContent="start"
        />
      ),
      dataIndex: "expiredDate",
      render: (text) => (
        <div className="text-table-ccus">
          {text ? getExpirationDateCcus061(text) : "-"}
        </div>
      ),
      width: 238,
    },
  ];

  const columnsTab2: ColumnsType<TableIssueAuthenType> = [
    {
      title: (
        <SortTable
          columnTitle="技能者ID"
          sortOrder={handler.funcCheckSortOrder("ccusId", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("ccusId", sortOrder)}
          justifyContent="start"
        />
      ),
      render: (_, record) => {
        return (
          <div style={{ paddingTop: 16, paddingLeft: 8 }}>
            <span
              className={
                record.state === AUTHENTICATION_KEY[1].key
                  ? "text-table-ccus-disable"
                  : "text-table-ccus"
              }
            >
              {record.ccusId}
            </span>
            <Form.Item
              name={[`record${record.key}`, `ccusId`]}
              hidden
              style={{ display: "none" }}
            >
              <Input
                disabled={record.state === AUTHENTICATION_KEY[1].key}
                size="large"
                className="input-ccus062"
              />
            </Form.Item>
          </div>
        );
      },
      width: 30,
    },
    {
      title: "外国籍",
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `isForeigner`]}
          valuePropName="checked"
          style={{
            paddingTop: 8,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Checkbox
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            onChange={() => {
              const lastName = handler.formTab2.getFieldValue([
                `record${record.key}`,
                "lastName",
              ]);

              const firstName = handler.formTab2.getFieldValue([
                `record${record.key}`,
                "firstName",
              ]);

              const isCheckboxChecked = handler.formTab2.getFieldValue([
                `record${record.key}`,
                "isForeigner",
              ]);

              let isValid = true;
              let isFirstNameValid = true;

              if (!lastName && !firstName) {
                handler.handleRowError(record.key, "", "lastName");
                handler.handleRowError(record.key, "", "firstName");
                handler.setNotSubmit(false);
                return;
              }

              if (lastName) {
                isValid = validateInputName(
                  lastName,
                  (errorMessage: string) => {
                    handler.handleRowError(
                      record.key,
                      errorMessage,
                      "lastName"
                    );
                  },
                  isCheckboxChecked
                );
              } else {
                handler.handleRowError(record.key, "", "lastName");
              }

              if (firstName) {
                isFirstNameValid = validateInputName(
                  firstName,
                  (errorMessage: string) => {
                    handler.handleRowError(
                      record.key,
                      errorMessage,
                      "firstName"
                    );
                  },
                  isCheckboxChecked
                );
              } else {
                handler.handleRowError(record.key, "", "firstName");
              }

              handler.setNotSubmit(!(isValid || isFirstNameValid));
            }}
          />
        </Form.Item>
      ),
      width: 80,
    },
    {
      title: "技能者姓①",
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `lastName`]}
          className="input-ccus062-container"
          style={{
            paddingTop: 8,
            paddingLeft: 8,
          }}
          help={
            handler.rowErrors[record.key]?.lastName
              ? handler.rowErrors[record.key]?.lastName
              : ""
          }
          validateStatus={
            handler.rowErrors[record.key]?.lastName ? "error" : ""
          }
        >
          <Input
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            size="large"
            className="input-ccus062"
            style={{ width: 125 }}
            onInput={(e: any) => {
              const { value } = e.target;
              if (!value) {
                handler.handleRowError(record.key, "", "lastName");
                handler.setNotSubmit(false);
                return;
              }

              const isCheckboxChecked = handler.formTab2.getFieldValue([
                `record${record.key}`,
                "isForeigner",
              ]);

              const isValid = validateInputName(
                value,
                (errorMessage: string) => {
                  handler.handleRowError(record.key, errorMessage, "lastName");
                },
                isCheckboxChecked
              );

              handler.setNotSubmit(!isValid);
            }}
          />
        </Form.Item>
      ),
      width: 170,
    },
    {
      title: "技能者名①",
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `firstName`]}
          className="input-ccus062-container"
          style={{
            paddingTop: 8,
            paddingLeft: 8,
          }}
          help={
            handler.rowErrors[record.key]?.firstName
              ? handler.rowErrors[record.key]?.firstName
              : ""
          }
          validateStatus={
            handler.rowErrors[record.key]?.firstName ? "error" : ""
          }
        >
          <Input
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            size="large"
            className="input-ccus062"
            style={{ width: 125 }}
            onInput={(e: any) => {
              const { value } = e.target;
              if (!value) {
                handler.handleRowError(record.key, "", "firstName");
                handler.setNotSubmit(false);
                return;
              }

              const isCheckboxChecked = handler.formTab2.getFieldValue([
                `record${record.key}`,
                "isForeigner",
              ]);
              const isValid = validateInputName(
                value,
                (errorMessage: string) => {
                  handler.handleRowError(record.key, errorMessage, "firstName");
                },
                isCheckboxChecked
              );

              handler.setNotSubmit(!isValid);
            }}
          />
        </Form.Item>
      ),
      width: 170,
    },
    {
      title: "技能者姓(カタカナ)②",
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `lastNameKatakana`]}
          className="input-ccus062-container"
          style={{
            paddingTop: 8,
            paddingLeft: 8,
          }}
          help={
            handler.rowErrors[record.key]?.lastNameKatakana
              ? handler.rowErrors[record.key]?.lastNameKatakana
              : ""
          }
          validateStatus={
            handler.rowErrors[record.key]?.lastNameKatakana ? "error" : ""
          }
        >
          <Input
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            size="large"
            className="input-ccus062"
            style={{ width: 125 }}
            onInput={(e: any) => {
              const { value } = e.target;

              if (!value) {
                handler.handleRowError(record.key, "", "lastNameKatakana");
                handler.setNotSubmit(false);
                return;
              }

              const isValid = validateInputNameKatakana(
                value,
                (errorMessage: string) => {
                  handler.handleRowError(
                    record.key,
                    errorMessage,
                    "lastNameKatakana"
                  );
                }
              );

              handler.setNotSubmit(!isValid);
            }}
          />
        </Form.Item>
      ),
      width: 170,
    },
    {
      title: "技能者名(カタカナ)②",
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `firstNameKatakana`]}
          className="input-ccus062-container"
          style={{
            paddingTop: 8,
            paddingLeft: 8,
          }}
          help={
            handler.rowErrors[record.key]?.firstNameKatakana
              ? handler.rowErrors[record.key]?.firstNameKatakana
              : ""
          }
          validateStatus={
            handler.rowErrors[record.key]?.firstNameKatakana ? "error" : ""
          }
        >
          <Input
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            size="large"
            className="input-ccus062"
            style={{ width: 125 }}
            onInput={(e: any) => {
              const { value } = e.target;

              if (!value) {
                handler.handleRowError(record.key, "", "firstNameKatakana");
                handler.setNotSubmit(false);
                return;
              }

              const isValid = validateInputNameKatakana(
                value,
                (errorMessage: string) => {
                  handler.handleRowError(
                    record.key,
                    errorMessage,
                    "firstNameKatakana"
                  );
                }
              );

              handler.setNotSubmit(!isValid);
            }}
          />
        </Form.Item>
      ),
      width: 170,
    },
    {
      title: "本人確認番号③",
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `confirmCode`]}
          className="input-ccus062-container"
          style={{
            paddingTop: 8,
            paddingLeft: 8,
          }}
          help={
            handler.rowErrors[record.key]?.confirmCode
              ? handler.rowErrors[record.key]?.confirmCode
              : ""
          }
          validateStatus={
            handler.rowErrors[record.key]?.confirmCode ? "error" : ""
          }
        >
          <Input
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            size="large"
            className="input-ccus062"
            style={{ width: "100px" }}
            maxLength={4}
            onInput={(e: any) => {
              let { value } = e.target;

              value = value.replace(/[^\d]/g, "");

              if (value.length > 4) {
                value = value.slice(0, 4);
              }

              e.target.value = value;

              const regex = /^\d{4}$/;
              if (value && !regex.test(value)) {
                handler.handleRowError(
                  record.key,
                  "4桁の半角数字を入力してください。",
                  "confirmCode"
                );
                handler.setNotSubmit(true);
              } else {
                handler.handleRowError(record.key, "", "confirmCode");
                handler.setNotSubmit(false);
              }
            }}
          />
        </Form.Item>
      ),
      width: 130,
    },
    {
      title: (
        <div>
          生年月日<span className="sterisk">*</span>
        </div>
      ),
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `dateOfBirth`]}
          className="input-ccus062-container"
          style={{
            paddingTop: 8,
            paddingLeft: 8,
          }}
          help={
            handler.rowErrors[record.key]?.dateOfBirth
              ? handler.rowErrors[record.key]?.dateOfBirth
              : ""
          }
          validateStatus={
            handler.rowErrors[record.key]?.dateOfBirth ? "error" : ""
          }
        >
          <Input
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            size="large"
            className="input-ccus062"
            style={{ width: "100px" }}
            maxLength={8}
            placeholder="yyyyMMdd"
            onInput={(e: any) => {
              let { value } = e.target;

              const validInputPattern = /[^\d]/g;
              value = value.replace(validInputPattern, "");

              if (value.length > 8) {
                value = value.slice(0, 8);
              }

              e.target.value = value;

              if (!value) {
                handler.handleRowError(
                  record.key,
                  MESSAGE.MESSAGE_CCUS061_VALID_DATE,
                  "dateOfBirth"
                );
                handler.setNotSubmit(true);
              } else if (!/^\d{8}$/.test(value)) {
                handler.handleRowError(
                  record.key,
                  MESSAGE.MESSAGE_CCUS061_VALID_DATE,
                  "dateOfBirth"
                );
                handler.setNotSubmit(true);
              } else {
                const [year, month, day] = [
                  value.slice(0, 4),
                  value.slice(4, 6),
                  value.slice(6, 8),
                ];

                const isValidDate = moment(
                  `${year}-${month}-${day}`,
                  "YYYY-MM-DD",
                  true
                ).isValid();

                if (
                  year > new Date().getFullYear() ||
                  !/^(0[1-9]|1[0-2])$/.test(month) ||
                  !isValidDate
                ) {
                  handler.handleRowError(
                    record.key,
                    MESSAGE.MESSAGE_CCUS061_VALID_DATE,
                    "dateOfBirth"
                  );
                  handler.setNotSubmit(true);
                } else {
                  handler.handleRowError(record.key, "", "dateOfBirth"); // Clear error if valid
                  handler.setNotSubmit(false);
                }
              }
            }}
          />
        </Form.Item>
      ),
      width: 120,
    },
    {
      title: "認証結果",
      render: (_, record) => {
        return (
          <div style={{ padding: "16px 0px 16px 8px" }}>
            <Form.Item
              name={[`record${record.key}`, `state`]}
              hidden
              style={{ display: "none" }}
            >
              <Input
                disabled={record.state === AUTHENTICATION_KEY[1].key}
                size="large"
                className="input-ccus062"
              />
            </Form.Item>
            <div
              className={`${
                record.state === AUTHENTICATION_KEY[1].key
                  ? "text-state-success"
                  : record.state === AUTHENTICATION_KEY[2].key
                  ? "text-state-error"
                  : ""
              }`}
            >
              {record.state === AUTHENTICATION_KEY[1].key ? (
                AUTHENTICATION_KEY[1].value
              ) : record.state === AUTHENTICATION_KEY[2].key ? (
                <div className="record-state-table">
                  <span>{AUTHENTICATION_KEY[2].value}</span>{" "}
                  <img
                    src={IconInfoRed}
                    style={{ cursor: "pointer" }}
                    onClick={() => handler.openModalError(record)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
      width: 130,
    },
  ];
  const rowSelection = {
    selectedRowKeys: handler.selectedRowsKey,
    onChange: (selectedRowKeys: React.Key[]) => {
      handler.getSelectedKey(selectedRowKeys);
    },
    getCheckboxProps: (record: any) => {
      return {
        disabled: record.state === AUTHENTICATION_KEY[1].key,
      };
    },
  };
  return {
    columnsTab1,
    columnsTab2,
    rowSelection,
  };
};
