import { CM0017_3APIImpl } from "./ServiceImpl";
import helpers from "../../../../common/helpers/common";
import {
  ReSummaryCompanySummaryOutsourceCostRetrievePayload,
  ReSummarySubmittedCompanyOutsourceCostResults,
} from "../entity/Entity";
import { CommonPutDeletePostResponse } from "../../CM007/entity/Entity";
import {
  ReSummaryCompanySummaryOutsourceCostDetail,
  ReSummaryCompanySummaryOutsourceCostResult,
  ReSummaryCompanySummaryOutsourceCostTotalResult,
} from "../../CM0017/entity/Entity";
import { WorkflowResponseData } from "../../CM008/entity/Entity";

const CM0017Service = (api: CM0017_3APIImpl) => {
  const getReSummarySubmittedCompanyOutsourceCostDetail = async (params: {
    documentId: number;
    page?: number;
    size?: number;
  }): Promise<ReSummaryCompanySummaryOutsourceCostDetail> => {
    try {
      const response =
        await api.getReSummarySubmittedCompanyOutsourceCostDetail(params);
      const { pagination, results } = response.data;
      const responseData: ReSummaryCompanySummaryOutsourceCostDetail = {
        pagination,
        results,
      };
      return responseData;
    } catch (error) {
      throw error;
    }
  };
  const getReSummarySubmittedCompanyOutsourceCostTotal = async (params: {
    documentId: number;
  }): Promise<ReSummaryCompanySummaryOutsourceCostTotalResult> => {
    try {
      const response = await api.getReSummarySubmittedCompanyOutsourceCostTotal(
        params
      );
      const { results } = response.data;
      return results;
    } catch (error) {
      throw error;
    }
  };
  const getReSummarySubmittedCompanyOutsourceCost = async (params: {
    documentId: number;
  }): Promise<ReSummarySubmittedCompanyOutsourceCostResults> => {
    try {
      const response = await api.getReSummarySubmittedCompanyOutsourceCost(
        params
      );
      const { results } = response.data;
      return results;
    } catch (error) {
      throw error;
    }
  };
  const putReSummaryCompanySummaryOutsourceCostRetrieve = async (
    payload: ReSummaryCompanySummaryOutsourceCostRetrievePayload
  ): Promise<CommonPutDeletePostResponse> => {
    try {
      const response =
        await api.putReSummaryCompanySummaryOutsourceCostRetrieve(payload);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const getWorkflowSummaryCompanyOutsourceApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response =
        await api.getWorkflowSummaryCompanyOutsourceApprovalProcess(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const getSubCategory = async (): Promise<any> => {
    try {
      return await api.getSubCategory();
    } catch (error) {
      throw error;
    }
  };
  const getTaxSetting = async (params: any) => {
    try {
      const response = await api.getTaxSetting({ ...params });
      return response.data;
    } catch (error: any) {
      return;
    }
  };
  return {
    getReSummarySubmittedCompanyOutsourceCostDetail,
    getReSummarySubmittedCompanyOutsourceCostTotal,
    getReSummarySubmittedCompanyOutsourceCost,
    putReSummaryCompanySummaryOutsourceCostRetrieve,
    getWorkflowSummaryCompanyOutsourceApprovalProcess,
    getSubCategory,
    getTaxSetting
  };
};

export default CM0017Service;
